<template>
  <main id="home">
    <span class="overlay"></span>
    <aside>
      <img src="/images/fmo+50+blue-okerlarge.png" />
      <LoginForm />
      <img src="@/assets/logo-steward-redqueen.png" />
    </aside>
  </main>
</template>

<script>
import router from "@/router";
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "home",
  components: {
    LoginForm,
  },
  mounted() {
    this.$store.commit('disabledLoading')
    let user = {}
    if (window.localStorage.getItem('esg_toolkitv0.4')) {
      user = JSON.parse(atob(window.localStorage.getItem('esg_toolkitv0.4')))
    }
    if (user.name)
      router.push({ name: "overview" });
  },
};
</script>
