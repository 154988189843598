<template>
  <div>
    <h2>ESG Toolkit</h2>
    <p>Create account</p>
    <p v-if="showWarning">
      Accounts can be created as of Tuesday January 26th at 11AM CET
    </p>
    <form v-if="!hideForm && !showWarning" novalidate class="md-layout" @submit.prevent="validateUser">
      <md-field :class="getValidationClass('name')">
        <label for="name">Name</label>
        <md-input
          type="text"
          name="name"
          id="name"
          autocomplete="email"
          v-model="form.name"
          :disabled="sending"/>
        <span class="md-error" v-if="!$v.form.name.required">The name is required</span>
      </md-field>
      <md-field :class="getValidationClass('organization')">
        <label for="name">Organization name</label>
        <md-input
          type="text"
          name="organization"
          id="organization"
          v-model="form.organization"
          :disabled="sending"/>
        <span class="md-error" v-if="!$v.form.name.required">The organization name is required</span>
      </md-field>
      <md-field :class="getValidationClass('email')">
        <label for="email">Email</label>
        <md-input
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          v-model="form.email"
          :disabled="sending"/>
        <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
        <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
      </md-field>
      <md-field :class="getValidationClass('password')">
        <label for="password">Password</label>
        <md-input
          type="password"
          name="password"
          id="password"
          v-model="form.password"
          :disabled="sending"/>
        <span class="md-error" v-if="!$v.form.password.required">The password is required</span>
      </md-field>
      <md-field class="terms-holder" :class="getValidationClass('terms')">
        <md-checkbox v-model="form.terms" value="1" />
        <span class="terms">
          I have read the <router-link to="/disclaimer">disclaimer</router-link>
        </span>
        <span class="md-error" v-if="!$v.form.terms.required">You have to accept the terms</span>
      </md-field>
      <md-button type="submit" class="md-primary raised" :disabled="sending">Create</md-button>
    </form>
    <div v-if="hideForm && !showWarning">
      <hr />
      <p>
        You have received an e-mail to activate your account.<br/>
        (Please also check the spam folder)
      </p>
    </div>
    <hr />
    <md-button to="/" class="md-primary">Back</md-button>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CreateAccountForm",
  mixins: [validationMixin],
  data: () => ({
    showWarning: false,
    form: {
      email: null,
      name: null,
      organization: null,
      password: null,
      terms: null,
    },
    userSaved: false,
    sending: false,
    lastUser: null,
    message: "",
    hideForm: false,    
    showSnackbar: false,
  }),
  validations: {
    form: {
      terms: {
        required,
      },
      name: {
        required,
      },
      organization: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.create()
      }
    },
    create() {
      this.$store.commit("toggleLoading")
      axios
        .post(`${window.api}user/create/`, {
          name: this.form.name,
          email: this.form.email,
          organization: this.form.organization,
          pwd: this.form.password,
        })
        .then(response => {
          if (response.data.status) {
            this.$v.$reset()
            this.form.email = null
            this.form.name = null
            this.form.organization = null
            this.form.pwd = null
            this.hideForm = true;
          } else {
            this.message = "An account already exists with this e-mail.";
            this.showSnackbar = true;
          }
          this.$store.commit("toggleLoading")
        })
        .catch(() => {
          this.message = "Something went wrong, try again later.";
          this.showSnackbar = true;       
          this.$store.commit("toggleLoading")   
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.raised {
  width: 100%;
  margin-top: 30px;
}
hr {
  margin: 40px 0;
}
.terms-holder {
  padding: 0;
  margin-bottom: 0;
  min-height: inherit;
  &:after {
    background: none;
  }
  .md-checkbox {
    margin: 0 16px 0 0;
  }
  .terms {
    top: 0;
    z-index: 10;
    left: 30px;
    position: absolute;
    font-size: 16px;
    color: rgba(0,0,0,0.54);
  }
}
</style>
