<template>
  <main id="sector-risks">
    <md-card>
      <md-card-header>
        <div class="md-title">
          <span v-if="type === '1' || type === '3'">Sector Risks</span>
          <span v-if="type === '2'">Country issues</span>
        </div>
        <span class="utils">
          <md-field>
            <md-select id="sector" v-model="type">
              <md-option value="1">Sector risks</md-option>
              <md-option value="2">Country issues</md-option>
              <md-option value="3" v-if="maxSector > 0">Mitigants</md-option>
            </md-select>
          </md-field>
          <md-button v-if="showTable" @click="showFullTable = true">
            <md-icon>fullscreen</md-icon>
          </md-button>
          <md-button @click="download">
            <md-icon>save_alt</md-icon>
          </md-button>
          <md-icon class="info">
            info
            <md-tooltip md-direction="top">
              <span v-if="type && data.content" v-html="data.content['Info: Sector risks']"></span>
              <span v-if="!type && data.content" v-html="data.content['Info: Country issues']"></span>
            </md-tooltip>
          </md-icon>
        </span>
      </md-card-header>
      <md-card-content>
        <table cellpadding="0" v-if="item.high_risk === 0 && type === '1'" cellspacing="0" border="0">
          <tr>
            <td class="small">
              
              <strong>Relevance</strong>
            </td>
            <td>
              <strong>Name</strong>
            </td>
            <td colspan="3">
              <strong>Risks</strong>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps2-labor-revised-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 2 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 2:<br/>Labor</strong>
            </td>
            <td colspan="3" v-if="sectorPS2.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS2[item]">
                <strong>{{sectorPS2[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps3-resource-efficiency-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 3 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 3:<br/>Resource Efficiency</strong>
            </td>
            <td colspan="3" v-if="sectorPS3.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS3[item]">
                <strong>{{sectorPS3[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps4-community-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 4 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 4:<br/>Community</strong>
            </td>
            <td colspan="3" v-if="sectorPS4.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS4[item]">
                <strong>{{sectorPS4[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps5-land-resettlement-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 5 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 5:<br/>Land Resettlement</strong>
            </td>
            <td colspan="3" v-if="sectorPS5.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS5[item]">
                <strong>{{sectorPS5[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps6-biodiversity-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 6 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 6:<br/>Biodiversity</strong>
            </td>
            <td colspan="3" v-if="sectorPS6.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS6[item]">
                <strong>{{sectorPS6[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps7-indigenous-people-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 7 relevance`]" />
            </td>
            <td>  
              <strong>IFC PS 7:<br/>Indigenous People</strong>
            </td>
            <td colspan="3" v-if="sectorPS7.length === 0">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="sectorPS7[item]">
                <strong>{{sectorPS7[item].title}}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps8-cultural-heritage-no-text-boxed.png" />
              <ProgressDash :level="subsectorData[`PS 8 relevance`]" />
            </td>
            <td>
              <strong>IFC PS 8:<br/>Cultural Heritage</strong>
            </td>
            <td colspan="3">
              N/A
              <md-tooltip md-direction="bottom" v-html="data.content['Info: Sector risks / Country issues N/A']"></md-tooltip>
            </td>
          </tr>
        </table>
        <table class="country" cellpadding="0" v-if="type === '2'" cellspacing="0" border="0">
          <tr>
            <td>
              <strong>Relevance</strong>
            </td>
            <td>
              <strong>Name</strong>
            </td>
            <td colspan="3">
              <strong>Country issues</strong>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps2-labor-revised-no-text-boxed.png" />
            </td>
            <td>
              <strong>IFC PS 2:<br/>Labor</strong>
            </td>
            <td colspan="3" v-if="countryPS2.length === 0">
              N/A
              <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="countryPS2[item]">
                <strong>{{ countryPS2[item].title }}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps3-resource-efficiency-no-text-boxed.png" />
            </td>
            <td>
              <strong>IFC PS 3:<br/>Resource Efficiency</strong>
            </td>
            <td colspan="3" v-if="countryPS3.length === 0">
              N/A
              <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="countryPS3[item]">
                <strong>{{ countryPS3[item].title }}</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <img src="/images/ps6-biodiversity-no-text-boxed.png" />
            </td>
            <td>
              <strong>IFC PS 6:<br/>Biodiversity</strong>
            </td>
            <td colspan="3" v-if="countryPS6.length === 0">
              N/A
              <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
            </td>
            <td v-else v-for="item in [0, 1, 2]" v-bind:key="item">
              <span v-if="countryPS6[item]">
                <strong>{{ countryPS6[item].title }}</strong>
              </span>
            </td>
          </tr>
        </table>
        <div class="links" v-if="item.high_risk === 1 && type === '1'">
          <p>
            When investing in any FI it should be verified whether the FI’s ESMS or risk management system ensures compliance with local E&S law and whether it flags or prohibits loans or investments in activities on the EDFI Exclusion list <a href="@/assets/files/EDFI Harmonized High Risk Sector List.pdf" target="_blank">EDFI Harmonized High Risk Sector List</a>.<br/>
            Some FIs are exposed to elevated levels of E&S risk through the activities of their borrowers/investees. Such E&S risks can drive financial, legal, and/or reputational risk.
          </p>
          <p>
            <strong>E&S risks</strong><br/>
            &bull; are higher in certain sectors; and<br/> 
            &bull; large infrastructure and industrial projects can have adverse impacts on people and on the environment.<br/>
            Therefore, where an FI has exposures to such high risk sectors, or where it has a larger portfolio of project financing transactions, it will have an elevated risk profile. This tool classifies FIs that have >10% exposure to Project Finance, or >20% exposure in high risk sectors as high risk. In assessing whether such exposures are sufficiently managed in the ESMS of the FI, the user of this tool can test sector risks by making “dummy investments”. For example, if an FI has a large mining exposure in Mongolia, the user can complete the investment section of this tool as if it is a direct investment by the fund in the mining sector in Mongolia, to understand the risks and check whether they are appropriately managed by the FI <a href="@/assets/files/FI+Interpretation+Note+November+2018.pdf" target="_blank">FI Interpretation Note November 2018</a>
          </p>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog :md-active.sync="showFullTable">
      <md-dialog-title>
        <span v-if="type === '1'">Sector Risks</span>
        <span v-if="type === '2'">Country issues</span>
        <span v-if="type === '3'">Mitigants</span>
        <md-button @click="showFullTable = false">
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <md-dialog-content>
        <div v-if="type === '1'">
          <table cellpadding="0" v-if="maxSector > 0" cellspacing="0" border="0">
            <tr>
              <td>
                <strong>Relevance</strong>
              </td>
              <td>
                <strong>Name</strong>
              </td>
              <td :colspan="maxSector">
                <strong>Risks</strong>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps2-labor-revised-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 2 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 2:<br/>Labor</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS2.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS2[item]">
                  <strong>{{sectorPS2[item].title}}</strong>
                  {{sectorPS2[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps3-resource-efficiency-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 3 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 3:<br/>Resource Efficiency</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS3.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS3[item]">
                  <strong>{{sectorPS3[item].title}}</strong>
                  {{sectorPS3[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps4-community-no-text-boxed.png" />
                <ProgressDash  :small="true" :level="subsectorData[`PS 4 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 4:<br/>Community</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS4.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS4[item]">
                  <strong>{{sectorPS4[item].title}}</strong>
                  {{sectorPS4[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps5-land-resettlement-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 5 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 5:<br/>Land Resettlement</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS5.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS5[item]">
                  <strong>{{sectorPS5[item].title}}</strong>
                  {{sectorPS5[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps6-biodiversity-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 6 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 6:<br/>Biodiversity</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS6.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS6[item]">
                  <strong>{{sectorPS6[item].title}}</strong>
                  {{sectorPS6[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps7-indigenous-people-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 7 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 7:<br/>Indigenous People</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorPS7.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorPS7[item]">
                  <strong>{{sectorPS7[item].title}}</strong>
                  {{sectorPS7[item].text}}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps8-cultural-heritage-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 8 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 8:<br/>Cultural Heritage</strong>
              </td>
              <td :colspan="maxSector">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
            </tr>
          </table>
          <p v-if="maxSector === 0" class="center">
            <strong>There are no applicable Sector Risks</strong>
          </p>
        </div>
        <div v-if="type === '2'">
          <table class="country" cellpadding="0" v-if="maxCountry > 0" cellspacing="0" border="0">
            <tr>
              <td class="small">
                <strong>Relevance</strong>
              </td>
              <td>
                <strong>Name</strong>
              </td>
              <td :colspan="maxCountry">
                <strong>Country issues</strong>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps2-labor-revised-no-text-boxed.png" />
              </td>
              <td>
                <strong>IFC PS 2:<br/>Labor</strong>
              </td>
              <td :colspan="maxCountry" v-if="countryPS2.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in countryRange" v-bind:key="item">
                <span v-if="countryPS2[item]">
                  {{ countryPS2[item].title }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps3-resource-efficiency-no-text-boxed.png" />
              </td>
              <td>
                <strong>IFC PS 3:<br/>Resource Efficiency</strong>
              </td>
              <td :colspan="maxCountry" v-if="countryPS3.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in countryRange" v-bind:key="item">
                <span v-if="countryPS3[item]">
                  {{ countryPS3[item].title }}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps6-biodiversity-no-text-boxed.png" />
              </td>
              <td>  
                <strong>IFC PS 8:<br/>Biodiversity</strong>
              </td>
              <td :colspan="maxCountry" v-if="countryPS6.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no issues applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in countryRange" v-bind:key="item">
                <span v-if="countryPS6[item]">
                  {{ countryPS6[item].title }}
                </span>
              </td>
            </tr>
          </table>
          <p v-if="maxCountry === 0" class="center">
            <strong>There are no applicable Country issues</strong>
          </p>
        </div>
        <div v-if="type === '3'">
          <table cellpadding="0" v-if="maxSector > 0" cellspacing="0" border="0">
            <tr>
              <td>
                <strong>Relevance</strong>
              </td>
              <td>
                <strong>Name</strong>
              </td>
              <td :colspan="maxSector">
                <strong>Mitigants</strong>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps2-labor-revised-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 2 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 2:<br/>Labor</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS2.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS2[item]">
                  <strong>{{sectorMitigantsPS2[item].title}}</strong>
                  <span v-html="sectorMitigantsPS2[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps3-resource-efficiency-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 3 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 3:<br/>Resource Efficiency</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS3.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS3[item]">
                  <strong>{{sectorMitigantsPS3[item].title}}</strong>
                  <span v-html="sectorMitigantsPS3[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps4-community-no-text-boxed.png" />
                <ProgressDash  :small="true" :level="subsectorData[`PS 4 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 4:<br/>Community</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS4.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS4[item]">
                  <strong>{{sectorMitigantsPS4[item].title}}</strong>
                  <span v-html="sectorMitigantsPS4[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps5-land-resettlement-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 5 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 5:<br/>Land Resettlement</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS5.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS5[item]">
                  <strong>{{sectorMitigantsPS5[item].title}}</strong>
                  <span v-html="sectorMitigantsPS5[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps6-biodiversity-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 6 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 6:<br/>Biodiversity</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS6.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS6[item]">
                  <strong>{{sectorMitigantsPS6[item].title}}</strong>
                  <span v-html="sectorMitigantsPS6[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps7-indigenous-people-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 7 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 7:<br/>Indigenous People</strong>
              </td>
              <td :colspan="maxSector" v-if="sectorMitigantsPS7.length === 0">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
              <td v-else v-for="item in sectorRange" v-bind:key="item">
                <span v-if="sectorMitigantsPS7[item]">
                  <strong>{{sectorMitigantsPS7[item].title}}</strong>
                  <span v-html="sectorMitigantsPS7[item].text"></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <img src="/images/ps8-cultural-heritage-no-text-boxed.png" />
                <ProgressDash :small="true" :level="subsectorData[`PS 8 relevance`]" />
              </td>
              <td>
                <strong>IFC PS 8:<br/>Cultural Heritage</strong>
              </td>
              <td :colspan="maxSector">
                N/A
                <md-tooltip md-direction="bottom">Relevance threshold not passed, therefore no risks applicable.</md-tooltip>
              </td>
            </tr>
          </table>
          <p v-if="maxCountry === 0" class="center">
            <strong>There are no applicable Country issues</strong>
          </p>
        </div>
      </md-dialog-content>
    </md-dialog>
  </main>
</template>

<style lang="scss" scoped>
.links {
  padding: 15px 20px;
  text-align: left;
}
.md-card-header {
  position: relative;
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  .utils {
    position: absolute;
    right: 16px;
    top: 16px;
    min-width: inherit;
    display: flex;
    align-items: center;
    .md-switch {
      margin: 0 10px 0 0;
      min-width: 120px;
    }
    .md-field {
      margin: 0; 
      min-height: 32px;
      padding-top: 0;
    }
    button {
      margin: 0 10px;
      padding: 0;
      min-width: auto;
    }
    i.info {
      margin-left: 10px;
    }
  }
}
.md-card-content {
  overflow: auto;
  overflow-y: hidden;
  padding: 0;
  td {
    width: calc((((100vw - 30px) / 3) / 3) - 50px);
    &:nth-child(2) {
      width: 100px;
      text-align: left;
    }
    &:first-child {
      width: 88px;
      text-align: center;
    }
  }
}
table {
  min-width: 600px;
  width: 100%;
  &.country {
    td {
      padding: 20px 12px;
    }
  }
}
th {
  font-weight: normal;
  padding: 10px 12px;
}
tr:first-child {
  td {
    padding-bottom: 10px;
  }
}
td {
  padding: 10px 12px;
  font-size: 12px;
  &:not(:first-child) {
    strong {
      display: block;
    }
  }
  &:first-child {
    padding-bottom: 0;
    width: 40px;
    text-align: left;
    img {
      width: 40px;
    }
  }
}
tr:nth-child(even) {
  td {
    background: rgba(0, 0, 0, 0.1);
  }
}
.md-dialog-content {
  padding: 0 0 20px;
  p.center {
    text-align: center;
    min-width: 500px;
  }
  td {
    min-width: 300px;
    text-align: center;
    &:nth-child(2) {
      width: 100px;
      min-width: initial;
      text-align: left;
    }
    &:first-child {
      width: 88px;
      min-width: initial;
      text-align: center;
    }
  }
}
.md-card {
  margin-bottom: 10px;
}
</style>

<script>
import ProgressDash from '@/components/commom/ProgressDash';
import axios from "axios";
import Mitigants from "@/assets/mitigants.json";

export default {
  name: "CardSectorRisk",
  data() {
    return {
      showFullTable: false,
      user: this.$store.state.user,
      type: '1',
      typeName: 'Sector',
      subsectorData: {},
      countryRisk: {},
      showTable: true,
      maxSector: 0,
      sectorRange: [],
      maxCountry: 0,
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
      countryRange: [],
      sectorPS2: [],
      sectorPS2items: ['Working Conditions and OHS', 'Exposure to hazardous waste/materials', 'Contract violations and labour standards', 'Child and forced labour', 'Worker relationship', 'Supply Chain Issues', 'Contractors, Third Parties, and Temporary Workers'],
      sectorPS3: [],
      sectorPS3items: ['Indirect risks: Polluting activities', 'Pesticide use', 'Soil & Groundwater', 'Historical pollution', 'Air Emissions (and Ambient Air Quality)', 'Radioactivity', 'Energy use', 'Water use, Wastewater (and Ambient Water Quality), Water pollution', 'Hazardous Materials', 'Waste', 'Other forms of pollution', 'GHG emissions / Climate change'],
      sectorPS4: [],
      sectorPS4items: ['Environmental and Natural resources issues (rel to communities)', 'Security personnel', 'Infrastructure and equipment safety Hazardous Materials Safety', 'Transport of Hazardous Materials / Traffic Safety', 'Exposure to disease / Disease Prevention'],
      sectorPS5: [],
      sectorPS5items: ['Physical or Economic displacement', 'Land acquisition and involuntary resettlement'],
      sectorPS6: [],
      sectorPS6items: ['Animal welfare', 'Impact on traditional or customary lands', 'Legally protected Areas', '(Critical) habitat', 'Biodiversity and habitat', 'Supply chain issues'],
      sectorPS7: [],
      sectorPS7items: ['Check whether indigenous people', 'Impact on traditional or customary lands', 'Relocation of Indigenous people / Involuntary resettlement'],
      countryPS2: [],
      countryPS2items: ['Gender, equal opportunities, discrimination', 'Trade Union Rights (freedom of association, collective bargaining, strike)', 'Child labour', 'Freedom of association'],
      countryPS3: [],
      countryPS3items: ['Air Quality', 'Water Sanitation', 'Heavy metals', 'Air pollution', 'Climate and Energy', 'Water Resources'],
      countryPS6: [],
      countryPS6items: ['Biodiversity &Habitat', 'Forests', 'Fisheries', 'Agriculture'],
      sectorMitigantsPS2: [],
      sectorMitigantsPS3: [],
      sectorMitigantsPS4: [],
      sectorMitigantsPS5: [],
      sectorMitigantsPS6: [],
      sectorMitigantsPS7: [],
    }
  },
  components: {
    ProgressDash
  },
  props: ["item", "data"],
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let sector_id = this.item.sector_id;
      let subsector_id = this.item.subsector_id;
      if (subsector_id === 850) {
        subsector_id = 849
        sector_id = 848
      }
      if (subsector_id === 780) {
        subsector_id = 770
        sector_id = 831
      }

      this.data.sectors.map((sector) => {
        if (sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (subsector_id === subsector.id) 
              this.subsectorData = subsector;
              this.sectorPS2 = []
              this.sectorMitigantsPS2 = []
              this.sectorPS3 = []
              this.sectorMitigantsPS3 = []
              this.sectorPS4 = []
              this.sectorMitigantsPS4 = []
              this.sectorPS5 = []
              this.sectorMitigantsPS5 = []
              this.sectorPS6 = []
              this.sectorMitigantsPS6 = []
              this.sectorPS7 = []
              this.sectorMitigantsPS7 = []
              for(const key in this.subsectorData) {
                if (this.sectorPS2items.indexOf(key) >= 0 && this.subsectorData[`PS 2 relevance`] >= 3) {
                  this.sectorPS2.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS2.push({"title": key, "text": Mitigants[key]});
                }
                if (this.sectorPS3items.indexOf(key) >= 0 && this.subsectorData[`PS 3 relevance`] >= 3) {
                  this.sectorPS3.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS3.push({"title": key, "text": Mitigants[key]});
                }
                if (this.sectorPS4items.indexOf(key) >= 0 && this.subsectorData[`PS 4 relevance`] >= 3) {
                  this.sectorPS4.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS4.push({"title": key, "text": Mitigants[key]});
                }
                if (this.sectorPS5items.indexOf(key) >= 0 && this.subsectorData[`PS 5 relevance`] >= 3) {
                  this.sectorPS5.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS5.push({"title": key, "text": Mitigants[key]});
                }
                if (this.sectorPS6items.indexOf(key) >= 0 && this.subsectorData[`PS 6 relevance`] >= 3) {
                  this.sectorPS6.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS6.push({"title": key, "text": Mitigants[key]});
                }
                if (this.sectorPS7items.indexOf(key) >= 0 && this.subsectorData[`PS 7 relevance`] >= 3) {
                  this.sectorPS7.push({"title": key, "text": this.subsectorData[key].toLowerCase().trim() !== 'x' ? this.subsectorData[key] : ''});
                  this.sectorMitigantsPS7.push({"title": key, "text": Mitigants[key]});
                }
              }
          })
        }
      });
      this.maxSector = Math.max(this.sectorPS2.length, this.sectorPS3.length, this.sectorPS4.length, this.sectorPS5.length, this.sectorPS6.length, this.sectorPS7.length);
      this.sectorRange = new Array(this.maxSector - 0).fill().map((d, i) => i + 0);
      this.data.countries.map((country) => {
        if (this.item.country_id === country.id) {
          this.countryRisk = country.risk;
          this.countryPS2 = []
          this.countryPS3 = []
          this.countryPS6 = []
          for(const key in this.countryRisk) {
            if (this.countryPS2items.indexOf(key) >= 0) {
              if (key === 'Gender, equal opportunities, discrimination' && parseFloat(this.countryRisk[key]) < 0.7)
                this.countryPS2.push({"title": key, "value": this.countryRisk[key]});
              if (key === 'Trade Union Rights (freedom of association, collective bargaining, strike)' && parseFloat(this.countryRisk[key]) > 1)
                this.countryPS2.push({"title": key, "value": this.countryRisk[key]});
              if (key === 'Child labour' && parseFloat(this.countryRisk[key]) > 0)
                this.countryPS2.push({"title": key, "value": this.countryRisk[key]});
            }
            if (this.countryPS3items.indexOf(key) >= 0 && parseFloat(this.countryRisk[key]) <= 90)
              this.countryPS3.push({"title": key, "value": this.countryRisk[key]});
            if (this.countryPS6items.indexOf(key) >= 0 && parseFloat(this.countryRisk[key]) <= 90)
              this.countryPS6.push({"title": key, "value": this.countryRisk[key]});
          }
        }
      });
      this.maxCountry = Math.max(this.countryPS2.length, this.countryPS3.length, this.countryPS6.length);
      this.countryRange = new Array(this.maxCountry - 0).fill().map((d, i) => i + 0);
    },
    getText(string) {
      if (!string || string.toLowerCase().trim() === 'x')
        return '';
      return string;
    },
    getMitigant(string) {
      return string.split('<br/>')[0];
    },
    ellipsis(string, value) {
      if (!string)
        return '';
      if (string.toLowerCase().trim() === 'x') {
        if (value && value.length > 80)
          return value.substr(0, 80) + '...';
        return value;
      } else {
        if (string && string.length > 80)
          return string.substr(0, 80) + '...';
        return string;
      }
    },
    download() {
      this.$store.commit("toggleLoading")

      this.data.sectors.map((sector) => {
        if (this.item.sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (this.item.subsector_id === subsector.id)
              this.subsectorData = subsector;
          });
        }
      });
      this.data.countries.map((country) => {
        if (this.item.country_id === country.id) {
          this.countryTi = country.ti;
        }
      });
      this.bi = this.subsectorData["sector corruption risk"];
      let riskContryTi = 'Low'
      if (this.countryTi < 20) {
        riskContryTi = 'High'
      }
      if (this.countryTi < 70) {
        riskContryTi = 'Medium'
      }
      if ((this.bi === 'medium' || this.bi === 'low') && riskContryTi === 'High')
        this.bi = 'High';
      if (this.bi === 'low')
        this.bi = riskContryTi;

      axios
        .post(`${window.api}report/sectorRisk/?jwt=${this.user.token}`, {
          letters: this.letters,
          subsectorData: this.subsectorData,
          maxSector: this.maxSector,
          sectorPS2: this.sectorPS2,
          sectorPS3: this.sectorPS3,
          sectorPS4: this.sectorPS4,
          sectorPS5: this.sectorPS5,
          sectorPS6: this.sectorPS6,
          sectorPS7: this.sectorPS7,
          maxCountry: this.maxCountry,
          countryPS2: this.countryPS2,
          countryPS3: this.countryPS3,
          countryPS6: this.countryPS6,
          sectorMitigantsPS2: this.sectorMitigantsPS2,
          sectorMitigantsPS3: this.sectorMitigantsPS3,
          sectorMitigantsPS4: this.sectorMitigantsPS4,
          sectorMitigantsPS5: this.sectorMitigantsPS5,
          sectorMitigantsPS6: this.sectorMitigantsPS6,
          sectorMitigantsPS7: this.sectorMitigantsPS7,
          investment: this.item,
          bi: this.bi,
        })
        .then((response) => {
          window.open(`${window.api}${response.data.path}`);
          this.$store.commit("toggleLoading")
        });
    }
  },
  watch: {
    data() {
      this.getData();
    },
    item() {
      this.getData();
    },
    type() {
      if (this.type === '3') {
        this.showFullTable = true;
      }
    },
    showFullTable() {
      if (this.type === '3' && !this.showFullTable) {
        this.type = '1'
      }
    }
  }
}
</script>
