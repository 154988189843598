<template>
  <md-card>
    <md-card-header>
      <div class="md-title">ESG management benchmark - {{ section }}</div>
      <div class="utils">
        <md-button v-if="selectedLabel" @click="back" class="md-raise md-dense">Back</md-button>
        <md-icon>
          info
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: ESG Management benchmark']"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content>
      <div v-if="this.item.review">
        <div class="toggle" v-if="subsectorReviews.length > 0">
          <md-switch v-model="type" class="md-primary">
            <span v-if="type">Sub-sector comparison</span>
            <span v-if="!type">Investment in {{fund.name}}</span>
          </md-switch>
          <span v-if="type">Peer group size: {{subsectorReviews.length}}</span>
        </div>
        <vue-plotly :autoResize="true" :options="options" :data="chartData" v-on:hover="tooltip" v-on:unhover="tooltipText = ''" v-on:click="drilldown" :layout="layout" />
        <span class="tooltip" v-if="tooltipText.length > 0" :style="{'top': `${posY}px`}">{{tooltipText}}</span>
      </div>
      <div v-if="!this.item.review">
        <p>
          This investment doesn't have a review yet.
        </p>
      </div>
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.md-card-content {
  position: relative;
  padding-top: 20px;
  .toggle {
    display: flex;
    justify-content: space-between;
    .md-switch {
      margin: 0 0 20px;
    }
  }
  .tooltip {
    position: absolute;
    left: 120px;
    background: rgba(0, 0, 0, 0.7);
    padding: 0 5px;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
  }
}
.md-card-header {
  display: flex;
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  justify-content: space-between;
  .utils {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
  }
}
.title {
  display: block;
  margin: 10px 0;
  text-align: center;
}
</style>

<script>
import VuePlotly from '@statnett/vue-plotly'

export default {
  name: "CardManagementComparison",
  props: ["item"],
  components: {
    VuePlotly
  },
  data() {
    return {
      options: {
        autosizable: true,
        responsive: true
      },
      type: false,
      tooltipText: '',
      data: this.$store.state.data,
      fund: this.$store.state.fund,
      chartData: [],
      section: 'All',
      titleFull: {},
      posY: 0,
      subsectorReviews: this.$store.state.subsectorReviews,
      selectedLabel: false,
      layout: {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 30, "r": 0},
        showlegend: true,
        xaxis: {
          range: [0, 105],
        },
      }
    }
  },
  mounted() {
    if (!this.item.review || !this.item.reviewScore.esms)
      return false;
    if (this.item)
      this.getValues();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'getSubsectorReviews') {
        this.subsectorReviews = state.subsectorReviews;
      }
    });
  },
  methods: {
    tooltip(event) {
      this.tooltipText = '';
      if (this.titleFull[event.points[0].label]) {
        this.posY = ((230 / this.chartData[0].y.length) * (this.chartData[0].y.length - event.points[0].pointNumber));
        this.tooltipText = this.titleFull[event.points[0].label];
      }
    },
    drilldown(event) {
      if (this.selectedLabel)
        return false;
      this.selectedLabel = true;
      this.section = event.points[0].label.trim();
      this.getValues();
    },
    back() {
      this.selectedLabel = false;
      this.section = 'All';
      this.getValues();
    },
    getAll() {
      if (!this.item.reviewScore)
        return;
      const esmsTotal = [];
      const corporateTotal = [];
      const biTotal = [];
      if (this.type) {
        this.subsectorReviews.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          esmsTotal.push(item.reviewScore.esms.total)
          corporateTotal.push(item.reviewScore.corporate.total)
          biTotal.push(item.reviewScore.bi.total)
        })
      } else {
        this.fund.investments.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          esmsTotal.push(item.reviewScore.esms.total)
          corporateTotal.push(item.reviewScore.corporate.total)
          biTotal.push(item.reviewScore.bi.total)
        })
      }
      const esmsTotalPercentage = parseInt(esmsTotal.reduce((a, b) => a + b) / esmsTotal.length);
      const corporateTotalPercentage = parseInt(corporateTotal.reduce((a, b) => a + b) / corporateTotal.length);
      const biTotalPercentage = parseInt(biTotal.reduce((a, b) => a + b) / biTotal.length);
      let totals = [biTotalPercentage, corporateTotalPercentage, esmsTotalPercentage];
      this.chartData.push({
        y: ['BI  ', 'CG  ', 'E&S  '],
        x: [
          biTotalPercentage,
          corporateTotalPercentage,
          esmsTotalPercentage,
        ],
        name: 'Peers comparison',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        marker: {
          color: ["#AACCFF", "#AACCFF", "#AACCFF"]
        },
        type: 'bar',
        orientation: 'h',
      });
      const esms = parseInt(this.item.reviewScore.esms.total);
      const corporate = parseInt(this.item.reviewScore.corporate.total);
      const bi = parseInt(this.item.reviewScore.bi.total);
      totals = [bi, corporate, esms];
      this.chartData.push({
        y: ['BI  ', 'CG  ', 'E&S  '],
        x: [
          bi,
          corporate, 
          esms, 
        ],
        marker: {
          color: ["#015495", "#015495", "#015495"]
        },
        name: 'Investment',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        type: 'bar',
        orientation: 'h',
      });
      this.layout = {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 30, "r": 0},
        showlegend: true,
        legend: {
          orientation: 'h',
          valign: true,
          itemclick: false,
          itemdoubleclick: false,
        },
        xaxis: {
          range: [0, 105],
        },
      }
    },
    getEsm() {
      const t1 = [];
      const t2 = [];
      const t3 = [];
      const t4 = [];
      const t5 = [];
      const t6 = [];
      const t7 = [];
      if (this.type) {
        this.subsectorReviews.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          t1.push(item.reviewScore.esms.s1.total)
          t2.push(item.reviewScore.esms.s2.total)
          t3.push(item.reviewScore.esms.s3.total)
          t4.push(item.reviewScore.esms.s4.total)
          t5.push(item.reviewScore.esms.s5.total)
          t6.push(item.reviewScore.esms.s6.total)
          t7.push(item.reviewScore.esms.s7.total)
        })
      } else {
        this.fund.investments.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          t1.push(item.reviewScore.esms.s1.total)
          t2.push(item.reviewScore.esms.s2.total)
          t3.push(item.reviewScore.esms.s3.total)
          t4.push(item.reviewScore.esms.s4.total)
          t5.push(item.reviewScore.esms.s5.total)
          t6.push(item.reviewScore.esms.s6.total)
          t7.push(item.reviewScore.esms.s7.total)
        })
      }
      const t1TotalPercentage = parseInt(t1.reduce((a, b) => a + b) / t1.length);
      const t2TotalPercentage = parseInt(t2.reduce((a, b) => a + b) / t2.length);
      const t3TotalPercentage = parseInt(t3.reduce((a, b) => a + b) / t3.length);
      const t4TotalPercentage = parseInt(t4.reduce((a, b) => a + b) / t4.length);
      const t5TotalPercentage = parseInt(t5.reduce((a, b) => a + b) / t5.length);
      const t6TotalPercentage = parseInt(t6.reduce((a, b) => a + b) / t6.length);
      const t7TotalPercentage = parseInt(t7.reduce((a, b) => a + b) / t7.length);
      let totals = [t7TotalPercentage, t6TotalPercentage, t5TotalPercentage, t4TotalPercentage, t3TotalPercentage, t2TotalPercentage, t1TotalPercentage];
      const y = ['Policy  ', 'ESG Risk and Opportunity Identification'.substr(0, 10) + '...  ', 'Organisational Capacity and Competency'.substr(0, 10) + '...  ', 'Processes  ', 'Performance Management and Monitoring'.substr(0, 10) + '...  ', 'Reporting  ', 'Emergency Response and Preparedness'.substr(0, 10) + '...  '];
      this.chartData.push({
        y: y.reverse(),
        x: [
          t1TotalPercentage,
          t2TotalPercentage,
          t3TotalPercentage,
          t4TotalPercentage,
          t5TotalPercentage,
          t6TotalPercentage,
          t7TotalPercentage,
        ].reverse(),
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        name: 'Peers comparison',
        marker: {
          color: ["#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF"]
        },
        type: 'bar',
        orientation: 'h',
      });
      const s1 = parseInt(this.item.reviewScore.esms.s1.total);
      const s2 = parseInt(this.item.reviewScore.esms.s2.total);
      const s3 = parseInt(this.item.reviewScore.esms.s3.total);
      const s4 = parseInt(this.item.reviewScore.esms.s4.total);
      const s5 = parseInt(this.item.reviewScore.esms.s5.total);
      const s6 = parseInt(this.item.reviewScore.esms.s6.total);
      const s7 = parseInt(this.item.reviewScore.esms.s7.total);
      totals = [s7, s6, s5, s4, s3, s2, s1];
      this.chartData.push({
        y: y.reverse(),
        x: [
          s1,
          s2,
          s3,
          s4,
          s5,
          s6,
          s7,
        ].reverse(),
        marker: {
          color: ["#015495", "#015495", "#015495", "#015495", "#015495", "#015495", "#015495"]
        },
        name: 'Investment',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        type: 'bar',
        orientation: 'h',
      });
      this.titleFull[y[0]] = "Policy"
      this.titleFull[y[1]] = "ESG Risk and Opportunity Identification"
      this.titleFull[y[2]] = "Organisational Capacity and Competency"
      this.titleFull[y[3]] = "Processes"
      this.titleFull[y[4]] = "Performance Management and Monitoring"
      this.titleFull[y[5]] = "Reporting"
      this.titleFull[y[6]] = "Emergency Response and Preparedness"
      this.layout = {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 95, "r": 0},
        showlegend: true,
        legend: {
          orientation: 'h',
          valign: true,
          itemclick: false,
          itemdoubleclick: false,
        },
        xaxis: {
          range: [0, 108],
        },
      }
    },
    getCg() {
      if (this.item.is_sme === 1) {
        this.getCGSME();
      } else {
        this.getCGNotSME();
      }
    },
    getBi() {
      const t1 = [];
      const t2 = [];
      const t3 = [];
      const t4 = [];
      const t5 = [];
      const t6 = [];
      if (this.type) {
        this.subsectorReviews.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          t1.push(item.reviewScore.esms.s1.total)
          t2.push(item.reviewScore.esms.s2.total)
          t3.push(item.reviewScore.esms.s3.total)
          t4.push(item.reviewScore.esms.s4.total)
          t5.push(item.reviewScore.esms.s5.total)
          t6.push(item.reviewScore.esms.s6.total)
        })
      } else {
        this.fund.investments.map((item) => {
          if (!item.reviewScore.esms)
            return false;
          t1.push(item.reviewScore.esms.s1.total)
          t2.push(item.reviewScore.esms.s2.total)
          t3.push(item.reviewScore.esms.s3.total)
          t4.push(item.reviewScore.esms.s4.total)
          t5.push(item.reviewScore.esms.s5.total)
          t6.push(item.reviewScore.esms.s6.total)
        })
      }
      const t1TotalPercentage = parseInt(t1.reduce((a, b) => a + b) / t1.length);
      const t2TotalPercentage = parseInt(t2.reduce((a, b) => a + b) / t2.length);
      const t3TotalPercentage = parseInt(t3.reduce((a, b) => a + b) / t3.length);
      const t4TotalPercentage = parseInt(t4.reduce((a, b) => a + b) / t4.length);
      const t5TotalPercentage = parseInt(t5.reduce((a, b) => a + b) / t5.length);
      const t6TotalPercentage = parseInt(t6.reduce((a, b) => a + b) / t6.length);
      let totals = [t6TotalPercentage, t5TotalPercentage, t4TotalPercentage, t3TotalPercentage, t2TotalPercentage, t1TotalPercentage];
      const y = [" Business Integrity Management System (BIMS)".substr(0, 11) + "... ", "Business Integrity Alert and Economic Sanctions".substr(0, 10) + "... ", "Compliance with laws and regulations".substr(0, 10) + "... ", "Anti-Bribery and Corruption (ABC) Management".substr(0, 10) + "... ", "Whistleblower system".substr(0, 10) + "... ", "Cyber and data security".substr(0, 10) + "... "];
      this.chartData.push({
        y: y.reverse(),
        x: [
          t1TotalPercentage,
          t2TotalPercentage,
          t3TotalPercentage,
          t4TotalPercentage,
          t5TotalPercentage,
          t6TotalPercentage,
        ].reverse(),
        name: 'Peers average',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        marker: {
          color: ["#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF"]
        },
        type: 'bar',
        orientation: 'h',
      });
      const s1 = parseInt(this.item.reviewScore.esms.s1.total);
      const s2 = parseInt(this.item.reviewScore.esms.s2.total);
      const s3 = parseInt(this.item.reviewScore.esms.s3.total);
      const s4 = parseInt(this.item.reviewScore.esms.s4.total);
      const s5 = parseInt(this.item.reviewScore.esms.s5.total);
      const s6 = parseInt(this.item.reviewScore.esms.s6.total);
      totals = [s6, s5, s4, s3, s2, s1];
      this.chartData.push({
        y: y.reverse(),
        x: [
          s1,
          s2,
          s3,
          s4,
          s5,
          s6,
        ].reverse(),
        marker: {
          color: ["#015495", "#015495", "#015495", "#015495", "#015495", "#015495"]
        },
        name: 'Investment',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        type: 'bar',
        orientation: 'h',
      });
      this.titleFull[y[0]] = "Business Integrity Management System (BIMS)";
      this.titleFull[y[1]] = "Business Integrity Alert and Economic Sanctions";
      this.titleFull[y[2]] = "Compliance with laws and regulations";
      this.titleFull[y[3]] = "Anti-Bribery and Corruption (ABC) Management";
      this.titleFull[y[4]] = "Whistleblower system";
      this.titleFull[y[5]] = "Cyber and data security";
      this.layout = {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 95, "r": 0},
        showlegend: true,
        legend: {
          orientation: 'h',
          valign: true,
          itemclick: false,
          itemdoubleclick: false,
        },
        xaxis: {
          range: [0, 108],
        },
      }
    },
    getCGNotSME() {
      const t1 = [];
      const t2 = [];
      const t3 = [];
      const t4 = [];
      const t5 = [];
      if (this.type) {
        this.subsectorReviews.map((item) => {
          if (!item.reviewScore.corporate && item.is_sme === 0)
            return false;
          t1.push(item.reviewScore.corporate.s1.total)
          t2.push(item.reviewScore.corporate.s2.total)
          t3.push(item.reviewScore.corporate.s3.total)
          t4.push(item.reviewScore.corporate.s4.total)
          t5.push(item.reviewScore.corporate.s5.total)
        })
      } else {
        this.fund.investments.map((item) => {
          if (!item.reviewScore.corporate && item.is_sme === 0)
            return false;
          t1.push(item.reviewScore.corporate.s1.total)
          t2.push(item.reviewScore.corporate.s2.total)
          t3.push(item.reviewScore.corporate.s3.total)
          t4.push(item.reviewScore.corporate.s4.total)
          t5.push(item.reviewScore.corporate.s5.total)
        })
      }
      const t1TotalPercentage = parseInt(t1.reduce((a, b) => a + b) / t1.length);
      const t2TotalPercentage = parseInt(t2.reduce((a, b) => a + b) / t2.length);
      const t3TotalPercentage = parseInt(t3.reduce((a, b) => a + b) / t3.length);
      const t4TotalPercentage = parseInt(t4.reduce((a, b) => a + b) / t4.length);
      const t5TotalPercentage = parseInt(t5.reduce((a, b) => a + b) / t5.length);
      let totals = [t5TotalPercentage, t4TotalPercentage, t3TotalPercentage, t2TotalPercentage, t1TotalPercentage];
      const y = ["Commitment to CG".substr(0, 10) + "...  ", "Board Structure and Functioning".substr(0, 10) + "...  ", "Control Environment and Processes".substr(0, 10) + "...  ", "Transparency and Disclosure".substr(0, 10) + "...  ", "Shareholder Rights".substr(0, 10) + "...  "]
      this.chartData.push({
        y: y.reverse(),
        x: [
          t1TotalPercentage,
          t2TotalPercentage,
          t3TotalPercentage,
          t4TotalPercentage,
          t5TotalPercentage,
        ].reverse(),
        name: 'Peers average',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        marker: {
          color: ["#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF"]
        },
        type: 'bar',
        orientation: 'h',
      });
      const s1 = parseInt(this.item.reviewScore.corporate.s1.total);
      const s2 = parseInt(this.item.reviewScore.corporate.s2.total);
      const s3 = parseInt(this.item.reviewScore.corporate.s3.total);
      const s4 = parseInt(this.item.reviewScore.corporate.s4.total);
      const s5 = parseInt(this.item.reviewScore.corporate.s5.total);
      totals = [s5, s4, s3, s2, s1];
      this.chartData.push({
        y: y.reverse(),
        x: [
          s1,
          s2,
          s3,
          s4,
          s5,
        ].reverse(),
        marker: {
          color: ["#015495", "#015495", "#015495", "#015495", "#015495"]
        },
        name: 'Investment',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        type: 'bar',
        orientation: 'h',
      });
      this.titleFull[y[0]] = "Commitment to CG";
      this.titleFull[y[1]] = "Board Structure and Functioning";
      this.titleFull[y[2]] = "Control Environment and Processes";
      this.titleFull[y[3]] = "Transparency and Disclosure";
      this.titleFull[y[4]] = "Shareholder Rights";
      this.layout = {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 95, "r": 0},
        showlegend: true,
        legend: {
          orientation: 'h',
          valign: true,
          itemclick: false,
          itemdoubleclick: false,
        },
        xaxis: {
          range: [0, 108],
        },
      }
    },
    getCGSME() {
      const t1 = [];
      const t2 = [];
      const t3 = [];
      const t4 = [];
      const t5 = [];
      const t6 = [];
      if (this.type) {
        this.subsectorReviews.map((item) => {
          if (!item.reviewScore.corporate && item.is_sme === 1)
            return false;
          t1.push(item.reviewScore.corporate.s1.total)
          t2.push(item.reviewScore.corporate.s2.total)
          t3.push(item.reviewScore.corporate.s3.total)
          t4.push(item.reviewScore.corporate.s4.total)
          t5.push(item.reviewScore.corporate.s5.total)
          t6.push(item.reviewScore.corporate.s6.total)
        })
      } else {
        this.fund.investments.map((item) => {
          if (!item.reviewScore.corporate && item.is_sme === 1)
            return false;
          t1.push(item.reviewScore.corporate.s1.total)
          t2.push(item.reviewScore.corporate.s2.total)
          t3.push(item.reviewScore.corporate.s3.total)
          t4.push(item.reviewScore.corporate.s4.total)
          t5.push(item.reviewScore.corporate.s5.total)
          t6.push(item.reviewScore.corporate.s6.total)
        })
      }
      const t1TotalPercentage = parseInt(t1.reduce((a, b) => a + b) / t1.length);
      const t2TotalPercentage = parseInt(t2.reduce((a, b) => a + b) / t2.length);
      const t3TotalPercentage = parseInt(t3.reduce((a, b) => a + b) / t3.length);
      const t4TotalPercentage = parseInt(t4.reduce((a, b) => a + b) / t4.length);
      const t5TotalPercentage = parseInt(t5.reduce((a, b) => a + b) / t5.length);
      const t6TotalPercentage = parseInt(t6.reduce((a, b) => a + b) / t6.length);
      let totals = [t6TotalPercentage, t5TotalPercentage, t4TotalPercentage, t3TotalPercentage, t2TotalPercentage, t1TotalPercentage];
      const y = ["Commitment to CG".substr(0, 10) + "...  ", "Decision making and strategic oversight".substr(0, 10) + "...  ", "Control Environment and Processes".substr(0, 10) + "...  ", "Transparency and Disclosure".substr(0, 10) + "...  ", "Ownership: shareholder".substr(0, 10) + "...  ", " Ownership: family owned companies".substr(0, 11) + "...  "]
      this.chartData.push({
        y: y.reverse(),
        x: [
          t1TotalPercentage,
          t2TotalPercentage,
          t3TotalPercentage,
          t4TotalPercentage,
          t5TotalPercentage,
          t6TotalPercentage,
        ].reverse(),
        name: 'Peers average',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        marker: {
          color: ["#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF", "#AACCFF"]
        },
        type: 'bar',
        orientation: 'h',
      });
      const s1 = parseInt(this.item.reviewScore.corporate.s1.total);
      const s2 = parseInt(this.item.reviewScore.corporate.s2.total);
      const s3 = parseInt(this.item.reviewScore.corporate.s3.total);
      const s4 = parseInt(this.item.reviewScore.corporate.s4.total);
      const s5 = parseInt(this.item.reviewScore.corporate.s5.total);
      const s6 = parseInt(this.item.reviewScore.corporate.s6.total);
      totals = [s6, s5, s4, s3, s2, s1];
      this.chartData.push({
        y: y.reverse(),
        x: [
          s1,
          s2,
          s3,
          s4,
          s5,
          s6,
        ].reverse(),
        marker: {
          color: ["#015495", "#015495", "#015495", "#015495", "#015495", "#015495"]
        },
        name: 'Investment',
        hoverinfo: 'none',
        text: totals.map(String),
        textposition: "outside",
        type: 'bar',
        orientation: 'h',
      });
      this.titleFull[y[0]] = "Commitment to CG";
      this.titleFull[y[1]] = "Decision making and strategic oversight";
      this.titleFull[y[2]] = "Control Environment and Processes";
      this.titleFull[y[3]] = "Transparency and Disclosure";
      this.titleFull[y[4]] = "Ownership: shareholder";
      this.titleFull[y[5]] = "Ownership: family owned companies";
      this.layout = {
        height: 260,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 95, "r": 0},
        showlegend: true,
        legend: {
          orientation: 'h',
          valign: true,
          itemclick: false,
          itemdoubleclick: false,
        },
        xaxis: {
          range: [0, 108],
        },
      }
    },
    getValues() {
      if (!this.fund.investments || !this.item) 
        return false;
      this.$store.commit("getInvestmentsSubsectors");
      this.chartData = [];
      switch (this.section) {
        case 'All':
          this.getAll();
          break;
        case 'E&S':
          this.getEsm();
          break;
        case 'BI':
          this.getBi();
          break;
        case 'CG':
          this.getCg();
          break;
      }
    }
  },
  watch: {
    item() {
      this.getValues();
    },
    type() {
      this.getValues();
    }
  }
}
</script>
