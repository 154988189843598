<template>
  <div v-if="level" :class="isSmall()">
    <span v-for="indexFull in parseInt(level)" v-bind:key="indexFull" class="active">-</span>
    <span v-if="level < 5">    
      <span v-for="indexEmpty in (5 - parseInt(level))" v-bind:key="indexEmpty">-</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ProgressDash",
  props: ["level", "small"],
  methods: {
    isSmall() {
      return this.small ? 'small' : '';
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  color: rgba(0, 0, 0, 0.4);
  font-size: 38px;
  line-height: 10px;
  &.active {
    color: rgb(2, 84, 149);
  }
}
.small {
  span {
    font-size: 24px;
  }
}
</style>