<template>
  <form novalidate class="md-layout" @submit.prevent="validatePassword">
    <md-card md-with-hover>
      <md-card-header>
        <div class="md-title">Update my password</div>
      </md-card-header>
      <md-card-content>
        <md-field :class="getValidationClass('password')">
          <label for="name">New password</label>
          <md-input
            type="password"
            name="password"
            v-model="form.password"
            id="password"/>
        </md-field>
      </md-card-content>
      <md-card-actions>
        <md-button type="submit" class="md-primary md-raised save">Update password</md-button>
      </md-card-actions>
    </md-card>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "Form",
  mixins: [validationMixin],
  data() {
    return {
      user: this.$store.state.user,
      form: {
        password: "",
      },
      message: "",
      showSnackbar: false,
    }
  },
  validations: {
    form: {
      password: {
        required,
      }
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validatePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updatePassword()
      }
    },
    updatePassword() {
      axios
        .post(`${window.api}user/${this.user.id}/update-password/?jwt=${this.user.token}`, {
          pwd: this.form.password,
        })
        .then(response => {
          if (response.data.status) {
            this.message = "Password updated";
            this.showSnackbar = true;
            this.form.password = null;
            this.$v.$reset()
          } else {
            this.message = "Something went wrong";
            this.showSnackbar = true;
          }
        })
        .catch(() => {
          this.message = "Something went wrong";
          this.showSnackbar = true;          
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.md-card,
form {
  width: calc(100% - 10px);
  margin-left: 20px;
}
@media(max-width: 789px) {
  .md-card {
    margin-top: 20px;
  }
}
</style>
