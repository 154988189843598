<template>
  <main>
    <md-app>
      <md-app-toolbar>
        <Bar />
      </md-app-toolbar>

      <md-app-drawer md-permanent="full">
        <Menu />
      </md-app-drawer>

      <md-app-content>
        <div class="breadcrumbs">
          <router-link to="/admin">Home</router-link> / Feedbacks
        </div>
        <Grid />
      </md-app-content>
    </md-app>
  </main>
</template>

<script>
import Bar from '../../../components/admin/Bar';
import Menu from '../../../components/admin/Menu';
import Grid from '../../../components/admin/feedbacks/Grid';

export default {
  name: "AdminFeedbacks",
  components: {
    Menu,
    Grid,
    Bar,
  }
};
</script>
