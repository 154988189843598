<template>
  <main>
    <md-card class="md-layout-item md-elevation-8">
      <md-card-header>
        <router-link to="/create-account" class="back">
          Back
        </router-link>
        <div class="md-title">
          ESG Toolkit - Terms
        </div>
      </md-card-header>
      <md-card-content>
        <p>
          <strong>Roles</strong>
        </p>
        <p>
          The FMO ESG Toolkit is developed in partnership between the Dutch Entrepreneurial Development Bank FMO and Steward Redqueen.  
        </p>
        <p>
          FMO (located Anna van Saksenlaan 71, 2593 HW, ‘s-Gravenhage) is registered in the Netherlands under company number 27078545.  
        </p>
        <p>
          Steward Redqueen (located Kinderhuissingel 4A/B, 2013 AS, Haarlem) is registered in the Netherlands under registration number 27170153 and is responsible as data controller for the processing of your information. 
        </p>
        <p>
          User means the individual accessing the Toolkit, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. 
        </p>
        <p>
          <strong>Errors and Omissions </strong>
        </p>
        <p>
          The data and information in the Toolkit have been compiled with the greatest of care. Nevertheless, we provide no guarantee of its correctness, completeness, or reliability, nor any guarantee that it is up-to-date, and it accepts no liability for losses which may arise from the use of this information. 
        </p>
        <p>
          <strong>Liability </strong>
        </p>
        <p>
          We will not be liable to User or anyone else for any decision made or action taken in reliance on the information given by the Toolkit or for any consequential, special or similar damages, even if advised of the possibility of such damages. 
        </p>
        <p>
          <strong>Duration</strong>
        </p>
        <p>
          The access to FMO ESG Toolkit is of unlimited duration. It may be terminated by either Party at any time with immediate effect and without need to give reasons. We shall be entitled to block a User's access to some or all services without giving its reasons and without advance notice. 
        </p>
        <p>
          <strong>External Links </strong>
        </p>
        <p>
          The Toolkit may contain links to external websites that are not provided or maintained by or in any way affiliated with us. We do not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites. 
        </p>
      </md-card-content>
    </md-card>
    <span class="overlay"></span>
  </main>
</template>

<script>
export default {
  name: "terms",
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background:rgba(0,0,0,0.4);

  .overlay {
    background: url(/images/20151006_081.jpg) center;
    background-size: cover;
    opacity: 0.4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .md-card {
    max-width: 800px;
    width: 100%;
    margin: 0 15px;
    position: relative;
    z-index: 4;
  }
  .md-title {
    text-align: center;
  }
  .back {
    position: absolute;
    left: 16px;
    top: 26px;
  }
  p {
    text-align: left;
  }
}
</style>
