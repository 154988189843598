<template>
  <div>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import router from "@/router";

export default {
  name: "activate",
  data() {
    return {
      message: "",
      showSnackbar: false,
    }
  },
  mounted() {
    this.$store.commit("toggleLoading")
    axios
      .get(`${window.api}/user/activate/${this.$route.params.code}`)
      .then(() => {
        this.message = "Your account has been activated, you can log in now";
        this.showSnackbar = true;
        setTimeout(() => {
          router.push({ name: "home" });
        }, 3000)
      })
      .catch(() => {
        this.message = "User not found.";
        this.showSnackbar = true;
        setTimeout(() => {
          router.push({ name: "home" });
        }, 3000)
      })
  }
};
</script>

<style lang="scss" scoped>
</style>
