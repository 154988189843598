<template>
  <md-card>
    <md-card-content v-if="item.risk">
      <strong>E&S risk category</strong>
      <span :class="item.risk.toLowerCase()">{{ item.risk }}</span>
      <span v-if="item.risk.toLowerCase() === 'excluded'">
        <a href="https://www.fmo-im.nl/en/exclusion-list" target="_blank">FMO exclusion list</a>
      </span>
      <hr />
      <strong>Business Integrity Risk Category</strong>
      <span :class="bi.toLowerCase()">{{ bi }}</span>
      <span v-show="item.potential_exposure === 1">
        <hr />
        <strong>Potential exclusion list exposure</strong>
        <a href="https://www.fmo-im.nl/en/exclusion-list" target="_blank">FMO exclusion list</a>
      </span>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardRisk",
  props: ["item", "data"],
  data() {
    return {
      subsectorData: {},
      countryTi: {},
      bi: '',
    }
  },
  watch: {
    data() {
      this.getData();
    },
    item() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.data.sectors.map((sector) => {
        if (this.item.sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (this.item.subsector_id === subsector.id)
              this.subsectorData = subsector;
          });
        }
      });
      this.data.countries.map((country) => {
        if (this.item.country_id === country.id) {
          this.countryTi = parseInt(country.ti['TI Score']);
        }
      });
      this.bi = '';
      if (this.countryTi < 20) {
        this.bi = 'High'
      } else if (this.countryTi >= 20 && this.countryTi < 70) {
        this.bi = 'Medium'
      } else {
        this.bi = 'Low'
      }
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
strong {
  display: block;
  font-size: 20px;
  margin: 16px 0;
}
.md-card-content {
  padding: 16px 0;
}
span {
  text-transform: capitalize;
  font-size: 16px;

  a {
    display: block;
    margin-top: 10px;
  }
  &.low {
    color: #869c66
  }
  &.medium {
    color: #ff9502;
  }
  &.high {
    color: #d43501;
  }
}
</style>