<template>
  <div class="main">
    <div class="title">
      <span class="text" @click="Edit('title', item.title)" v-html="item.title"></span>
      <md-tooltip md-direction="bottom">Click here to edit the title</md-tooltip>
    </div>
    <div class="buttons">
      <Option :item="option" v-for="(option, index) in item.options" v-bind:key="index"/>
    </div>
    <md-dialog :md-active.sync="edit">
      <md-dialog-title>
        <span>Editing</span>
      </md-dialog-title>
      <md-dialog-content>
        <ckeditor v-if="editor" id="edit" :editor="editor" v-model="value"></ckeditor>
        <md-button @click="edit = false">Close</md-button>
        <md-button @click="Save">Save</md-button>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import Option from './Option';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "QuestionQuick",
  props: ['item'],
  components: {
    Option
  },
  data() {
    return {
      edit: false,
      editor: null,
      field: null,
      value: "",
    };
  },
  methods: {
    Edit(field, text) {
      this.field = field;
      this.value = text;
      this.edit = true;
    },
    Save() {
      this.item[this.field] = this.value;
      this.edit = false;
    }
  },
  mounted() {
    this.editor = ClassicEditor;
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  .title {
    min-width: 20%;
    text-align: left;
    padding:0 10px;
    .text { 
      background: rgba(0, 0, 0, 0.02);
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    .button {
      width: 100%;
      margin: 0 10px;
    }
  }
}
</style>
