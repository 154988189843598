<template>
  <main>
    <div class="utils">
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
    </div>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Country Data</h1>
        </div>

        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search by country..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No countries with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="Country" md-sort-by="name">{{item.name}}</md-table-cell>	
        <md-table-cell md-label="EPI_regions">{{item.data['EPI_regions']}}</md-table-cell>
        <md-table-cell md-label="GEO_subregion">{{item.data['GEO_subregion']}}</md-table-cell>
        <md-table-cell md-label="GDPCAP07">{{item.data['GDPCAP07']}}</md-table-cell>
        <md-table-cell md-label="Population07">{{item.data['Population07']}}</md-table-cell>
        <md-table-cell md-label="Landarea">{{item.data['Landarea']}}</md-table-cell>
        <md-table-cell md-label="PopulationDensity">{{item.data['PopulationDensity']}}</md-table-cell>
        <md-table-cell md-label="Landlock">{{item.data['Landlock']}}</md-table-cell>
        <md-table-cell md-label="No_surface_water">{{item.data['No_surface_water']}}</md-table-cell>
        <md-table-cell md-label="Desert">{{item.data['Desert']}}</md-table-cell>
        <md-table-cell md-label="High_Population_Density">{{item.data['High_Population_Density']}}</md-table-cell>
        <md-table-cell md-label="EPI">{{item.data['EPI']}}</md-table-cell>
        <md-table-cell md-label="ENVHEALTH">{{item.data['ENVHEALTH']}}</md-table-cell>
        <md-table-cell md-label="ECOSYSTEM">{{item.data['ECOSYSTEM']}}</md-table-cell>
        <md-table-cell md-label="DALY">{{item.data['DALY']}}</md-table-cell>
        <md-table-cell md-label="AIR_H">{{item.data['AIR_H']}}</md-table-cell>
        <md-table-cell md-label="WATER_H">{{item.data['WATER_H']}}</md-table-cell>
        <md-table-cell md-label="AIR_E">{{item.data['AIR_E']}}</md-table-cell>
        <md-table-cell md-label="WATER_E">{{item.data['WATER_E']}}</md-table-cell>
        <md-table-cell md-label="BIODIVERSITY">{{item.data['BIODIVERSITY']}}</md-table-cell>
        <md-table-cell md-label="FORESTRY">{{item.data['FORESTRY']}}</md-table-cell>
        <md-table-cell md-label="FISHERIES">{{item.data['FISHERIES']}}</md-table-cell>
        <md-table-cell md-label="AGRICULTURE">{{item.data['AGRICULTURE']}}</md-table-cell>
        <md-table-cell md-label="CLIMATE">{{item.data['CLIMATE']}}</md-table-cell>
        <md-table-cell md-label="DALY_pt">{{item.data['DALY_pt']}}</md-table-cell>
        <md-table-cell md-label="ACSAT_pt">{{item.data['ACSAT_pt']}}</md-table-cell>
        <md-table-cell md-label="ACSAT_pt_imp">{{item.data['ACSAT_pt_imp']}}</md-table-cell>
        <md-table-cell md-label="WATSUP_pt">{{item.data['WATSUP_pt']}}</md-table-cell>
        <md-table-cell md-label="WATSUP_pt_imp">{{item.data['WATSUP_pt_imp']}}</md-table-cell>
        <md-table-cell md-label="INDOOR_pt">{{item.data['INDOOR_pt']}}</md-table-cell>
        <md-table-cell md-label="PM10_pt">{{item.data['PM10_pt']}}</md-table-cell>
        <md-table-cell md-label="SO2_pt">{{item.data['SO2_pt']}}</md-table-cell>
        <md-table-cell md-label="NOX_pt">{{item.data['NOX_pt']}}</md-table-cell>
        <md-table-cell md-label="NMVOC_pt">{{item.data['NMVOC_pt']}}</md-table-cell>
        <md-table-cell md-label="OZONE_pt">{{item.data['OZONE_pt']}}</md-table-cell>
        <md-table-cell md-label="WQI_pt">{{item.data['WQI_pt']}}</md-table-cell>
        <md-table-cell md-label="WQI_pt_imp">{{item.data['WQI_pt_imp']}}</md-table-cell>
        <md-table-cell md-label="WQI_pt_GEMS station data">{{item.data['WQI_pt_GEMS station data']}}</md-table-cell>
        <md-table-cell md-label="WSI_pt">{{item.data['WSI_pt']}}</md-table-cell>
        <md-table-cell md-label="WATSTR_pt">{{item.data['WATSTR_pt']}}</md-table-cell>
        <md-table-cell md-label="PACOV_pt">{{item.data['PACOV_pt']}}</md-table-cell>
        <md-table-cell md-label="MPAEEZ_pt">{{item.data['MPAEEZ_pt']}}</md-table-cell>
        <md-table-cell md-label="AZE_pt">{{item.data['AZE_pt']}}</md-table-cell>
        <md-table-cell md-label="FORGRO_pt">{{item.data['FORGRO_pt']}}</md-table-cell>
        <md-table-cell md-label="FORCOV_pt">{{item.data['FORCOV_pt']}}</md-table-cell>
        <md-table-cell md-label="MTI_pt">{{item.data['MTI_pt']}}</md-table-cell>
        <md-table-cell md-label="EEZTD_pt">{{item.data['EEZTD_pt']}}</md-table-cell>
        <md-table-cell md-label="AGWAT_pt">{{item.data['AGWAT_pt']}}</md-table-cell>
        <md-table-cell md-label="AGSUB_pt">{{item.data['AGSUB_pt']}}</md-table-cell>
        <md-table-cell md-label="AGPEST_pt">{{item.data['AGPEST_pt']}}</md-table-cell>
        <md-table-cell md-label="GHGCAP_pt">{{item.data['GHGCAP_pt']}}</md-table-cell>
        <md-table-cell md-label="GHGCAP_pt_imp">{{item.data['GHGCAP_pt_imp']}}</md-table-cell>
        <md-table-cell md-label="GHGIND_pt">{{item.data['GHGIND_pt']}}</md-table-cell>
        <md-table-cell md-label="CO2KWH_pt">{{item.data['CO2KWH_pt']}}</md-table-cell>
        <md-table-cell md-label="CO2KWH_pt_imp">{{item.data['CO2KWH_pt_imp']}}</md-table-cell>
        <md-table-cell md-label="DALY">{{item.data['DALY']}}</md-table-cell>
        <md-table-cell md-label="INDOOR">{{item.data['INDOOR']}}</md-table-cell>
        <md-table-cell md-label="PM10">{{item.data['PM10']}}</md-table-cell>
        <md-table-cell md-label="OZONE_H">{{item.data['OZONE_H']}}</md-table-cell>
        <md-table-cell md-label="SO2">{{item.data['SO2']}}</md-table-cell>
        <md-table-cell md-label="OZONE_E">{{item.data['OZONE_E']}}</md-table-cell>
        <md-table-cell md-label="WATQI">{{item.data['WATQI']}}</md-table-cell>
        <md-table-cell md-label="WATQI_GEMS station data">{{item.data['WATQI_GEMS station data']}}</md-table-cell>
        <md-table-cell md-label="WATSTR">{{item.data['WATSTR']}}</md-table-cell>
        <md-table-cell md-label="FORGRO">{{item.data['FORGRO']}}</md-table-cell>
        <md-table-cell md-label="CRI">{{item.data['CRI']}}</md-table-cell>
        <md-table-cell md-label="EFFCON">{{item.data['EFFCON']}}</md-table-cell>
        <md-table-cell md-label="AZE">{{item.data['AZE']}}</md-table-cell>
        <md-table-cell md-label="MPAEEZ">{{item.data['MPAEEZ']}}</md-table-cell>
        <md-table-cell md-label="MTI">{{item.data['MTI']}}</md-table-cell>
        <md-table-cell md-label="EEZTD">{{item.data['EEZTD']}}</md-table-cell>
        <md-table-cell md-label="IRRSTR">{{item.data['IRRSTR']}}</md-table-cell>
        <md-table-cell md-label="AGINT">{{item.data['AGINT']}}</md-table-cell>
        <md-table-cell md-label="AGSUB">{{item.data['AGSUB']}}</md-table-cell>
        <md-table-cell md-label="BURNED">{{item.data['BURNED']}}</md-table-cell>
        <md-table-cell md-label="PEST">{{item.data['PEST']}}</md-table-cell>
        <md-table-cell md-label="GHGCAP">{{item.data['GHGCAP']}}</md-table-cell>
        <md-table-cell md-label="CO2IND">{{item.data['CO2IND']}}</md-table-cell>
        <md-table-cell md-label="CO2KWH">{{item.data['CO2KWH']}}</md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/country/data/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}data/countries/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
