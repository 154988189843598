<template>
  <div>
    <h2>ESG Toolkit</h2>
    <form novalidate class="md-layout" @submit.prevent="validateForm">
      <md-field :class="getValidationClass('password')">
        <label for="password">Password</label>
        <md-input
          type="password"
          name="password"
          id="password"
          v-model="form.password"
          :disabled="sending"
        />
        <span class="md-error" v-if="!$v.form.password.required"
          >The password is required</span
        >
      </md-field>

      <md-field :class="getValidationClass('confirmPassword')">
        <label for="password">Confirm password</label>
        <md-input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          v-model="form.confirmPassword"
          :disabled="sending"
        />
        <span class="md-error" v-if="!$v.form.confirmPassword.sameAsPassword">Passwords must be identical.</span>
      </md-field>
      <md-button type="submit">Create new password</md-button>
    </form>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false"
        >Retry</md-button
      >
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import router from "../router";

export default {
  name: "PasswordRecoveryForm",
  mixins: [validationMixin],
  data: () => ({
    form: {
      password: null,
      confirmPassword: null,
    },
    userSaved: false,
    sending: false,
    lastUser: null,
    message: "",
    showSnackbar: false,
  }),
  validations: {
    form: {
      password: {
        required,
      },
      confirmPassword: {
        sameAsPassword: sameAs('password')
      }
    },
  },
  mounted() {
    this.$store.commit("toggleLoading")
    axios
      .get(`${window.api}user/${this.$route.params.code}/check`)
      .then(() => {
        this.$store.commit("toggleLoading")
      })
      .catch(() => {
        this.$store.commit("toggleLoading")
        router.push({ name: "home" });
      })
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.newPassword()
      }
    },
    newPassword() {
      this.$store.commit("toggleLoading")
      axios
        .post(`${window.api}user/${this.$route.params.code}/update-password/`, {
          pwd: this.form.password,
        })
        .then(response => {
          this.$store.commit("toggleLoading")
          if (response.data.status) {
            this.$v.$reset()
            this.form.password = null
            this.form.confirmPassword = null
            this.message = "Password updated";
            this.showSnackbar = true;
            setTimeout(() => {
              router.push({ name: "home" });
            }, 3000)
          } else {
            this.message = "Your link has been expired, you can try again with a new link.";
            this.showSnackbar = true;
            setTimeout(() => {
              router.push({ name: "home" });
            }, 3000)
          }
        })
        .catch(() => {
          this.$store.commit("toggleLoading")
          this.message = "User not found";
          this.showSnackbar = true;       
          setTimeout(() => {
            router.push({ name: "home" });
          }, 3000)   
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#form-holder {
  max-width: 400px;
  width: 90%;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.logo-steward {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
}
</style>
