<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Geographical focus</div>
    </md-card-header>
    <md-card-content>
      <div  v-if="chartData[0]">
        <vue-plotly :autoResize="true" :options="options" :data="chartData" :layout="layout" />
      </div>
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.md-card-content {
  padding: 0;
}
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
}
</style>

<script>
import VuePlotly from '@statnett/vue-plotly'
import codes from "../../assets/iso3.json";
import Countries from '../../assets/countries.json';

export default {
  name: "CardMap",
  props: ['investments'],
  components: {
    VuePlotly
  },
  data() {
    return {
      options: {
        autosizable: true,
        responsive: true
      },
      count: {},
      locations: [],
      values: [],
      chartData: [],
      data: {
        type: "choroplethmapbox",
        geojson: "https://raw.githubusercontent.com/python-visualization/folium/master/examples/data/world-countries.json",
        colorbar: {
          thickness: 0
        },
        colorscale: [
            [0, 'rgb(117,107,177)'], [1, 'rgb(84,39,143)']
        ],
        showscale: false,
        marker: {
          opacity: 0.4,
          line:{
            width: 0
          }
        }
      },
      layout: {
        mapbox: {
          style: "carto-positron", 
          center: {
            lon: 10, 
            lat: 15
          }, 
          zoom: 0.7
        },
        margin: {"t": 0, "b": 0, "l": 0, "r": 0},
        height: 340,
      },
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      if (!this.investments)
        return false;
      this.count = this.chartData = []
      const locations = [];
      this.investments.map((item) => {
        const country = Countries.filter((country) => country.name === item.country);
        item.country_code = !country ? 'bb' : country[0].code;
        if (!this.count[item.country_code]) {
          this.count[item.country_code] = 0;
          locations.push(codes[item.country_code.toUpperCase()]);
        }
        this.count[item.country_code] += 1;
        if (item.countries_subsidiaries_code.length > 0) {
          item.countries_subsidiaries_code.map((subsidiary) => {
            if (!this.count[subsidiary.code]) {
              this.count[subsidiary.code] = 0;
              locations.push(codes[subsidiary.code.toUpperCase()]);
            }
            this.count[subsidiary.code] += 1;
          })
        }
      });
      const values = [];
      for (const key in this.count) {
        values.push(this.count[key]);
      }
      const chart = this.data;
      chart.locations = locations;
      chart.z = values;
      this.chartData.push(chart);
    }
  },
  watch: {
    investments() {
      this.setData();
    }
  }
}
</script>
