<template>
  <main id="home">
    <span class="overlay"></span>
    <aside>
      <img src="/images/fmo+50+blue-okerlarge.png" />
      <ForgotPasswordForm />
      <img src="@/assets/logo-steward-redqueen.png" />
    </aside>
  </main>
</template>

<script>
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";

export default {
  name: "home",
  components: {
    ForgotPasswordForm,
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
