<template>
  <div>
    <p v-if="feedback.type === 1">
      Idea
    </p>
    <p v-if="feedback.type === 2">
      Bug
    </p>
    <h2>{{feedback.title}}</h2>
    <p>
      {{feedback.text}}
    </p>
    <p>
      Name: {{feedback.name}} &lt;{{feedback.email}}&gt;
    </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "View",
  data() {
    return {
      feedback: {},
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.$store.commit('toggleLoading')
    if (this.$route.params.id) {
      axios
        .get(`${window.api}feedbacks/${this.$route.params.id}/get/?jwt=${this.user.token}`)
        .then(response => {
          this.feedback = response.data.feedback
          this.$store.commit('toggleLoading')
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.back {
  float: left;
}
.save {
  float: right;
}
</style>
