<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Library</div>
      <div class="utils">
        <md-icon>
          info
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: Library']"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="line">
        <div>
          <a href="http://www.ifc.org/wps/wcm/connect/Topics_Ext_Content/IFC_External_Corporate_Site/Sustainability-At-IFC/Policies-Standards/Performance-Standards" target="_blank">
            <i>
              <img src="/images/ifc-logo-5.png"/>
            </i>
            <span>IFC Performance Standards</span>
          </a>
        </div>
        <div>
          <a href="https://data.worldbank.org/indicator" target="_blank">
            <i>
              <img src="/images/logo-wb-header-en.svg"/>
            </i>
            <span>World Bank Country Indicators</span>
          </a>
        </div>
      </div>
      <div class="line">
        <div>
          <a href="http://www.oecd.org/corporate/mne/" target="_blank">
            <i>
              <img src="/images/images.jpg"/>
            </i>
            <span>OECD Guidelines</span>
          </a>
        </div>
        <div>
          <a href="http://toolkit.cdcgroup.com/" target="_blank">
            <i>
              <img src="/images/cdc-group-squarelogo-1392052466661.png" class="cdc"/>
            </i>
            <span>CDC Toolkit</span>
          </a>
        </div>
      </div>
      <div class="line">
        <div>
          <a href="https://www.ifc.org/wps/wcm/connect/topics_ext_content/ifc_external_corporate_site/ifc+cg/investment+services/corporate+governance+tools" target="_blank">
            <i>
              <img src="/images/ifc-logo-5.png"/>
            </i>
            <span>Corporate Governance Tools</span>
          </a>
        </div>
        <div>
          <a href="http://cgdevelopmentframework.com/cg-development-framework/" target="_blank">
            <i>
              <img src="/images/cgdf-logo.png"/>
            </i>
            <span>CG Development Framework</span>
          </a>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardLibrary",
  data() {
    return {
      data: this.$store.state.data
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card-header {
  display: flex;
  justify-content: space-between;
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  .utils {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
  }
}
.md-card-content {
  padding-top: 20px;
  .line {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    div {
      width: 50%;
      a {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        i {
          height: 60px;
          display: flex;
          align-items: center;
          img {
            filter: grayscale(1);
            max-width: 120px;
            height: 24px;
            &.cdc {
              height: 18px;
            }
          }
        }
        span {
          width: 100%;
        }
      }
    }
  }
}
</style>
