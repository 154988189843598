<template>
  <main>
    <UpdateName />
    <UpdatePassword />
  </main>
</template>

<script>
import UpdateName from "./UpdateName";
import UpdatePassword from "./UpdatePassword";

export default {
  name: "Form",
  components: {
    UpdateName,
    UpdatePassword,
  },
}
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    @media(max-width: 789px) {
      flex-wrap: wrap;
    }
  }
</style>
