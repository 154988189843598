import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Countries from '../assets/countries.json';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reviewStep: 0,
    isLoading: false,
    admin: {
      users: []
    },
    user: {},
    data: {},
    investment: {},
    fund: {},
    funds: [],
    funds_archived: [],
    expandStatus: false,
    review: {
      higher: [],
      lower: [],
    },
    content: {},
    currency: {},
    bota: {},
    naCount: 0,
    reviewCount: {
      higher: {
        total: 0,
        esms: 0,
        corporate: 0,
        bi: 0,
      },
      lower: {
        total: 0,
        esms: 0,
        corporate: 0,
        bi: 0,
      },
    },
    preview: {
      status: false,
      review: {
        higher: [],
        lower: [],
      }
    },
    subsectorReviews: [],
    thresholdNA: {
      esms: [],
      corporate: [],
      bi: [],
    },
    thresholdNAStatus: false,
    reviewSection: {
      higher: 0,
      lower: 0,
    },
    filterHome: {
      sector: 'All',
      risk: 'All',
    }
  },
  mutations: {
    editContent(state, content) {
      state.content = content;
    },
    toggleExpandStatus(state, value) {
      state.expandStatus = value;
    },
    toggleFilter(state, value) {
      state.filterHome = value;
    },
    reviewPrevious(state) {
      state.reviewStep--;
    },
    reviewNext(state) {
      state.reviewStep++;
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    disabledLoading(state) {
      state.isLoading = false;
    },
    toogleAdminData(state, value) {
      state.admin = value;
    },
    toggleLoggedUser(state, value) {
      state.user = value
      window.localStorage.setItem("esg_toolkitv0.4", btoa(JSON.stringify(value)));
      this.commit("loadData");
    },
    updateToken(state, value) {
      state.user.token = value
      window.localStorage.setItem("esg_toolkitv0.4", btoa(JSON.stringify(state.user)));
    },
    loadData(state) {
      state.isLoading = true;
      if (!state.user.id) {
        state.user = JSON.parse(atob(window.localStorage.getItem('esg_toolkitv0.4')))
      }
      if (state.data.countries) {
        this.commit("loadFunds");
        return false;
      }
      axios
        .get(`//currency.nearfuturelab.com.br/eur/usd/`)
        .then(response => {
          this.commit("loadCurrency", {key: 'eurToUsd', value: response.data});
        });
      axios
        .get(`//currency.nearfuturelab.com.br/usd/eur/`)
        .then(response => {
          this.commit("loadCurrency", {key: 'usdToEur', value: response.data});
        });
      axios
        .get(`${window.api}data/load/?jwt=${state.user.token}`)
        .then(response => {
          this.commit("toggleData", response.data);
          this.commit("loadFunds");
        });
    },
    loadCurrency(state, data) {
      state.currency[data.key] = data.value;
    },
    toggleData(state, value) {
      state.data = value;
    },
    restoreFund(state, value) {
      state.isLoading = true;
      axios
        .get(`${window.api}funds/${state.user.id}/recover/${value.id}/?jwt=${state.user.token}`)
        .then(() => {
          this.commit('loadFunds')
        })
    },
    loadFunds(state) {
      if (!state.user.id) {
        state.isLoading = false;
        return false;
      }
      state.isLoading = true;
      axios
        .get(`${window.api}funds/${state.user.id}/get/?jwt=${state.user.token}`)
        .then(response => {
          this.commit("toggleFunds", response.data);
        });
    },
    toggleFunds(state, data) {
      state.funds = data.funds;
      state.funds_archived = data.archived;
      state.isLoading = false;
      if (!state.fund.name)
        this.commit("selectFund", state.funds[0]);
      else {
        const fund = state.funds.filter(item => item.id === state.fund.id)[0]
        this.commit("selectFund", fund);
      }
    },
    deleteInvestment(state, value) {
      state.isLoading = true;
      axios
        .get(`${window.api}funds/${state.fund.id}/investments/${state.user.id}/delete/${value.id}/?jwt=${state.user.token}`)
        .then(() => {
          this.commit("loadData");
        });
    },
    selectFund(state, value) {
      if (value && value.investments)
        value.investments.map((item) => {
          item.reviews.map((review) => {
            const reviewValues = JSON.parse(review.values);
            review.reviewScore = [];
            let values = undefined;
            if (review.done_quick === 1){
              values = reviewValues.higher;
              if(values.length == 0)values = reviewValues.lower
            }
            if (review.done_indeepth === 1){
              values = reviewValues.lower;
              if(values.length == 0)values = reviewValues.higher
            }
            if (values) {
              values.map((topic) => {
                if (!review.reviewScore[topic.level])
                  review.reviewScore[topic.level] = {}
                if (!review.reviewScore[topic.level][topic.section]) {
                  review.reviewScore[topic.level][topic.section] = {}
                  review.reviewScore[topic.level][topic.section].value = 0
                  review.reviewScore[topic.level][topic.section].count = 0
                }
                if (topic.value < 4) {
                  review.reviewScore[topic.level][topic.section].value += parseInt(topic.value);
                  review.reviewScore[topic.level][topic.section].count += 1;
                }
              })
              let total = 0
              for (const level in review.reviewScore) {
                let valuesSection = 0
                let countSection = 0
                for (const section in review.reviewScore[level]) {
                  review.reviewScore[level][section].total = (review.reviewScore[level][section].value / (review.reviewScore[level][section].count * 3)) * 100
                  valuesSection += review.reviewScore[level][section].value;
                  countSection += review.reviewScore[level][section].count * 3
                }
                review.reviewScore[level].total = (valuesSection / countSection) * 100
                total += review.reviewScore[level].total
              }
              review.reviewScore.total = total / 3
            }
          });
          const country = Countries.filter((country) => country.name === item.country);
          item.country_code = !country || !country[0] ? 'bb' : country[0].code;
          const countries_subsidiaries = JSON.parse(item.countries_subsidiaries);
          item.countries_subsidiaries_code = []
          if (countries_subsidiaries && countries_subsidiaries.length > 0) {
            countries_subsidiaries.map((subsidiary) => {
              item.countries_subsidiaries_code.push(Countries.filter((country) => country.name === subsidiary.text)[0]);
            })
          }
          item.reviewScore = []
          const latestDoneQuickReview = item.reviews.reverse().filter(item => item.done_quick === 1 && item.type === 1)[0]
          if (latestDoneQuickReview)
            item.reviewScore = latestDoneQuickReview.reviewScore;
          const latestIndeepthQuickReview = item.reviews.reverse().filter(item => item.done_indeepth === 1 && item.type === 2)[0]
          if (latestIndeepthQuickReview)
            item.reviewScore = latestIndeepthQuickReview.reviewScore;
        });
      state.fund = value;
      if (state.investment.id) {
        state.fund.investments.map((item) => {
          if (item.id === state.investment.id) {
            state.investment = item
            return;
          }
        })
      }
    },
    startReview(state) {
      state.review = {
        higher: [],
        lower: [],
      };
      state.reviewCount = {
        higher: {
          total: 0,
          esms: 0,
          corporate: 0,
          bi: 0,
        },
        lower: {
          total: 0,
          esms: 0,
          corporate: 0,
          bi: 0,
        },
      }
      state.reviewSection = {
        higher: 0,
        lower: 0,
      }
    },
    startFirstReview(state) {
      state.reviewSection = {
        higher: 0,
        lower: 0,
      }
    },
    updateInvestmentStage(state, investment_stage_id) {
      axios
        .post(`${window.api}funds/${state.fund.id}/investments/${state.user.id}/update/${state.investment.id}/stage/?jwt=${state.user.token}`, { investment_stage_id: investment_stage_id });
      state.investment.investment_stage_id = investment_stage_id;
    },
    selectInvestment(state, value) {
      state.investment = value;
      if (state.investment.review) {
        const review = JSON.parse(state.investment.review.values)
        state.review = review
        review.higher.map((item) => {
          state.reviewCount.higher[item.level] += 1;
        })
        state.reviewCount.higher.total = state.review.higher.length;
        review.lower.map((item) => {
          state.reviewCount.lower[item.level] += 1;
        })
        state.reviewCount.lower.total = state.review.lower.length;
      } else {
        state.review = {
          higher: [],
          lower: [],
        };
        state.reviewCount = {
          higher: {
            total: 0,
            esms: 0,
            corporate: 0,
            bi: 0,
          },
          lower: {
            total: 0,
            esms: 0,
            corporate: 0,
            bi: 0,
          },
        }
      }
    },
    answerReview(state, answer) {
      state.thresholdNAStatus = false;
      if (!state.investment || state.preview.status)
        return false;
      if (state.thresholdNA[answer.level].length < 1)
        state.thresholdNA[answer.level] = []
      if (!state.thresholdNA[answer.level][answer.section])
        state.thresholdNA[answer.level][answer.section] = 0;
      const exist = state.review[answer.type].map((item) => {
        if (!state.thresholdNA[item.level])
          state.thresholdNA[item.level] = []
        if (!state.thresholdNA[item.level][item.section])
          state.thresholdNA[item.level][item.section] = 0;
        if (item.value === 4)
          state.thresholdNA[item.level][item.section] += 1;
        if (
          item.key === answer.key &&
          state.thresholdNA[item.level][item.section] <= (item.total / 2)
        ) {
          item.value = answer.value;
          return true;
        }
      }).filter((item) => item === true)
      if (answer.value === 4) {
        state.thresholdNA[answer.level][answer.section] += 1;
        if (state.thresholdNA[answer.level][answer.section] > (answer.total / 2)) {
          state.thresholdNAStatus = true;
          return false;
        } else {
          state.thresholdNAStatus = false;
        }
      }
      if (exist.indexOf(true) < 0) {
        state.review[answer.type].push(answer);
        state.reviewCount[answer.type][answer.level] += 1;
      }
      state.reviewCount[answer.type].total = state.review[answer.type].length;
      axios
        .post(`${window.api}funds/${state.fund.id}/investments/${state.user.id}/review/${state.investment.id}/save/?jwt=${state.user.token}`, { values: state.review });
    },
    toggleSection(state, value) {
      state.reviewSection[value.type] = value.section;
    },
    saveFund(state, fund) {
      state.isLoading = true;
      let url = `${window.api}funds/${state.user.id}/create/?jwt=${state.user.token}`;
      if (fund.id) {
        url = `${window.api}funds/${state.user.id}/update/${fund.id}/?jwt=${state.user.token}`;
      }
      axios
        .post(url, { name: fund.name })
        .then(() => {
          state.isLoading = false;
          axios
            .get(`${window.api}funds/${state.user.id}/get/?jwt=${state.user.token}`)
            .then(response => {
              state.isLoading = false;
              state.funds = response.data.funds;
              state.funds_archived = response.data.archived;
              this.commit("selectFund", response.data.funds.reverse()[0]);
            });
        });
    },
    deleteFund(state, fund) {
      state.isLoading = true;
      axios
        .get(`${window.api}funds/${state.user.id}/delete/${fund.id}/?jwt=${state.user.token}`)
        .then(() => {
          state.fund = {}
          this.commit("loadFunds");
        });
    },
    reviewDone(state) {
      axios
        .get(`${window.api}funds/${state.fund.id}/investments/${state.user.id}/review/${state.investment.id}/done/?jwt=${state.user.token}`);
    },
    getInvestmentsSubsectors(state) {
      axios
        .get(`${window.api}investments/${state.investment.id}/subsector/${state.investment.subsector_id}/?jwt=${state.user.token}`)
        .then((response) => {
          this.commit("getSubsectorReviews", response.data);
        });
    },
    getSubsectorReviews(state, data) {
      state.subsectorReviews = []
      data.investments.map((item) => {
        if (!item.review)
          return false;
        const reviewValues = JSON.parse(item.review.values);
        item.reviewScore = [];
        let values = '';
        if (reviewValues.higher.length > 0)
          values = reviewValues.higher;
        if (reviewValues.lower.length > 0)
          values = reviewValues.lower;
        if (values) {
          values.map((topic) => {
            if (!item.reviewScore[topic.level])
              item.reviewScore[topic.level] = {}
            if (!item.reviewScore[topic.level][topic.section]) {
              item.reviewScore[topic.level][topic.section] = {}
              item.reviewScore[topic.level][topic.section].value = 0
              item.reviewScore[topic.level][topic.section].count = 0
            }
            if (topic.value < 4) {
              item.reviewScore[topic.level][topic.section].value += parseInt(topic.value);
              item.reviewScore[topic.level][topic.section].count += 1;
            }
          });
          let valuesLevel = 0
          let countLevel = 0
          for (const level in item.reviewScore) {
            let valuesSection = 0
            let countSection = 0
            for (const section in item.reviewScore[level]) {
              item.reviewScore[level][section].total = (item.reviewScore[level][section].value / (item.reviewScore[level][section].count * 3)) * 100
              valuesSection += item.reviewScore[level][section].value;
              countSection += item.reviewScore[level][section].count * 3
            }
            valuesLevel += valuesSection;
            countLevel += countSection;
            item.reviewScore[level].total = (valuesSection / countSection) * 100
          }
          item.reviewScore.total = (valuesLevel / countLevel) * 100
          state.subsectorReviews.push(item);
        }
      });
    },
    removeReview(state, id) {
      state.isLoading = true;
      axios
        .get(`${window.api}funds/${state.investment.fund_id}/investments/${state.investment.user_id}/review/${id}/remove/?jwt=${state.user.token}`)
        .then(() => {
          this.commit("loadFunds");
        });
    },
    toggleReviewType(state, type) {
      axios
        .post(
          `${window.api}funds/${state.investment.fund_id}/investments/${state.investment.user_id}/review/${state.investment.id}/type/?jwt=${state.user.token}`,
          {type: type}
        );
    },
    toggleFundCurrency(state, value) {
      axios
        .post(
          `${window.api}funds/${state.user.id}/update/${state.fund.id}/currency/?jwt=${state.user.token}`,
          {currency: value}
        )
        .then(() => {
          this.commit("loadFunds");
        });
    },
    togglePreview(state, review) {
      if (review) {
        state.preview.status = true;
        state.preview.review = review;
      } else {
        state.preview.status = false;
      }
    }
  },
});
