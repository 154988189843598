<template>
  <main id="app-holder" class="faq">
    <Bar />
    <md-card class="header" v-if="title.title">
      <md-card-header>
        <div class="md-title">
          {{title.title[0].text}}
        </div>
      </md-card-header>
      <md-card-content>
        <prismic-rich-text :field="title.text" class="description"/>
        <div class="md-layout">
          <div class="md-layout-item">
            <a href="/files/Manual-FMO-ESG-toolkit.pdf" target="_blank">
              <img src="/icon-manual.png">
              <p>
                Download the Toolkit manual
              </p>
            </a>
          </div>
          <div class="md-layout-item">
            <a href="/files/Toolkit-Guide-FInal.pdf" target="_blank">
              <img src="/icon-guide.png">
              <p>
                Download FMO's Toolkit Guide
              </p>
            </a>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <div class="content">
      <aside>
        <md-card>
          <md-card-content>
            <ul>
              <li v-for="(item, index) in subjects" :key="index">
                <md-button :class="active === item.id ? 'active' : ''" @click="toggleSubject(item.id)">{{ item.data.title[0].text }}</md-button>
              </li>
            </ul>
          </md-card-content>
        </md-card>
      </aside>
      <article v-if="done">
        <div v-for="(topic, indexT) in topics" :key="indexT">
          <h2 class="md-title">{{topic.data.name[0].text}}</h2>

          <md-card v-for="(question, indexQ) in topic.questions" :key="indexQ">
            <md-card-header>
              <md-button @click="toggleQuestion(indexT, indexQ)">
                <div class="md-title">
                  <div>{{question.data.title[0].text}}</div>
                  <md-icon v-show="question.status">add</md-icon>
                  <md-icon v-show="!question.status">remove</md-icon>
                </div>
              </md-button>

            </md-card-header>

            <md-card-content v-show="question.status">
              <prismic-rich-text :field="question.data.content" class="description"/>
            </md-card-content>
          </md-card>
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import Bar from './../components/commom/Bar';

export default {
  components: {
    Bar,
  },
  name: "FAQ",
  data() {
    return {
      active: '',
      subjects: [],
      done: false,
      topics: [],
      title: {},
      questions: []
    }
  },
  mounted() {
    this.$prismic.client.query(
      this.$prismic.Predicates.at('document.type', 'faq_title_-_esg_fmo'),
    ).then((document) => {
      if (document) {
        this.title = document.results[0].data
      }
    })

    this.$prismic.client.query(
      this.$prismic.Predicates.at('document.type', 'subjects_faq_-_esg'),
      {
        orderings: '[my.subjects_faq_-_esg.order]'
      }
    ).then((document) => {
      if (document) {
        this.subjects = document.results;
        this.active = this.subjects[0].id;
        this.getTopics(this.active);
      }
    })
  },
  methods: {
    getTopics(uid) {
      this.$prismic.client.query(
        this.$prismic.Predicates.at('my.faq_-_topics_-_esg_fmo.subject', uid),
      ).then((document) => {
        if (document) {
          this.topics = document.results
          this.getQuestions()
        }
      })
    },
    getQuestions() {
      this.done = false;
      let count = 0;
      this.topics.map(t => {
        this.$prismic.client.query(
          this.$prismic.Predicates.at('my.questions_-_faq_esg.subject', t.id),
        ).then((document) => {
          document.results.map(q => q.status = false)
          t.questions = document.results;
          count++;
          if (count === this.topics.length) {
            this.done = true;
          }
        })
      })
    },
    toggleSubject(uid) {
      this.questions = []
      this.active = uid
      this.getTopics(uid);
    },
    toggleQuestion(topic, question) {
      this.done = false;
      if (this.topics[topic].questions[question].status)
        this.topics[topic].questions[question].status = false;
      else {
        this.topics.map(t => t.questions.map(q => q.status = false));
        this.topics[topic].questions[question].status = true;
      }
      this.done = true;
    }
  }
}
</script>

<style lang="scss">
.faq {
  .md-layout-item {
    a {
      text-align: center;
      img {
        width: 30px;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .md-card-header .md-button:last-child {
    margin-right: 0;
  }
  .header {
    margin: 15px;

    .md-card-content {
      justify-content: flex-start;
      text-align: left;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 120px;

    @media (min-width: 789px) {
      flex-wrap: nowrap;
    }

    aside {
      margin: 0 15px;
      width: 100%;

      @media (min-width: 789px) {
        min-width: 300px;
        max-width: 300px;
      }

      .md-card-content {
        padding: 0;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: block;

            button {
              width: 100%;
              margin: 0;
              height: auto;
              white-space: initial;
              padding: 16px 10px;
              text-transform: initial;

              &.active {
                background: rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }

    article {
      width: 100%;
      margin: 15px 15px 0;

      @media (min-width: 789px) {
        margin: 0 15px 0 0;
      }

      h2 {
        margin: 0 0 20px;
        line-height: initial;
      }

      .md-card {
        margin-bottom: 15px;

        .md-card-header {
          padding: 0;

          button {
            width: 100%;
            height: auto;
            white-space: initial;
            text-transform: initial;

            .md-button-content {
              text-transform: initial !important;
              display: block;
              width: 100%;
              padding: 16px;

              .md-title {
                display: flex;
                justify-content: space-between;
                div {
                  width: 100%;
                }
              }
            }
          }
        }

        .md-card-content {
          justify-content: flex-start;
          text-align: left;
        }
      }
    }
  }
}
</style>