<template>
  <main>
    <div class="utils">
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
    </div>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Investment Type</h1>
        </div>
      </md-table-toolbar>
      <md-table-empty-state
        md-label="Nothing here yet, start importing new data with the button above">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="Investment type">{{item.name}}</md-table-cell>
        <md-table-cell md-label="E & S risk category">{{item['E & S risk category']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Relevance">{{item['PS 5 Relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Relevance">{{item['PS 7 Relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Relevance">{{item['PS 8 Relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Risk a">{{item['PS 5 Risk a']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Risk b">{{item['PS 5 Risk b']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Risk c">{{item['PS 5 Risk c']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Risk d">{{item['PS 5 Risk d']}}</md-table-cell>
        <md-table-cell md-label="PS 5 Risk e">{{item['PS 5 Risk e']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Risk a">{{item['PS 7 Risk a']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Risk b">{{item['PS 7 Risk b']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Risk c">{{item['PS 7 Risk c']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Risk d">{{item['PS 7 Risk d']}}</md-table-cell>
        <md-table-cell md-label="PS 7 Risk e">{{item['PS 7 Risk e']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Risk a">{{item['PS 8 Risk a']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Risk b">{{item['PS 8 Risk b']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Risk c">{{item['PS 8 Risk c']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Risk d">{{item['PS 8 Risk d']}}</md-table-cell>
        <md-table-cell md-label="PS 8 Risk e">{{item['PS 8 Risk e']}}</md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>


<script>
import axios from "axios";

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/investment/type/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}data/types/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
