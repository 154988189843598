<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Economic Indicators</div>
    </md-card-header>
    <md-card-content>
      <md-table>
        <md-table-row v-if="dataPop[0]">
          <md-table-cell class="left">
            <strong>Population:</strong>
          </md-table-cell>
          <md-table-cell class="flex">
            <span class="pop" v-html="parsePopulation(dataPop[0].value)"></span>
          </md-table-cell>
        </md-table-row>
        <md-table-row v-if="dataGDP[0]">
          <md-table-cell class="left">
            <strong>GDP:</strong>
          </md-table-cell>
          <md-table-cell>
            <span class="gdp" v-html="parseGDP()"></span>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table>
        <md-table-row v-if="dataEODB[0] && dataEODB[0].value">
          <md-table-cell class="left">
            World Bank EODB Index
          </md-table-cell>
          <md-table-cell>
          </md-table-cell>
          <md-table-cell>
            {{ parse(dataEODB[0].value) }}
          </md-table-cell>
          <md-table-cell>
            {{dataEODB[0].date}}
          </md-table-cell>
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: EODB Index']"></md-tooltip>
        </md-table-row>
        <md-table-row v-if="dataGINI[0] && dataGINI[0].value">
          <md-table-cell class="left">
            GINI Coefficient
          </md-table-cell>
          <md-table-cell>
          </md-table-cell>
          <md-table-cell>
            {{ parse(dataGINI[0].value) }}
          </md-table-cell>
          <md-table-cell>
            {{dataGINI[0].date}}
          </md-table-cell>
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: GINI Coefficient']"></md-tooltip>
        </md-table-row>
        <md-table-row v-if="dataHDI[0] && dataHDI[0].HDI">
          <md-table-cell class="left">
            HD Index
          </md-table-cell>
          <md-table-cell>
          </md-table-cell>
          <md-table-cell>
            {{ parse(dataHDI[0].HDI) }}
          </md-table-cell>
          <md-table-cell>
            {{ dataHDI[0].year }}
          </md-table-cell>
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: HD Index']"></md-tooltip>
        </md-table-row>
        <md-table-row v-if="country && country.ti">
          <md-table-cell class="left">
            TI Score
          </md-table-cell>
          <md-table-cell>
          </md-table-cell>
          <md-table-cell>
            {{ country.ti["TI Score"] }}
          </md-table-cell>
          <md-table-cell>
            2019
          </md-table-cell>
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: TI Score']"></md-tooltip>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.left,
p {
  text-align: left;
}
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
}
.md-card-content {
  padding: 0;
}
.gdp,
.pop {
  font-size: 22px;
  line-height: 10px;
  position: relative;
  top: 4px;
}
.flex .md-table-cell-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>

<script>
import axios from "axios";

export default {
  name: "CardEconomic",
  props: ["item"],
  data() {
    return {
      data: this.$store.state.data,
      dataPop: [],
      dataGDP: [],
      dataEODB: [],
      dataGINI: [],
      dataHDI: [],
      currency: [],
      country: {},
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    parseGDP() {
      if (!this.dataGDP)
        return false;
      const value = this.dataGDP[0].value * this.currency[0].value;
      if (value / (10 ** 12) >= 1) {
        return `€ ${parseInt(value / 10 ** 12)} Trillion`;
      }
      if (value / (10 ** 9) >= 1) {
        return `€ ${parseInt(value / 10 ** 9)} Billion`;
      }
      if (value / (10 ** 6) >= 1) {
        return `€ ${parseInt(value / 10 ** 6)} Million`;
      }
    },
    getData() {
      if (!this.item || !this.item.country_code)
        return false;
      axios
        .get(`https://api.worldbank.org/v2/country/${this.item.country_code}/indicator/SP.POP.TOTL?format=json`)
        .then(response => {
          if (!response.data[1])
            return false;
          response.data[1].map((item) => {
            if (item.value > 0) {
              this.dataPop.push(item);
              return true;
            }
          })
        });
      axios
        .get(`https://api.worldbank.org/v2/country/${this.item.country_code}/indicator/NY.GDP.MKTP.CD?format=json`)
        .then(response => {
          if (!response.data[1])
            return false;
          response.data[1].map((item) => {
            if (item.value > 0) {
              this.dataGDP.push(item);
              return true;
            }
          })
        });
      axios
        .get(`https://api.worldbank.org/v2/country/XC/indicator/PA.NUS.FCRF?format=json`)
        .then(response => {
          if (!response.data[1])
            return false;
          response.data[1].map((item) => {
            if (item.value > 0) {
              this.currency.push(item);
              return true;
            }
          })
        });
      axios
        .get(`https://api.worldbank.org/v2/country/${this.item.country_code}/indicator/IC.BUS.EASE.XQ?format=json`)
        .then(response => {
          if (!response.data[1])
            return false;
          response.data[1].map((item) => {
            if (item.value > 0) {
              this.dataEODB.push(item);
              return true;
            }
          })
        });
      axios
        .get(`https://api.worldbank.org/v2/country/${this.item.country_code}/indicator/SI.POV.GINI?format=json`)
        .then(response => {
          if (!response.data[1])
            return false;
          response.data[1].map((item) => {
            if (item.value > 0) {
              this.dataGINI.push(item);
              return true;
            }
          })
        });
      axios
        .get(`https://hdr.nearfuturelab.com.br/get/${this.item.country}/`)
        .then(response => {
          this.dataHDI.push(response.data);
        })
        .catch(() => {
          this.dataHDI = []
        });
      this.data.countries.map((item) => {
        if (item.id === this.item.country_id) {
          this.country = item;
        }
      })
    },
    parsePopulation(value) {
      if (value / (10 ** 9) >= 1)
        return `${parseInt(value / 10 ** 9)} Billion`;
      if (value / (10 ** 6) >= 1)
        return `${parseInt(value / 10 ** 6)} Million`;
      return `${parseInt(value / 10 ** 3)} Thousand`;
    },
    parse(value) {
      if (value)
        return value.toLocaleString()
    }
  },
  watch: {
    item() {
      this.getData();
    }
  }
}
</script>
