<template>
  <main>
    <HeaderColumnsLower />
    <div class="group" :class="{'active': section[0].status}">
      <div class="group-title" @click="toggleSection(0)">
        <span class="title">
          <button>
            <span v-if="!section[0].status">+</span>
            <span v-if="section[0].status">-</span>
          </button>
          Business Integrity Management System (BIMS)
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s1')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s1')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              There are policies for required and business critical areas, and they are integrated into the operation and management processes. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              There are policies for required and business critical areas, and they are integrated into the operation and management processes. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s1')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              The policies state applicable business and operational requirements, define accountability for all relevant Business Integrity areas, and are evaluated regularly. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              The policies state applicable business and operational requirements, define accountability for all relevant Business Integrity areas, and are evaluated regularly. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s1')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              There is accountability on senior management level. The company engages and communicates with external stakeholders proactively. The Board receives Business Integrity performance reports and evaluations. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              There is accountability on senior management level. The company engages and communicates with external stakeholders proactively. The Board receives Business Integrity performance reports and evaluations. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s1')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>BI Business integrity principles or policies</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('BIBusinessintegrityprinciplespolicies', 's1', 0)" :class="{'active': answers.BIBusinessintegrityprinciplespolicies === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('BIBusinessintegrityprinciplespolicies', 's1', 1)" :class="{'active': answers.BIBusinessintegrityprinciplespolicies === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Policies have been identified for required and critical areas (ex. environment, health and safety, harassment, discrimination, bribery, corruption, ethics, business and professional conduct, other). Senior management has formally/publicly committed to each individual policy.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                Policies have been identified for required and critical areas (ex. environment, health and safety, harassment, discrimination, bribery, corruption, ethics, business and professional conduct, other). Senior management has formally/publicly committed to each individual policy.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIBusinessintegrityprinciplespolicies', 's1', 2)" :class="{'active': answers.BIBusinessintegrityprinciplespolicies === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The policies state objectives, principles and applicable business and operational requirements. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                The policies state objectives, principles and applicable business and operational requirements. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIBusinessintegrityprinciplespolicies', 's1', 3)" :class="{'active': answers.BIBusinessintegrityprinciplespolicies === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The policy/ies are communicated to staff and, in some cases, also to external stakeholders.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                The policy/ies are communicated to staff and, in some cases, also to external stakeholders.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('BIBusinessintegrityprinciplespolicies', 's1', 4)" :class="{'active': answers.BIBusinessintegrityprinciplespolicies === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>BI Roles and responsibilities</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('BIRolesresponsibilities', 's1', 0)" :class="{'active': answers.BIRolesresponsibilities === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('BIRolesresponsibilities', 's1', 1)" :class="{'active': answers.BIRolesresponsibilities === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There are persons accountable for some, but not all, Business Integrity areas.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                There are persons accountable for some, but not all, Business Integrity areas.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIRolesresponsibilities', 's1', 2)" :class="{'active': answers.BIRolesresponsibilities === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There are named persons accountable for each of the identified and relevant Business Integrity areas.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                There are named persons accountable for each of the identified and relevant Business Integrity areas.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIRolesresponsibilities', 's1', 3)" :class="{'active': answers.BIRolesresponsibilities === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is accountability on senior management level for each of the policies. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                There is accountability on senior management level for each of the policies. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('BIRolesresponsibilities', 's1', 4)" :class="{'active': answers.BIRolesresponsibilities === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>BI Engagement Program</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('BIEngagementProgram', 's1', 0)" :class="{'active': answers.BIEngagementProgram === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('BIEngagementProgram', 's1', 1)" :class="{'active': answers.BIEngagementProgram === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The Business Integrity policies are partially integrated into the operation and management processes of the company (i.e. through training, operational guidelines, disclosures, checklists, management reports, performance targets, incentives, etc).  
              </span>
              <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
                The Business Integrity policies are partially integrated into the operation and management processes of the company (i.e. through training, operational guidelines, disclosures, checklists, management reports, performance targets, incentives, etc).  
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIEngagementProgram', 's1', 2)" :class="{'active': answers.BIEngagementProgram === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The Business Integrity policies are fully integrated into the operation and management processes of the company (i.e. through documented sets of practical operational procedures covering all stages of the operations.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
                The Business Integrity policies are fully integrated into the operation and management processes of the company (i.e. through documented sets of practical operational procedures covering all stages of the operations.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BIEngagementProgram', 's1', 3)" :class="{'active': answers.BIEngagementProgram === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The company engages external stakeholders such as co-investors, business partners and regulators in Business Integrity matters. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
                The company engages external stakeholders such as co-investors, business partners and regulators in Business Integrity matters. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('BIEngagementProgram', 's1', 4)" :class="{'active': answers.BIEngagementProgram === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Performance management</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button" @click="toggle('Performancemanagement', 's1', 0)" :class="{'active': answers.Performancemanagement === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Performancemanagement', 's1', 1)" :class="{'active': answers.Performancemanagement === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There have been occasional evaluations of the relevance, usefulness and adequacy of the Business Integrity policies in content, management arrangements and implementation.
              </span>
              <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
                There have been occasional evaluations of the relevance, usefulness and adequacy of the Business Integrity policies in content, management arrangements and implementation.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Performancemanagement', 's1', 2)" :class="{'active': answers.Performancemanagement === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There are regular evaluations of the Business Integrity policies, and the responsibility for these evaluations is defined. There are also Business Integrity performance reports summarising breaches, incidents and issues that have arisen. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
                There are regular evaluations of the Business Integrity policies, and the responsibility for these evaluations is defined. There are also Business Integrity performance reports summarising breaches, incidents and issues that have arisen. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Performancemanagement', 's1', 3)" :class="{'active': answers.Performancemanagement === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The Business Integrity performance reports and the evaluations of the Business Integrity policies and the reports summarising are reported to the Board with regular intervals.  
              </span>
              <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
                The Business Integrity performance reports and the evaluations of the Business Integrity policies and the reports summarising are reported to the Board with regular intervals.  
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Performancemanagement', 's1', 4)" :class="{'active': answers.Performancemanagement === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[1].status}">
      <div class="group-title" @click="toggleSection(1)">
        <span class="title">
          <button>
            <span v-if="!section[1].status">+</span>
            <span v-if="section[1].status">-</span>
          </button>
          Business Integrity Alert and Economic Sanctions
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s2')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s2')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              One (or several) Politically Exposed Persons, potentially posing a risk to the Company. No company staff or associated persons appear on the EU, UN or OFAC sanctions lists. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              One (or several) Politically Exposed Persons, potentially posing a risk to the Company. No company staff or associated persons appear on the EU, UN or OFAC sanctions lists. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s2')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              One (or several) Politically Exposed Persons, but without risk to the Company. Regular reviews of EU, UN or OFAC sanctions lists against all employees and persons associated with the company. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              One (or several) Politically Exposed Persons, but without risk to the Company. Regular reviews of EU, UN or OFAC sanctions lists against all employees and persons associated with the company. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s2')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              No Politically Exposed Persons. Procedures established to handle situation where individuals appear on sanctions lists. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              No Politically Exposed Persons. Procedures established to handle situation where individuals appear on sanctions lists. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s3')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Politically Exposed Person (PEP) involvement</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('PoliticallyExposedPersoninvolvement', 's2', 0)" :class="{'active': answers.PoliticallyExposedPersoninvolvement === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('PoliticallyExposedPersoninvolvement', 's2', 1)" :class="{'active': answers.PoliticallyExposedPersoninvolvement === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is at least one Politically Exposed Person involved in the Company, and where such exposure has the potential to impact the company. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                There is at least one Politically Exposed Person involved in the Company, and where such exposure has the potential to impact the company. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('PoliticallyExposedPersoninvolvement', 's2', 2)" :class="{'active': answers.PoliticallyExposedPersoninvolvement === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There is at least one Politically Exposed Person involved in the Company, but without exposure deemed to have an impact on the company. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                There is at least one Politically Exposed Person involved in the Company, but without exposure deemed to have an impact on the company. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('PoliticallyExposedPersoninvolvement', 's2', 3)" :class="{'active': answers.PoliticallyExposedPersoninvolvement === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is no Politically Exposed Person involved in the Company. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
                There is no Politically Exposed Person involved in the Company. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('PoliticallyExposedPersoninvolvement', 's2', 4)" :class="{'active': answers.PoliticallyExposedPersoninvolvement === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Sanctions List</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('SanctionsList', 's2', 0)" :class="{'active': answers.SanctionsList === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('SanctionsList', 's2', 1)" :class="{'active': answers.SanctionsList === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The company, its board members, its beneficial owners, its country/ies of domicile and operations, and its business partners are not on the EU, UN or OFAC sanctions lists. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                The company, its board members, its beneficial owners, its country/ies of domicile and operations, and its business partners are not on the EU, UN or OFAC sanctions lists. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('SanctionsList', 's2', 2)" :class="{'active': answers.SanctionsList === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There are regular reviews of the relevant sanctions lists to ensure the business integrity of the company's board members, business partners, and beneficial owners. 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                There are regular reviews of the relevant sanctions lists to ensure the business integrity of the company's board members, business partners, and beneficial owners. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('SanctionsList', 's2', 3)" :class="{'active': answers.SanctionsList === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There are procedures established to handle situations where a party related to the company appears on any of the relevant sanctions lists (i.e. contractual clauses or similar). 
              </span>
              <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
                There are procedures established to handle situations where a party related to the company appears on any of the relevant sanctions lists (i.e. contractual clauses or similar). 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('SanctionsList', 's2', 4)" :class="{'active': answers.SanctionsList === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[2].status}">
      <div class="group-title" @click="toggleSection(2)">
        <span class="title">
          <button>
            <span v-if="!section[2].status">+</span>
            <span v-if="section[2].status">-</span>
          </button>
          Compliance with laws and regulations
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s3')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s3')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              Awareness of, but not full compliance with applicable legislation and regulations. Occasional external audits. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Awareness of, but not full compliance with applicable legislation and regulations. Occasional external audits. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s3')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Monitoring and awareness of all relevant legislation and regulation, and intention to reach full compliance. Regular external audits. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Monitoring and awareness of all relevant legislation and regulation, and intention to reach full compliance. Regular external audits. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s3')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Full compliance, and processes to identify and monitor relevant legislation and regulation and ensure continuous compliance. Audits and compliance reports provided to the Board. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Full compliance, and processes to identify and monitor relevant legislation and regulation and ensure continuous compliance. Audits and compliance reports provided to the Board. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s3')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Awareness</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Awareness', 's3', 0)" :class="{'active': answers.Awareness === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Awareness', 's3', 1)" :class="{'active': answers.Awareness === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Relevant norms, standards, regulations and laws governing the full scope of the company's operations have been identified and are occasionally verified and updated. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Relevant norms, standards, regulations and laws governing the full scope of the company's operations have been identified and are occasionally verified and updated. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Awareness', 's3', 2)" :class="{'active': answers.Awareness === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                A record is kept and updated of all relevant norms, standards, regulations and laws. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                A record is kept and updated of all relevant norms, standards, regulations and laws. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Awareness', 's3', 3)" :class="{'active': answers.Awareness === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is a process to govern the regular identification and update of relevant norms, standards, regulations and laws.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a process to govern the regular identification and update of relevant norms, standards, regulations and laws.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Awareness', 's3', 4)" :class="{'active': answers.Awareness === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Compliance</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('ComplianceBI', 's3', 0)" :class="{'active': answers.ComplianceBI === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('ComplianceBI', 's3', 1)" :class="{'active': answers.ComplianceBI === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The company is not compliant with all norms, standards, regulations and legislation and is not actively pursuing initiatives to reach full compliance either.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company is not compliant with all norms, standards, regulations and legislation and is not actively pursuing initiatives to reach full compliance either.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ComplianceBI', 's3', 2)" :class="{'active': answers.ComplianceBI === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The company is actively pursuing compliance with all norms, standards, regulations and legislation.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company is actively pursuing compliance with all norms, standards, regulations and legislation.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ComplianceBI', 's3', 3)" :class="{'active': answers.ComplianceBI === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The company is compliant with all norms, standards, regulations and legislation.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company is compliant with all norms, standards, regulations and legislation.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ComplianceBI', 's3', 4)" :class="{'active': answers.ComplianceBI === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Audits</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Auditsbi', 's3', 0)" :class="{'active': answers.Auditsbi === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Auditsbi', 's3', 1)" :class="{'active': answers.Auditsbi === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                External audits of regulatory compliance take place occasionally.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                External audits of regulatory compliance take place occasionally.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Auditsbi', 's3', 2)" :class="{'active': answers.Auditsbi === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                External audits of regulatory compliance take place regularly. Assessment of the implementation of the anti-corruption policy is also in scope for external auditors. At least one internal audit of the functioning of the whistleblower system has been made. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                External audits of regulatory compliance take place regularly. Assessment of the implementation of the anti-corruption policy is also in scope for external auditors. At least one internal audit of the functioning of the whistleblower system has been made. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Auditsbi', 's3', 3)" :class="{'active': answers.Auditsbi === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Internal or external audits also conducted of the system to monitor regulatory compliance. External auditors dedicate specific resources and interviews to the assessment of the anti-corruption policy implementation. Periodical internal audits of the whistleblower system.  
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Internal or external audits also conducted of the system to monitor regulatory compliance. External auditors dedicate specific resources and interviews to the assessment of the anti-corruption policy implementation. Periodical internal audits of the whistleblower system.  
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Auditsbi', 's3', 4)" :class="{'active': answers.Auditsbi === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Monitoring and oversight</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button" @click="toggle('Monitoringoversight', 's3', 0)" :class="{'active': answers.Monitoringoversight === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Monitoringoversight', 's3', 1)" :class="{'active': answers.Monitoringoversight === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is a named person accountable for regulatory compliance. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                There is a named person accountable for regulatory compliance. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Monitoringoversight', 's3', 2)" :class="{'active': answers.Monitoringoversight === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There is accountability for regulatory compliance in senior management. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                There is accountability for regulatory compliance in senior management. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Monitoringoversight', 's3', 3)" :class="{'active': answers.Monitoringoversight === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Regulatory compliance is part of the regular reporting provided to the Board. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Regulatory compliance is part of the regular reporting provided to the Board. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Monitoringoversight', 's3', 4)" :class="{'active': answers.Monitoringoversight === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[3].status}">
      <div class="group-title" @click="toggleSection(3)">
        <span class="title">
          <button>
            <span v-if="!section[3].status">+</span>
            <span v-if="section[3].status">-</span>
          </button>
          Anti-Bribery and Corruption (ABC) Management
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s4')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s4')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              There is an anti-corruption policy, endorsed by senior management. Training is provided for select staff, and there is an anti-corruption officer. Policy implementation is audited. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              There is an anti-corruption policy, endorsed by senior management. Training is provided for select staff, and there is an anti-corruption officer. Policy implementation is audited. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s4')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              The policy also mandate staff to report suspected corruption. The anti-corruption reports directly to the Board. External auditors dedicate specific resources and time for the auditing of the anti-corruption policy implementation. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              The policy also mandate staff to report suspected corruption. The anti-corruption reports directly to the Board. External auditors dedicate specific resources and time for the auditing of the anti-corruption policy implementation. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s4')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              The policy is communicated to all external business partners. Training is required for all employees. The Board confirms in the annual report every year that it is satisfied with the anti-corruption policy implementation. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              The policy is communicated to all external business partners. Training is required for all employees. The Board confirms in the annual report every year that it is satisfied with the anti-corruption policy implementation. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s4')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Anti-corruption policies and procedures</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Anticorruptionpoliciesprocedures', 's4', 0)" :class="{'active': answers.Anticorruptionpoliciesprocedures === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Anticorruptionpoliciesprocedures', 's4', 1)" :class="{'active': answers.Anticorruptionpoliciesprocedures === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is an anti-corruption policy in place, and it states that the company, its employees, and related third parties will not under any circumstances accept bribes, kickbacks or facilitation payments. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is an anti-corruption policy in place, and it states that the company, its employees, and related third parties will not under any circumstances accept bribes, kickbacks or facilitation payments. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Anticorruptionpoliciesprocedures', 's4', 2)" :class="{'active': answers.Anticorruptionpoliciesprocedures === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The policy also states that employees are required to report knowledge or suspicion of corruption; that they are guaranteed not to suffer any adverse consequences as a result; and that breaches of the anti-corruption policy is considered gross misconduct. The anti-corruption policy is part of the staff handbook or other operational manual. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The policy also states that employees are required to report knowledge or suspicion of corruption; that they are guaranteed not to suffer any adverse consequences as a result; and that breaches of the anti-corruption policy is considered gross misconduct. The anti-corruption policy is part of the staff handbook or other operational manual. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Anticorruptionpoliciesprocedures', 's4', 3)" :class="{'active': answers.Anticorruptionpoliciesprocedures === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The policy is communicated to all external business partners prior to contracts being signed. Competitive procurement procedures and screening of contractors are in place to prevent bribes from being paid or received on the company's behalf.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The policy is communicated to all external business partners prior to contracts being signed. Competitive procurement procedures and screening of contractors are in place to prevent bribes from being paid or received on the company's behalf.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Anticorruptionpoliciesprocedures', 's4', 4)" :class="{'active': answers.Anticorruptionpoliciesprocedures === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Accountability, roles and responsibilities</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Accountabilityrolesresponsibilities', 's4', 0)" :class="{'active': answers.Accountabilityrolesresponsibilities === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Accountabilityrolesresponsibilities', 's4', 1)" :class="{'active': answers.Accountabilityrolesresponsibilities === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is an appointed anti-corruption officer.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                There is an appointed anti-corruption officer.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Accountabilityrolesresponsibilities', 's4', 2)" :class="{'active': answers.Accountabilityrolesresponsibilities === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The appointed anti-corruption officer reports directly to a member of the senior management team.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The appointed anti-corruption officer reports directly to a member of the senior management team.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Accountabilityrolesresponsibilities', 's4', 3)" :class="{'active': answers.Accountabilityrolesresponsibilities === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The appointed anti-corruption officer is part of the senior management team, or there is a person in senior management who is overall accountable for anti-corruption.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The appointed anti-corruption officer is part of the senior management team, or there is a person in senior management who is overall accountable for anti-corruption.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Accountabilityrolesresponsibilities', 's4', 4)" :class="{'active': answers.Accountabilityrolesresponsibilities === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Senior level endorsement and public statement</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Seniorlevelendorsementpublicstatement', 's4', 0)" :class="{'active': answers.Seniorlevelendorsementpublicstatement === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Seniorlevelendorsementpublicstatement', 's4', 1)" :class="{'active': answers.Seniorlevelendorsementpublicstatement === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Senior management has publicly endorsed the anti-corruption policy and communicated this to all employees. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Senior management has publicly endorsed the anti-corruption policy and communicated this to all employees. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Seniorlevelendorsementpublicstatement', 's4', 2)" :class="{'active': answers.Seniorlevelendorsementpublicstatement === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The Board has publicly endorsed the anti-corruption policy. The anti-corruption policy is widely and easily available to internal and external audiences on the company's website or through other easily accessible channels. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The Board has publicly endorsed the anti-corruption policy. The anti-corruption policy is widely and easily available to internal and external audiences on the company's website or through other easily accessible channels. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Seniorlevelendorsementpublicstatement', 's4', 3)" :class="{'active': answers.Seniorlevelendorsementpublicstatement === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The Board and/or senior management regularly communicate and confirm their endorsement of the anti-corruption policy. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The Board and/or senior management regularly communicate and confirm their endorsement of the anti-corruption policy. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Seniorlevelendorsementpublicstatement', 's4', 4)" :class="{'active': answers.Seniorlevelendorsementpublicstatement === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[4].status}">
      <div class="group-title" @click="toggleSection(4)">
        <span class="title">
          <button>
            <span v-if="!section[4].status">+</span>
            <span v-if="section[4].status">-</span>
          </button>
          Whistleblower system
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s5')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s5')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              There is a whistleblowing policy available to internal parties. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              There is a whistleblowing policy available to internal parties. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s5')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              The whistleblowing policy is endorsed by senior management and the Board, is available to external parties, and is subject to periodical internal audits. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              The whistleblowing policy is endorsed by senior management and the Board, is available to external parties, and is subject to periodical internal audits. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s5')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              The whistleblowing policy is regularly communicated to all staff, and overseen by senior management. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              The whistleblowing policy is regularly communicated to all staff, and overseen by senior management. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s5')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Policy and commitment</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Policyandcommitment', 's5', 0)" :class="{'active': answers.Policyandcommitment === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Policyandcommitment', 's5', 1)" :class="{'active': answers.Policyandcommitment === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is a formal whistleblowing policy
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a formal whistleblowing policy
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Policyandcommitment', 's5', 2)" :class="{'active': answers.Policyandcommitment === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The whistleblowing policy has been formally supported by the Board and senior management, externally and internally.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The whistleblowing policy has been formally supported by the Board and senior management, externally and internally.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Policyandcommitment', 's5', 3)" :class="{'active': answers.Policyandcommitment === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The whistleblowing policy and system is communicated regularly to all company employees, via announcements, training, new employee induction etc. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The whistleblowing policy and system is communicated regularly to all company employees, via announcements, training, new employee induction etc. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Policyandcommitment', 's5', 4)" :class="{'active': answers.Policyandcommitment === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Procedures</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Procedures', 's5', 0)" :class="{'active': answers.Procedures === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Procedures', 's5', 1)" :class="{'active': answers.Procedures === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Senior management oversees the whistleblower system periodically.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Senior management oversees the whistleblower system periodically.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Procedures', 's5', 2)" :class="{'active': answers.Procedures === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Senior management oversees the whistleblower system on a regular basis.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Senior management oversees the whistleblower system on a regular basis.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Procedures', 's5', 3)" :class="{'active': answers.Procedures === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Senior management oversees the whistleblower system on a regular basis, and monitors the functioning of the system.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Senior management oversees the whistleblower system on a regular basis, and monitors the functioning of the system.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Procedures', 's5', 4)" :class="{'active': answers.Procedures === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Public statement</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Publicstatement', 's5', 0)" :class="{'active': answers.Publicstatement === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Publicstatement', 's5', 1)" :class="{'active': answers.Publicstatement === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The whistleblowing policy is available to internal parties only. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The whistleblowing policy is available to internal parties only. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Publicstatement', 's5', 2)" :class="{'active': answers.Publicstatement === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The whistleblowing policy is available to internal and external parties. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The whistleblowing policy is available to internal and external parties. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Publicstatement', 's5', 3)" :class="{'active': answers.Publicstatement === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The whistleblowing policy is available to internal and external parties through easily accessible channels, and is regularly communicated. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The whistleblowing policy is available to internal and external parties through easily accessible channels, and is regularly communicated. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Publicstatement', 's5', 4)" :class="{'active': answers.Publicstatement === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[5].status}">
      <div class="group-title" @click="toggleSection(5)">
        <span class="title">
          <button>
            <span v-if="!section[5].status">+</span>
            <span v-if="section[5].status">-</span>
          </button>
          Cyber and data security
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s6')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s6')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              System, user and network activity is monitored, systems and security patches are up-to-date, and basic cyber threat and data security risk governance elements have been established. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              System, user and network activity is monitored, systems and security patches are up-to-date, and basic cyber threat and data security risk governance elements have been established. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s6')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              User privileges are reviewed periodically, generic training is provided, there are security protocols to prevent data breaches, and there is an appointed person in charge of cyber and data security.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              User privileges are reviewed periodically, generic training is provided, there are security protocols to prevent data breaches, and there is an appointed person in charge of cyber and data security.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s6')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              User privileges are restricted to the necessary minimum, tailored training is provided to specific staff and needs, there are regular security reviews, and the company discloses externally it has a cyber security team. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              User privileges are restricted to the necessary minimum, tailored training is provided to specific staff and needs, there are regular security reviews, and the company discloses externally it has a cyber security team. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s6')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>User privileges and access</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Userprivilegesaccess', 's6', 0)" :class="{'active': answers.Userprivilegesaccess === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Userprivilegesaccess', 's6', 1)" :class="{'active': answers.Userprivilegesaccess === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is a formal process in place to grant user privileges. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a formal process in place to grant user privileges. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Userprivilegesaccess', 's6', 2)" :class="{'active': answers.Userprivilegesaccess === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There is a process in place to review and keep up-to-date user privileges and users.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a process in place to review and keep up-to-date user privileges and users.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Userprivilegesaccess', 's6', 3)" :class="{'active': answers.Userprivilegesaccess === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is a process to restrict user privileges and access to sensitive data to the necessary minimum.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a process to restrict user privileges and access to sensitive data to the necessary minimum.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Userprivilegesaccess', 's6', 4)" :class="{'active': answers.Userprivilegesaccess === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Secure systems and configuration</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Securesystemsconfiguration', 's6', 0)" :class="{'active': answers.Securesystemsconfiguration === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Securesystemsconfiguration', 's6', 1)" :class="{'active': answers.Securesystemsconfiguration === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                All systems, configurations and security patches are up to date. Anti-malware defences are in place across the entire company and they are active. Regular back-up routines are in place and tested regularly. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                All systems, configurations and security patches are up to date. Anti-malware defences are in place across the entire company and they are active. Regular back-up routines are in place and tested regularly. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Securesystemsconfiguration', 's6', 2)" :class="{'active': answers.Securesystemsconfiguration === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There are security protocols to ensure adequate protection from intrusions, data breaches, unauthorised access, malicious content and other attacks.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                There are security protocols to ensure adequate protection from intrusions, data breaches, unauthorised access, malicious content and other attacks.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Securesystemsconfiguration', 's6', 3)" :class="{'active': answers.Securesystemsconfiguration === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Threat intelligence and behavioural analytics is used to detect attacks and intrusions.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Threat intelligence and behavioural analytics is used to detect attacks and intrusions.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Securesystemsconfiguration', 's6', 4)" :class="{'active': answers.Securesystemsconfiguration === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Network and endpoint protection</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Networkendpointprotection', 's6', 0)" :class="{'active': answers.Networkendpointprotection === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Networkendpointprotection', 's6', 1)" :class="{'active': answers.Networkendpointprotection === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Preventive measures are in place to prevent unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Preventive measures are in place to prevent unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Networkendpointprotection', 's6', 2)" :class="{'active': answers.Networkendpointprotection === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Processes are in place to detect unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Processes are in place to detect unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Networkendpointprotection', 's6', 3)" :class="{'active': answers.Networkendpointprotection === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Regular reviews are established, and performance measured of actual levels of unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Regular reviews are established, and performance measured of actual levels of unauthorised access, malicious content and other attacks and intrusions managed in the company's networks.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Networkendpointprotection', 's6', 4)" :class="{'active': answers.Networkendpointprotection === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Cyber and data security risk management</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button" @click="toggle('Cyberdatasecurityriskmanagement', 's6', 0)" :class="{'active': answers.Cyberdatasecurityriskmanagement === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Cyberdatasecurityriskmanagement', 's6', 1)" :class="{'active': answers.Cyberdatasecurityriskmanagement === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Basic risk governance elements are in place to manage cyber and data security risks, i.e. policies, roles and responsibilities, reporting, and performance monitoring. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Basic risk governance elements are in place to manage cyber and data security risks, i.e. policies, roles and responsibilities, reporting, and performance monitoring. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Cyberdatasecurityriskmanagement', 's6', 2)" :class="{'active': answers.Cyberdatasecurityriskmanagement === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There an appointed person in charge of cyber and data security risk management. There is a named person at senior management or executive committee level with overall responsibility for information management and cyber security. The company communicates information about the company's cyber/information security strategy to senior management and the Board.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                There an appointed person in charge of cyber and data security risk management. There is a named person at senior management or executive committee level with overall responsibility for information management and cyber security. The company communicates information about the company's cyber/information security strategy to senior management and the Board.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Cyberdatasecurityriskmanagement', 's6', 3)" :class="{'active': answers.Cyberdatasecurityriskmanagement === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The company discloses externally that it has a cyber/information security team. The company has access to external expertise on cyber security.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                The company discloses externally that it has a cyber/information security team. The company has access to external expertise on cyber security.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Cyberdatasecurityriskmanagement', 's6', 4)" :class="{'active': answers.Cyberdatasecurityriskmanagement === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised" @click="previousSection()">Previous</md-button>
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Finish ESG Review</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="done()">Finish ESG Review</md-button>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import HeaderColumnsLower from '../HeaderColumnsLower';

export default {
  name: "bi",
  components: {
    HeaderColumnsLower,
  },
  data() {
    return {
      step: 0,
      answers: [],
      disabled: true,
      expandStatus: this.$store.state.expandStatus,
      fund: this.$store.state.fund,
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
      section: [
        { status: false, count: 0, total: 4, name: "s1" },
        { status: false, count: 0, total: 2, name: "s2" },
        { status: false, count: 0, total: 4, name: "s3" },
        { status: false, count: 0, total: 3, name: "s4" },
        { status: false, count: 0, total: 3, name: "s5" },
        { status: false, count: 0, total: 4, name: "s6" },
      ],
      sectionMedian: {
        s1: null,
        s2: null,
        s3: null,
        s4: null,
        s5: null,
        s6: null,
      },
      level: 'bi',
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
  },
  methods: {
    toggle(key, section, index) {
      const total = this.section.filter((item) => item.name === section)[0].total
      this.$store.commit("answerReview", {level: "bi", type: "lower", key: key, value: index, section: section, total: total});
      const check = this.review.lower.filter((item) => item.section === section && item.level === "bi")
      this.step = check.length;
      if (this.step >= total) {
        this.section.forEach((item) => {
          item.status = false;
        });
      }
    },
    assembleAnswers() {
      this.answers = []
      this.review.lower.map((item) => {
        this.answers[item.key] = item.value;
      });
      this.getMedian();
      if (this.reviewCount.lower.bi >= 20) {
        this.disabled = false;
      }
    },
    done() {
      this.$store.commit("reviewDone");
      router.push(`/overview/`)
    },
    previousSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 1})
    },
    toggleSection(key) {
      if (this.section[key].status) {
        this.section[key].status = false;
        return false;
      }
      this.section.forEach((item) => {
        item.status = false;
      });
      this.section[key].status = !this.section[key].status;
      const check = this.review.lower.filter((item) => item.section === this.section[key].name && item.level === "bi")
      const total = this.section[key].total;
      this.step = check.length === total ? 0 : check.length;
    },
    getMedian() {
      this.section.map((section) => {
        let total = 0
        if (this.review.lower) {
          const item = this.review.lower.filter((item) => item.section === section.name && item.level === this.level)
          if (item)
            item.map((item) => {
              total += item.value;
            });
        }
        this.sectionMedian[section.name] = parseInt(total / section.total);
      });
    },
    getLabel(section) {
      const total = this.section.filter((item) => item.name === section)[0].total
      const check = this.review.lower.filter((item) => item.section === section && item.level === this.level)
      if (check.length < total)
        return '';
      return this.sectionMedian[section];
    }
  }
}
</script>
