<template>
  <md-card v-if="data.sector">
    <md-card-header>
      <div class="md-title">
        <md-button to="/overview">
          <md-icon>chevron_left</md-icon>
        </md-button>
        {{data.name}}
      </div>
    </md-card-header>
    <md-card-content>
      <md-table>
        <md-table-row>
          <md-table-cell class="left">
            Sector
          </md-table-cell>
          <md-table-cell>
            {{data.sector}}
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="left">
            Subsector
          </md-table-cell>
          <md-table-cell>
            {{data.subsector}}
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="left">
            Country
          </md-table-cell>
          <md-table-cell>
            {{data.country}}
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="left">
            Investment type
          </md-table-cell>
          <md-table-cell>
            {{data.type}}
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="left">
            Investment stage
          </md-table-cell>
          <md-table-cell>
            {{data.stage}}
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell class="left">
            FMO KYC country risk
          </md-table-cell>
          <md-table-cell :class="getClass()">
            {{data.overallRiskRating}}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardId",
  props: ["item"],
  data() {
    return {
      data: {}
    }
  },
  methods: {
    getClass() {
      return this.data.overallRiskRating.trim() === 'Unacceptable' ? 'red' : '';
    },
  },
  mounted() {
    if (this.item) 
      this.data = this.item;
  },
  watch: {
    item() {
      if (this.item.sector) {
        this.data = this.item;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
}
.md-title {
  line-height: 37px;
  a.md-button {
    min-width: inherit;
    margin-right: 10px;
  }
}
.md-card-content {
  padding: 0;
}
.left {
  text-align: left;
  font-weight: bold;
}
.red {
  color: #d43501;
}
</style>