import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CountryFlag from 'vue-country-flag'
import PrismicVue from 'prismic-vue'
import linkResolver from './prismic/link-resolver'
import htmlSerializer from './prismic/html-serializer'

Vue.component('vue-country-flag', CountryFlag)

Vue.config.productionTip = false;

Vue.use(PrismicVue, {
  endpoint: 'https://newmont-dashboard.cdn.prismic.io/api/v2',
  linkResolver,
  htmlSerializer
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
