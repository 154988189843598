<template>
  <main>
    <div class="utils">
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
    </div>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Investment PS</h1>
        </div>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="'Nothing here yet, start importing new data with the button above'">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="Stage list">{{item.name}}</md-table-cell>
        <md-table-cell md-label="Relevance list">{{item['Relevance list']}}</md-table-cell>
        <md-table-cell md-label="yes / no list">{{item['yes / no list']}}</md-table-cell>
        <md-table-cell md-label="Management list">{{item['Management list']}}</md-table-cell>
        <md-table-cell md-label="Bubble scale factor">{{item['Bubble scale factor']}}</md-table-cell>
        <md-table-cell md-label="yes / no words list">{{item['yes / no words list']}}</md-table-cell>
        <md-table-cell md-label="E&S opportunity">{{item['E&S opportunity']}}</md-table-cell>
        <md-table-cell md-label="PS2 options">{{item['PS2 options']}}</md-table-cell>
        <md-table-cell md-label="PS3 options">{{item['PS3 options']}}</md-table-cell>
        <md-table-cell md-label="PS4 options">{{item['PS4 options']}}</md-table-cell>
        <md-table-cell md-label="PS5 options">{{item['PS5 options']}}</md-table-cell>
        <md-table-cell md-label="PS6 options">{{item['PS6 options']}}</md-table-cell>
        <md-table-cell md-label="PS7 options">{{item['PS7 options']}}</md-table-cell>
        <md-table-cell md-label="PS8 options">{{item['PS8 options']}}</md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/investment/stage/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}data/stages/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
