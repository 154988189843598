<template>
  <main>
    <HeaderColumnsLowerSMECG />
    <div class="group" :class="{'active': section[0].status}">
      <div class="group-title" @click="toggleSection(0)">
        <span class="title">
          <button>
            <span v-if="!section[0].status">+</span>
            <span v-if="section[0].status">-</span>
          </button>
          Commitment to CG
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s1')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s1')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s1')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s1')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s1')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Formalised view on Corporate Governance</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('FormalisedViewOnCorporateGovernance', 's1', 0)" :class="{'active': answers.FormalisedViewOnCorporateGovernance === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Articles of association are in place
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Articles of association are in place
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormalisedViewOnCorporateGovernance', 's1', 1)" :class="{'active': answers.FormalisedViewOnCorporateGovernance === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Organisation chart with reporting lines is in place, key policies and statement of basic business principles established
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Organisation chart with reporting lines is in place, key policies and statement of basic business principles established
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormalisedViewOnCorporateGovernance', 's1', 2)" :class="{'active': answers.FormalisedViewOnCorporateGovernance === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Clear division of responsibilities and authorities between management and the board. Core governance processes have been documented & the development of governance structures has been assigned to one company executive
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Clear division of responsibilities and authorities between management and the board. Core governance processes have been documented & the development of governance structures has been assigned to one company executive
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormalisedViewOnCorporateGovernance', 's1', 3)" :class="{'active': answers.FormalisedViewOnCorporateGovernance === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Clearly written corporate governance policies have been adopted (code of conduct, whistleblowing, corporate governance code with E&S considerations, succession plan, HR/Grievance mechanism
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Clearly written corporate governance policies have been adopted (code of conduct, whistleblowing, corporate governance code with E&S considerations, succession plan, HR/Grievance mechanism
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('FormalisedViewOnCorporateGovernance', 's1', 4)" :class="{'active': answers.FormalisedViewOnCorporateGovernance === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Authority</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Authority', 's1', 0)" :class="{'active': answers.Authority === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Core functions that are needed have been identified
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Core functions that are needed have been identified
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 1)" :class="{'active': answers.Authority === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Core staff positions have been filled (accounting, IT, admin, legal) or outsourced to qualified professionals
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Core staff positions have been filled (accounting, IT, admin, legal) or outsourced to qualified professionals
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 2)" :class="{'active': answers.Authority === 2}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Clearly defined roles and responsibilities defined for key personnel
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Clearly defined roles and responsibilities defined for key personnel
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 3)" :class="{'active': answers.Authority === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Company secretary function has been established
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Company secretary function has been established
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Authority', 's1', 4)" :class="{'active': answers.Authority === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Formulation of Strategy</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('FormulationOfStrategy', 's1', 1)" :class="{'active': answers.FormulationOfStrategy === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Initial strategic plans and budgets are documented
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Initial strategic plans and budgets are documented
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormulationOfStrategy', 's1', 2)" :class="{'active': answers.FormulationOfStrategy === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Documented strategic plans and budgets (1-3 years)
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Documented strategic plans and budgets (1-3 years)
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormulationOfStrategy', 's1', 3)" :class="{'active': answers.FormulationOfStrategy === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Strategy discussed at board level
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Strategy discussed at board level
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('FormulationOfStrategy', 's1', 4)" :class="{'active': answers.FormulationOfStrategy === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[1].status}">
      <div class="group-title" @click="toggleSection(1)">
        <span class="title">
          <button>
            <span v-if="!section[1].status">+</span>
            <span v-if="section[1].status">-</span>
          </button>
          Decision making and strategic oversight
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s2')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s2')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s2')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s2')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s2')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Strategic guidance & input</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('StrategicGuidanceInputCG', 's2', 0)" :class="{'active': answers.StrategicGuidanceInputCG === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Informal external advisers on strategic issues are identified and involved  as required
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Informal external advisers on strategic issues are identified and involved  as required
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('StrategicGuidanceInputCG', 's2', 1)" :class="{'active': answers.StrategicGuidanceInputCG === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                External Advisers have been formally engaged for strategic input
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                External Advisers have been formally engaged for strategic input
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('StrategicGuidanceInputCG', 's2', 2)" :class="{'active': answers.StrategicGuidanceInputCG === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                There is a formal board comprised of main shareholders or continuous and structured outside advice is received through an advisory board or similar).
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                There is a formal board comprised of main shareholders or continuous and structured outside advice is received through an advisory board or similar).
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('StrategicGuidanceInputCG', 's2', 3)" :class="{'active': answers.StrategicGuidanceInputCG === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Board approves strategy and discusses major material risks facing the company.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Board approves strategy and discusses major material risks facing the company.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('StrategicGuidanceInputCG', 's2', 4)" :class="{'active': answers.StrategicGuidanceInputCG === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Decision processes</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('DecisionProcessesCG', 's2', 0)" :class="{'active': answers.DecisionProcessesCG === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Founder(s) make decisions in consultation with individual executives
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Founder(s) make decisions in consultation with individual executives
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('DecisionProcessesCG', 's2', 1)" :class="{'active': answers.DecisionProcessesCG === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Key decisions are made in collaboration with key executives as a group
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Key decisions are made in collaboration with key executives as a group
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('DecisionProcessesCG', 's2', 2)" :class="{'active': answers.DecisionProcessesCG === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Regular meetings are held with agenda/supporting documents & accompanying meeting notification. Adequate minutes of the meeting are prepared. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Regular meetings are held with agenda/supporting documents & accompanying meeting notification. Adequate minutes of the meeting are prepared. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('DecisionProcessesCG', 's2', 3)" :class="{'active': answers.DecisionProcessesCG === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Board committees have been constituted where appropriate. Board procedures ensure effective meetings and input from each of the directors.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Board committees have been constituted where appropriate. Board procedures ensure effective meetings and input from each of the directors.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('DecisionProcessesCG', 's2', 4)" :class="{'active': answers.DecisionProcessesCG === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Capabilities & performance</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('CapabilitiesPerformanceCG', 's2', 1)" :class="{'active': answers.CapabilitiesPerformanceCG === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Staffing priorities are identified
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Staffing priorities are identified
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('CapabilitiesPerformanceCG', 's2', 2)" :class="{'active': answers.CapabilitiesPerformanceCG === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Performance is discussed by the board as a team
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Performance is discussed by the board as a team
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('CapabilitiesPerformanceCG', 's2', 3)" :class="{'active': answers.CapabilitiesPerformanceCG === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Board members receive an induction and regular training including on relevant industry E&S issues. Performance is discussed by the board as a team
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Board members receive an induction and regular training including on relevant industry E&S issues. Performance is discussed by the board as a team
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('CapabilitiesPerformanceCG', 's2', 4)" :class="{'active': answers.CapabilitiesPerformanceCG === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Succession planning and business continuity</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('SuccessionPlanningAndBusinessContinuityCG', 's2', 1)" :class="{'active': answers.SuccessionPlanningAndBusinessContinuityCG === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Business continuity plan for CEO and key persons mapped out
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                Business continuity plan for CEO and key persons mapped out
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('SuccessionPlanningAndBusinessContinuityCG', 's2', 2)" :class="{'active': answers.SuccessionPlanningAndBusinessContinuityCG === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                A succession framework has been established for key persons. HR policies are in place to attract, retain and motivate staff
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                A succession framework has been established for key persons. HR policies are in place to attract, retain and motivate staff
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('SuccessionPlanningAndBusinessContinuityCG', 's2', 3)" :class="{'active': answers.SuccessionPlanningAndBusinessContinuityCG === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Succession planning policy has been approved by the board
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                Succession planning policy has been approved by the board
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('SuccessionPlanningAndBusinessContinuityCG', 's2', 4)" :class="{'active': answers.SuccessionPlanningAndBusinessContinuityCG === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 4}">
          <span class="title">
            <span>Division of authority & skills mix</span>
          </span>
          <div class="buttons" :class="{'active': step === 4}">
            <span class="button" @click="toggle('DivisionOfAuthoritySkillsMixCG', 's2', 0)" :class="{'active': answers.DivisionOfAuthoritySkillsMixCG === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Authority Limits of key personnel have been communicated
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 4">
                Authority Limits of key personnel have been communicated
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('DivisionOfAuthoritySkillsMixCG', 's2', 1)" :class="{'active': answers.DivisionOfAuthoritySkillsMixCG === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Limited delegation of signing authority is formalised
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 4">
                Limited delegation of signing authority is formalised
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('DivisionOfAuthoritySkillsMixCG', 's2', 2)" :class="{'active': answers.DivisionOfAuthoritySkillsMixCG === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                There is an executive/management committee with clear terms of reeference that meets periodically. Board Members understand their role and responsibilities
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 4">
                There is an executive/management committee with clear terms of reeference that meets periodically. Board Members understand their role and responsibilities
              </md-tooltip>
            </span>
            <span class="button-disabled"></span>
            <span class="button na" @click="toggle('DivisionOfAuthoritySkillsMixCG', 's2', 4)" :class="{'active': answers.DivisionOfAuthoritySkillsMixCG === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[2].status}">
      <div class="group-title" @click="toggleSection(2)">
        <span class="title">
          <button>
            <span v-if="!section[2].status">+</span>
            <span v-if="section[2].status">-</span>
          </button>
          Control Environment and Processes 
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s3')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s3')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s3')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s3')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s3')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Financial controls</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('FinancialControls', 's3', 0)" :class="{'active': answers.FinancialControls === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Basic bookkeeping, cash flow management and tax functions established. Business and fiscal registrations are separate from those of the founder(s). Cash sources & bank accounts are separate from those of the founder
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Basic bookkeeping, cash flow management and tax functions established. Business and fiscal registrations are separate from those of the founder(s). Cash sources & bank accounts are separate from those of the founder
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FinancialControls', 's3', 1)" :class="{'active': answers.FinancialControls === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                More advanced bookkeeping and sound planning have been established. Processes are in place for tax payments, records and filing. There are established controls on cash management, signature of bank accounts with thresholds and delegation (single joint or combination) Accounting policies and reports have been established
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                More advanced bookkeeping and sound planning have been established. Processes are in place for tax payments, records and filing. There are established controls on cash management, signature of bank accounts with thresholds and delegation (single joint or combination) Accounting policies and reports have been established
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FinancialControls', 's3', 2)" :class="{'active': answers.FinancialControls === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                There is a strong, professional. Chief Financial Officer
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                There is a strong, professional. Chief Financial Officer
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FinancialControls', 's3', 3)" :class="{'active': answers.FinancialControls === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The independent external auditor reports directly to the board and prepare an annual “management letter” with observations and suggestions for areas of improvement in the company’s accounting and controls and this letter is shared with the board (or audit committee if established)
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                The independent external auditor reports directly to the board and prepare an annual “management letter” with observations and suggestions for areas of improvement in the company’s accounting and controls and this letter is shared with the board (or audit committee if established)
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('FinancialControls', 's3', 4)" :class="{'active': answers.FinancialControls === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Compliance</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Compliance', 's3', 0)" :class="{'active': answers.Compliance === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Basic principles of regulatory requirements and compliance have been communicated
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Basic principles of regulatory requirements and compliance have been communicated
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Compliance', 's3', 1)" :class="{'active': answers.Compliance === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Business conduct principles have been communicated 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Business conduct principles have been communicated 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Compliance', 's3', 2)" :class="{'active': answers.Compliance === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Internal controls are examined by the external auditor during the conduct of the audit
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Internal controls are examined by the external auditor during the conduct of the audit
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Compliance', 's3', 3)" :class="{'active': answers.Compliance === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There are effective internal control systems, policies, and procedures
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                There are effective internal control systems, policies, and procedures
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Compliance', 's3', 4)" :class="{'active': answers.Compliance === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Internal and external audit</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('InternalAndExternalAudit', 's3', 0)" :class="{'active': answers.InternalAndExternalAudit === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Policies are in place for the selection of the external auditor
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Policies are in place for the selection of the external auditor
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('InternalAndExternalAudit', 's3', 1)" :class="{'active': answers.InternalAndExternalAudit === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Internal audit is being done by an external party
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Internal audit is being done by an external party
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('InternalAndExternalAudit', 's3', 2)" :class="{'active': answers.InternalAndExternalAudit === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                An internal audit function has been established
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                An internal audit function has been established
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('InternalAndExternalAudit', 's3', 3)" :class="{'active': answers.InternalAndExternalAudit === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There is a specialised board committee that monitors the overall control environment of the company
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                There is a specialised board committee that monitors the overall control environment of the company
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('InternalAndExternalAudit', 's3', 4)" :class="{'active': answers.InternalAndExternalAudit === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Management information</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('ManagementInformation', 's3', 1)" :class="{'active': answers.ManagementInformation === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                A basic system is in place to track and record sales and accounts. Secure IT is used to generate data and reports
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                A basic system is in place to track and record sales and accounts. Secure IT is used to generate data and reports
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ManagementInformation', 's3', 2)" :class="{'active': answers.InternalAndExternalAudit === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Objectives, strategic planning, budgets, KPIs and clear accountabilities have been documented. Each business unit has clear authority, reporting lines and guidelines
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                Objectives, strategic planning, budgets, KPIs and clear accountabilities have been documented. Each business unit has clear authority, reporting lines and guidelines
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ManagementInformation', 's3', 3)" :class="{'active': answers.ManagementInformation === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There is timely and secure recording of sales and accounts
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 3">
                There is timely and secure recording of sales and accounts
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ManagementInformation', 's3', 4)" :class="{'active': answers.ManagementInformation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[3].status}">
      <div class="group-title" @click="toggleSection(3)">
        <span class="title">
          <button>
            <span v-if="!section[3].status">+</span>
            <span v-if="section[3].status">-</span>
          </button>
          Transparency and Disclosure
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s4')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s4')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s4')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s4')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s4')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Financial disclosures</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Financialdisclosure', 's4', 0)" :class="{'active': answers.Financialdisclosure === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Basic financial accounts are well prepared and reconciled
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Basic financial accounts are well prepared and reconciled
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 1)" :class="{'active': answers.Financialdisclosure === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Monthly bank account reconciliations are disclosed to all founders
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Monthly bank account reconciliations are disclosed to all founders
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 2)" :class="{'active': answers.Financialdisclosure === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Semi-annual financial reports are prepared and annual financial statements are prepared and audited in accordance with national accounting standards
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Semi-annual financial reports are prepared and annual financial statements are prepared and audited in accordance with national accounting standards
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 3)" :class="{'active': answers.Financialdisclosure === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Financial statements are prepared and audited in accordance with international financial and auditing standards
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Financial statements are prepared and audited in accordance with international financial and auditing standards
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Financialdisclosure', 's4', 4)" :class="{'active': answers.Financialdisclosure === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Consistency of information</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('ConsistencyOfInformation', 's4', 0)" :class="{'active': answers.ConsistencyOfInformation === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The same financial information and data are used for all purposes
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                The same financial information and data are used for all purposes
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ConsistencyOfInformation', 's4', 1)" :class="{'active': answers.ConsistencyOfInformation === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                The founder(s), shareholders and directors periodically receive consistent financial-and non-financial information. The company complies with all local financial and non-financial disclosure requirements
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                The founder(s), shareholders and directors periodically receive consistent financial-and non-financial information. The company complies with all local financial and non-financial disclosure requirements
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ConsistencyOfInformation', 's4', 2)" :class="{'active': answers.ConsistencyOfInformation === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                One person has been identified for information sharing. Key decisions are formally communicated to all staff. Basic performance reports are presented to external advisers
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                One person has been identified for information sharing. Key decisions are formally communicated to all staff. Basic performance reports are presented to external advisers
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ConsistencyOfInformation', 's4', 3)" :class="{'active': answers.ConsistencyOfInformation === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                Financial statements are audited by a recognised auditing firm and approved in an annual shareholders meeting. All shareholders are provided with accurate and timely information
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                Financial statements are audited by a recognised auditing firm and approved in an annual shareholders meeting. All shareholders are provided with accurate and timely information
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ConsistencyOfInformation', 's4', 4)" :class="{'active': answers.ConsistencyOfInformation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Public information</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('PublicInformation', 's4', 1)" :class="{'active': answers.PublicInformation === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                The company has website that is regularly updated
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                The company has website that is regularly updated
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('PublicInformation', 's4', 2)" :class="{'active': answers.PublicInformation === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Governance related information is provided on the website
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">
                Governance related information is provided on the website
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('PublicInformation', 's4', 3)" :class="{'active': answers.PublicInformation === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                 An annual report is produced, and it includes ESG information and this is published on the website. Related party transactions are disclosed to the board and shareholders. Key nonfinancial information is disclosed to the public including risks, opportunities and ESG practices
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">                
                 An annual report is produced, and it includes ESG information and this is published on the website. Related party transactions are disclosed to the board and shareholders. Key nonfinancial information is disclosed to the public including risks, opportunities and ESG practices
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('PublicInformation', 's4', 4)" :class="{'active': answers.PublicInformation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[4].status}">
      <div class="group-title" @click="toggleSection(4)">
        <span class="title">
          <button>
            <span v-if="!section[4].status">+</span>
            <span v-if="section[4].status">-</span>
          </button>
          Ownership: shareholder
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s5')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s5')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s5')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s5')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s5')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Shareholder meetings & access to information</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('ShareholderMeetingsAccessToInformation', 's5', 1)" :class="{'active': answers.ShareholderMeetingsAccessToInformation === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                An annual general meeting of shareholders is held. There are clear lines of communication with all shareholders.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">                
                An annual general meeting of shareholders is held. There are clear lines of communication with all shareholders.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ShareholderMeetingsAccessToInformation', 's5', 2)" :class="{'active': answers.ShareholderMeetingsAccessToInformation === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Annual shareholders meetings include discussions of key decisions made, dividend decisions and future plans. Shareholder meetings are well-organised and function effectively to allow for adequate shareholder participation..
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">                
                Annual shareholders meetings include discussions of key decisions made, dividend decisions and future plans. Shareholder meetings are well-organised and function effectively to allow for adequate shareholder participation..
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ShareholderMeetingsAccessToInformation', 's5', 3)" :class="{'active': answers.ShareholderMeetingsAccessToInformation === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The annual general meeting is convened with an agenda and explanatory material provided sufficiently in advance for adequate consideration by shareholders.  All shareholders are regularly updated on company policy, strategy and results
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">                
                The annual general meeting is convened with an agenda and explanatory material provided sufficiently in advance for adequate consideration by shareholders.  All shareholders are regularly updated on company policy, strategy and results
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ShareholderMeetingsAccessToInformation', 's5', 4)" :class="{'active': answers.ShareholderMeetingsAccessToInformation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Shareholder rights</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('ShareholderRights', 's5', 0)" :class="{'active': answers.ShareholderRights === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There are arrangements among shareholders/family/directors/lenders that generate a disproportion between ultimate beneficial ownership and voting/control rights. Equity investors also provide debt to the company.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">                
                There are arrangements among shareholders/family/directors/lenders that generate a disproportion between ultimate beneficial ownership and voting/control rights. Equity investors also provide debt to the company.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ShareholderRights', 's5', 1)" :class="{'active': answers.ShareholderRights === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                There is equal treatment of all shareholders. There is a shareholders agreement in place.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">                
                There is equal treatment of all shareholders. There is a shareholders agreement in place.
              </md-tooltip>
            </span>
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('ShareholderRights', 's5', 3)" :class="{'active': answers.ShareholderRights === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There are clearly articulated policies on the treatment of minority shareholders and these are effectively implemented.. The company has a formal dividend policy.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">                
                There are clearly articulated policies on the treatment of minority shareholders and these are effectively implemented.. The company has a formal dividend policy.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ShareholderRights', 's5', 4)" :class="{'active': answers.ShareholderRights === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Conflicts of interest</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('ConflictsOfInterest', 's5', 0)" :class="{'active': answers.ConflictsOfInterest === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The role and responsibilities of the founder(s) are clearly established. Ultimate beneficial ownership disclosed.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">                
                The role and responsibilities of the founder(s) are clearly established. Ultimate beneficial ownership disclosed.
              </md-tooltip>
            </span>
            <span class="button-disabled"></span>
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('ConflictsOfInterest', 's5', 3)" :class="{'active': answers.ConflictsOfInterest === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There is a mechanism in place for resolution of shareholder disputes and an established mechanism to address shareholder issues
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 2">                
                There is a mechanism in place for resolution of shareholder disputes and an established mechanism to address shareholder issues
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ConflictsOfInterest', 's5', 4)" :class="{'active': answers.ConflictsOfInterest === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[5].status}">
      <div class="group-title" @click="toggleSection(5)">
        <span class="title">
          <button>
            <span v-if="!section[5].status">+</span>
            <span v-if="section[5].status">-</span>
          </button>
          Ownership: family owned companies
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s6')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s6')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s6')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s6')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s6')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Roles of family and non-family members</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('RolesOfFamilyAndNonFamilyMembers', 's6', 0)" :class="{'active': answers.RolesOfFamilyAndNonFamilyMembers === 0}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is a basic understanding of the roles of all family members
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">                
                There is a basic understanding of the roles of all family members
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('RolesOfFamilyAndNonFamilyMembers', 's6', 1)" :class="{'active': answers.RolesOfFamilyAndNonFamilyMembers === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                The difference between non-family and family issues is acknowledged
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">      
                The difference between non-family and family issues is acknowledged
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('RolesOfFamilyAndNonFamilyMembers', 's6', 2)" :class="{'active': answers.RolesOfFamilyAndNonFamilyMembers === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                There is a clear distinction between the roles of the founder(s), family members and managers. There are clear career paths for non-family executives.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">                
                There is a clear distinction between the roles of the founder(s), family members and managers. There are clear career paths for non-family executives.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('RolesOfFamilyAndNonFamilyMembers', 's6', 3)" :class="{'active': answers.RolesOfFamilyAndNonFamilyMembers === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                There are policies and mechanisms in place to regulate family member's ownership, employment and other benefits
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">   
                There are policies and mechanisms in place to regulate family member's ownership, employment and other benefits
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('RolesOfFamilyAndNonFamilyMembers', 's6', 4)" :class="{'active': answers.RolesOfFamilyAndNonFamilyMembers === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Succession planing</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button-disabled"></span>
            <span class="button" @click="toggle('SuccessionPlaning', 's6', 1)" :class="{'active': answers.SuccessionPlaning === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                There is an awareness of family succession planning
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">      
                There is an awareness of family succession planning
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('SuccessionPlaning', 's6', 2)" :class="{'active': answers.SuccessionPlaning === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Family succession is in place.  There are clear career paths for non-family executives.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">      
                Family succession is in place.  There are clear career paths for non-family executives.
              </md-tooltip>
            </span>
            <span class="button-disabled"></span>
            <span class="button na" @click="toggle('SuccessionPlaning', 's6', 4)" :class="{'active': answers.SuccessionPlaning === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised" @click="previousSection()">Previous</md-button>
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Next</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="nextSection()">Next</md-button>
    </div>
  </main>
</template>

<script>
import HeaderColumnsLowerSMECG from '../HeaderColumnsLowerSMECG';

export default {
  name: "smeCorporate",
  components: {
    HeaderColumnsLowerSMECG,
  },
  data() {
    return {
      step: 0,
      answers: [],
      review: this.$store.state.review,
      expandStatus: this.$store.state.expandStatus,
      reviewCount: this.$store.state.reviewCount,
      disabled: true,
      section: [
        { status: false, count: 0, total: 3, name: 's1' },
        { status: false, count: 0, total: 5, name: 's2' },
        { status: false, count: 0, total: 4, name: 's3' },
        { status: false, count: 0, total: 3, name: 's4' },
        { status: false, count: 0, total: 3, name: 's5' },
        { status: false, count: 0, total: 2, name: 's6' },
      ],
      sectionMedian: {
        s1: null,
        s2: null,
        s3: null,
        s4: null,
        s5: null,
        s6: null,
      },
      level: 'corporate',
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
  },
  methods: {
    toggle(key, section, index) {
      const total = this.section.filter((item) => item.name === section)[0].total;
      this.$store.commit("answerReview", {level: "corporate", type: "lower", key: key, value: index, section: section, total: total});
      const check = this.review.lower.filter((item) => item.section === section && item.level === "corporate")
      this.step = check.length;
      if (this.step === total) {
        this.section.forEach((item) => {
          item.status = false;
        });
      }
    },
    assembleAnswers() {
      this.answers = []
      this.review.lower.map((item) => {
        this.answers[item.key] = item.value;
      });
      this.getMedian();
      if (this.reviewCount.lower.corporate >= 20) {
        this.disabled = false;
      }
    },
    toggleSection(key) {
      if (this.section[key].status) {
        this.section[key].status = false;
        return false;
      }
      this.section.forEach((item) => {
        item.status = false;
      });
      this.section[key].status = !this.section[key].status;
      const check = this.review.lower.filter((item) => item.section === this.section[key].name && item.level === "corporate")
      const total = this.section[key].total;
      this.step = check.length === total ? 0 : check.length;
    },
    nextSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 2})
    },
    previousSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 0})
    },
    getMedian() {
      this.section.map((section) => {
        let total = 0
        if (this.review.lower) {
          const item = this.review.lower.filter((item) => item.section === section.name && item.level === this.level)
          if (item)
            item.map((item) => {
              total += item.value;
            });
        }
        this.sectionMedian[section.name] = parseInt(total / section.total);
      });
    },
    getLabel(section) {
      const total = this.section.filter((item) => item.name === section)[0].total
      const check = this.review.lower.filter((item) => item.section === section && item.level === this.level)
      if (check.length < total)
        return '';
      return this.sectionMedian[section];
    }
  }
}
</script>
