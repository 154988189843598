<template>
  <main>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Content</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No users with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="ID">{{item.id}}</md-table-cell>
        <md-table-cell md-label="Name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="">
          <md-button @click="edit(item)">
            <md-icon>edit</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
import router from "@/router";

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.email).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    edit(item) {
      this.$store.commit('editContent', item);
      router.push({ name: "adminContentForm" });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}content/get/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
          this.$store.commit('toogleAdminData', {content: this.data})
        });
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
}
</style>
