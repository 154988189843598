import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import MyAccount from "../views/MyAccount.vue";
import MyInvestmentsForm from "../views/myInvestments/Form.vue";
import EsgReview from "../views/EsgReview.vue";
import CreateAccount from "../views/CreateAccount.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import PasswordRecovery from "../views/PasswordRecovery.vue";
import Terms from "../views/Terms.vue";
import Overview from "../views/Overview.vue";
import Dashboard from "../views/Dashboard.vue";
import AdminUserGrid from "../views/admin/user/Grid.vue";
import AdminUserForm from "../views/admin/user/Form.vue";
import AdminFeedbacksGrid from "../views/admin/feedbacks/Grid.vue";
import AdminFeedbacksView from "../views/admin/feedbacks/View.vue";
import AdminSectors from "../views/admin/sector/Grid.vue";
import AdminCountryData from "../views/admin/country/data/Grid.vue";
import AdminCountryRisk from "../views/admin/country/risk/Grid.vue";
import AdminInvestmentType from "../views/admin/investment/type/Grid.vue";
import AdminInvestmentPs from "../views/admin/investment/ps/Grid.vue";
import AdminCiriScore from "../views/admin/ciri/Grid.vue";
import AdminTiScore from "../views/admin/ti/Grid.vue";
import AdminContent from "../views/admin/content/Grid.vue";
import AdminContentForm from "../views/admin/content/Form.vue";
import AdminQuestionnarieForm from "../views/admin/questionnarie/Form.vue";
import Disclaimer from "../views/Disclaimer.vue";
import Activate from "../views/Activate.vue";
import FAQ from "../views/FAQ.vue";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { 
      guest: true
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/password-recovery/:code",
    name: "passwordRecovery",
    component: PasswordRecovery,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/activate/:code",
    name: "activate",
    component: Activate,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/create-account",
    name: "createAccount",
    component: CreateAccount,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: Disclaimer,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: { 
      guest: true,
    },
  },
  {
    path: "/dashboard/:id",
    name: "dashboard",
    component: Dashboard,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/my-account",
    name: "myAccount",
    component: MyAccount,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/overview",
    name: "overview",
    component: Overview,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/my-investments/:type",
    name: "myInvestmentsForm",
    component: MyInvestmentsForm,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/esg-review/:id/",
    name: "esgReview",
    component: EsgReview,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/esg-review/:id/:type",
    name: "esgReviewQuestions",
    component: EsgReview,
    meta: { 
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/feedbacks",
    name: "adminFeedbacks",
    component: AdminFeedbacksGrid,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/feedbacks/:id",
    name: "adminFeedbacksView",
    component: AdminFeedbacksView,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/users",
    name: "adminUser",
    component: AdminUserGrid,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/users/:type",
    name: "adminUserForm",
    component: AdminUserForm,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/sectors",
    name: "adminSectors",
    component: AdminSectors,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/country/data",
    name: "adminCountryData",
    component: AdminCountryData,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/country/risk",
    name: "adminCountryRisk",
    component: AdminCountryRisk,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/investment/type",
    name: "adminInvestmentType",
    component: AdminInvestmentType,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/investment/ps",
    name: "adminInvestmentPs",
    component: AdminInvestmentPs,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/ciri-score",
    name: "adminCiriScore",
    component: AdminCiriScore,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/ti-score",
    name: "adminTiScore",
    component: AdminTiScore,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/content",
    name: "adminContent",
    component: AdminContent,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/content/update",
    name: "adminContentForm",
    component: AdminContentForm,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/review/:session",
    name: "adminQuestionnarieForm",
    component: AdminQuestionnarieForm,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
  {
    path: "/admin/review/:session/:step",
    name: "adminQuestionnarieForm",
    component: AdminQuestionnarieForm,
    meta: { 
      requiresAuth: true,
      is_admin : true
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user = ''
  if (window.localStorage.getItem('esg_toolkitv0.4')) {
    user = JSON.parse(atob(window.localStorage.getItem('esg_toolkitv0.4')))
    axios.post(`${window.api}verify-token?jwt=${user.token}`)
      .catch(() => {
        window.localStorage.removeItem('esg_toolkitv0.4')
        router.push({ name: "home" });
      })
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (user.name == undefined) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      if(to.matched.some(record => record.meta.is_admin)) {
        if(user.role == 1){
            next()
        } else{
            next({ name: 'overview'})
        }
      }else {
          next()
      }
    }
  } else {
      next() 
  }
});

export default router;
