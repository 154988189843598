<template>
  <div class="holder">
    <span class="title">
      <div class="md-title">
        <span :class="getClass()" v-if="values.risk">
          <md-tooltip md-direction="right">E&S category: {{ values.risk }}</md-tooltip>
        </span>
        {{values.name}}
      </div>
      <div class="md-subhead">{{values.subsector}}</div>
    </span>
    <div class="content">
      <strong>Amount</strong>: {{currency}} {{ values.amountFormat }}
    </div>
    <div class="buttons">
      <md-button @click="edit()" class="md-dense">
        <md-tooltip md-direction="right" v-html="data.content['Info: Investment edit button']"></md-tooltip>
        <md-icon>edit</md-icon>
      </md-button>
      <md-button @click="review()" class="md-dense">
        <md-tooltip md-direction="bottom" v-html="data.content['Info: Investment review button']"></md-tooltip>
        <md-icon>content_paste</md-icon>
      </md-button>
      <md-button @click="dashboard()" class="md-dense">
        <md-tooltip md-direction="left" v-html="data.content['Info: Investment dashboard button']"></md-tooltip>
        <md-icon>insert_chart_outlined</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'InvestmentItem',
  methods: {
    getClass() {
      return `bullet ${this.values.risk.toLowerCase()}`;
    },
    edit() {
      this.$store.commit('selectInvestment', this.values);
      router.push(`/my-investments/${this.values.id}/`);
    },
    review() {
      this.$store.commit("toggleSection", {type: "higher", section: 0});
      this.$store.commit('selectInvestment', this.values);
      let type = ''
      if (this.values.review) {
        const review = JSON.parse(this.values.review.values);
        type = review.higher.length > 0 ? 'quick/' : 'indepth/';
      }
      router.push(`/esg-review/${this.values.id}/${type}`)
    },
    dashboard() {
      this.$store.commit('selectInvestment', this.values);
      router.push(`/dashboard/${this.values.id}`);
    },
    setData() {
      this.values = this.item;
      this.values.stage = this.data.stages.filter((item) => item.id === this.values.investment_stage_id)[0].name;
      this.values.type = this.data.types.filter((item) => item.id === this.values.investment_type_id)[0].name;
      const sector = this.data.sectors.filter((item) => item.id === this.values.sector_id)[0];
      this.values.sector = sector.name;
      const subsector = sector.subsectors.filter((item) => item.id === this.values.subsector_id)[0];
      this.values.subsector = subsector.name;
      this.currency = this.fund.currency === 0 ? '€' : '$';
      this.currencyName = this.fund.currency === 0 ? 'eur' : 'usd';
      if (typeof this.values.amount === 'string')
        this.values.amount = JSON.parse(this.values.amount)
      this.values.amountFormat = parseInt(this.values.amount[this.currencyName]).toLocaleString(undefined, {minimumFractionDigits: 0});
      this.done = true;
    }
  },
  data() {
    return {
      values: {},
      user: this.$store.user,
      done: false,
      currency: '',
      currencyName: '',
    }
  },
  props: ["item", "data", "fund"],
  mounted() {
    this.setData();
  },
  watch: {
    fund() {
      this.setData();
    },
    item() {
      this.setData();
    },
    data() {
      this.setData();
    }
  }
}
</script>

<style lang="scss" scoped>
.holder {
  padding: 16px 0 0;
}
.md-title,
.md-subhead,
.content {
  text-align: left;
  padding: 0 16px 0 36px;
  position: relative;
  .bullet {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    left: 10px;
    &.high {
      background: #D43501;
    }
    &.medium {
      background: #FF9502;
    }
    &.low {
      background: #869C66;
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  background: rgba(0,0,0,0.1);
  margin-top: 10px;
  padding: 0 16px;
  button {
    min-width: auto;
    &.opacity {
      opacity: 0.4;
    }
  }
}
</style>