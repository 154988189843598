<template>
  <md-card class="investments">
    <md-card-header>
      <md-card-header-text v-if="investments && investments.length < 1">
        <div class="md-title">You don't have any investments yet</div>
      </md-card-header-text>
      <md-card-header-text v-if="investments && investments.length > 0">
        <div class="md-title">
          Investments <span v-if="investments">({{investments.length}})</span>
        </div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <div v-if="investments && investments.length < 1">
        <p>You can create one on the button below</p>
      </div>
      <div class="holder" v-if="list && list.length > 0">
        <Item v-for="item in list" v-bind:key="item.id" :data="data" :fund="fund" :item="item"/>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import Item from '../investments/Item';

export default {
  name: "CardInvestments",
  props: ["investments", "data", "fund"],
  data() {
    return {
      filterStatus: false,
      search: '',
      list: [],
    }
  },
  components: {
    Item
  },
  mounted() {
    this.list = this.investments;
  },
  watch: {
    search() {
      if (this.search.length < 1) {
        this.list = this.investments
        return false;
      }
      this.list = []
      this.investments.map((item) => {
        if (
          item.name.toLowerCase().search(this.search.toLowerCase()) >= 0 ||
          item.sector.toLowerCase().search(this.search.toLowerCase()) >= 0 ||
          item.subsector.toLowerCase().search(this.search.toLowerCase()) >= 0
        )
          this.list.push(item);
      })
    },
    investments() {
      this.list = this.investments;
    }
  }
}
</script>

<style lang="scss" scoped>
.investments {
  height: calc(100vh - 392px);

  @media (max-width: 1366px) {
    height: 500px;
  }
  .md-title {
    margin: 0 !important;
    position: relative;
    padding: 16px;
    background: rgba(2, 84, 149, 0.08);
    border-bottom: 1px solid rgba(2, 84, 149, 0.5);
    button {
      position: absolute;
      right: 16px;
      min-width: initial;
    }
  }
  .md-card-header {
    padding: 0;
    position: relative;
  }
  .md-card-content {
    overflow: auto;
    height: calc(100% - 64px);
    padding: 0;
  }
}
</style>