<template>
  <main>
    <div class="utils">
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
    </div>
    <md-table v-model="data" md-sort="sector" md-sort-order="asc">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Sectors</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No data with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="Sector" md-sort-by="sector">{{item['sector']}}</md-table-cell>
        <md-table-cell md-label="Sector code ISIC">{{item['sector_code']}}</md-table-cell>
        <md-table-cell md-label="Sub sector">{{item['name']}}</md-table-cell>
        <md-table-cell md-label="Sub sector code">{{item['code']}}</md-table-cell>
        <md-table-cell md-label="PS 2 relevance">{{item['PS 2 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 3 relevance">{{item['PS 3 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 4 relevance">{{item['PS 4 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 5 relevance">{{item['PS 5 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 6 relevance">{{item['PS 6 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 7 relevance">{{item['PS 7 relevance']}}</md-table-cell>
        <md-table-cell md-label="PS 8 relevance">{{item['PS 8 relevance']}}</md-table-cell>
        <md-table-cell md-label="Internal references/ posiiton statmenets">{{item['Internal references/ posiiton statmenets']}}</md-table-cell>	
        <md-table-cell md-label="Exclusion list">{{item['Exclusion list']}}</md-table-cell>	
        <md-table-cell md-label="E & S risk category">{{item['E & S risk category']}}</md-table-cell>	
        <md-table-cell md-label="Working Conditions and OHS">{{item['Working Conditions and OHS']}}</md-table-cell>	
        <md-table-cell md-label="Exposure to hazardous waste/materials">{{item['Exposure to hazardous waste/materials']}}</md-table-cell>	
        <md-table-cell md-label="Contract violations and labour standards">{{item["Contract violations and labour standards"]}}</md-table-cell>	
        <md-table-cell md-label="Child and forced labour">{{item["Child and forced labour"]}}</md-table-cell>	
        <md-table-cell md-label="Worker relationship">{{item["Worker relationship"]}}</md-table-cell>	
        <md-table-cell md-label="Supply Chain Issues">{{item["Supply Chain Issues"]}}</md-table-cell>	
        <md-table-cell md-label="Contractors, Third Parties, and Temporary Workers">{{item["Contractors, Third Parties, and Temporary Workers"]}}</md-table-cell>	
        <md-table-cell md-label="Indirect risks: Polluting activities">{{item["Indirect risks: Polluting activities"]}}</md-table-cell>	
        <md-table-cell md-label="Pesticide use">{{item["Pesticide use"]}}</md-table-cell>	
        <md-table-cell md-label="Soil & Groundwater">{{item["Soil & Groundwater"]}}</md-table-cell>	
        <md-table-cell md-label="Historical pollution">{{item["Historical pollution"]}}</md-table-cell>	
        <md-table-cell md-label="Air Emissions (and Ambient Air Quality)">{{item["Air Emissions (and Ambient Air Quality)"]}}</md-table-cell>	
        <md-table-cell md-label="Radioactivity">{{item["Radioactivity"]}}</md-table-cell>	
        <md-table-cell md-label="Energy use">{{item["Energy use"]}}</md-table-cell>	
        <md-table-cell md-label="Water use, Wastewater (and Ambient Water Quality), Water pollution">{{item["Water use, Wastewater (and Ambient Water Quality), Water pollution"]}}</md-table-cell>	
        <md-table-cell md-label="Hazardous Materials">{{item["Hazardous Materials"]}}</md-table-cell>	
        <md-table-cell md-label="Waste">{{item["Waste"]}}</md-table-cell>	
        <md-table-cell md-label="Other forms of pollution">{{item["Other forms of pollution"]}}</md-table-cell>	
        <md-table-cell md-label="GHG emissions / Climate change">{{item["GHG emissions / Climate change"]}}</md-table-cell>	
        <md-table-cell md-label="Environmental and Natural resources issues (rel to communities)">{{item["Environmental and Natural resources issues (rel to communities)"]}}</md-table-cell>	
        <md-table-cell md-label="Security personnel">{{item["Security personnel"]}}</md-table-cell>	
        <md-table-cell md-label="Infrastructure and equipment safety Hazardous Materials Safety">{{item["Infrastructure and equipment safety Hazardous Materials Safety"]}}</md-table-cell>	
        <md-table-cell md-label="Transport of Hazardous Materials / Traffic Safety">{{item["Transport of Hazardous Materials / Traffic Safety"]}}</md-table-cell>	
        <md-table-cell md-label="Exposure to disease / Disease Prevention">{{item["Exposure to disease / Disease Prevention"]}}</md-table-cell>	
        <md-table-cell md-label="Check whether investment involves Physical or Economic Displacement, Consultation and/or Grievance requirements.  If yes, PS 5 is applicable">{{item["Check whether investment involves Physical or Economic Displacement, Consultation and/or Grievance requirements.  If yes, PS 5 is applicable"]}}</md-table-cell>	
        <md-table-cell md-label="Physical or Economic displacement">{{item["Physical or Economic displacement"]}}</md-table-cell>	
        <md-table-cell md-label="Land acquisition and involuntary resettlement">{{item["Land acquisition and involuntary resettlement"]}}</md-table-cell>	
        <md-table-cell md-label="Animal welfare">{{item["Animal welfare"]}}</md-table-cell>	
        <md-table-cell md-label="Legally protected Areas">{{item["Legally protected Areas"]}}</md-table-cell>	
        <md-table-cell md-label="(Critical) habitat">{{item["(Critical) habitat"]}}</md-table-cell>	
        <md-table-cell md-label="Biodiversity and habitat">{{item["Biodiversity and habitat"]}}</md-table-cell>	
        <md-table-cell md-label="Supply chain issues">{{item["Supply chain issues"]}}</md-table-cell>	
        <md-table-cell md-label="Check whether indigenous people">{{item["Check whether indigenous people"]}}</md-table-cell>	
        <md-table-cell md-label="Impact on traditional or customary lands">{{item["Impact on traditional or customary lands"]}}</md-table-cell>	
        <md-table-cell md-label="Relocation of Indigenous people / Involuntary resettlement">{{item["Relocation of Indigenous people / Involuntary resettlement"]}}</md-table-cell>	
        <md-table-cell md-label="Check whether cultural heritage objects are present in area of influence of project. If yes, PS 8 is applicable">{{item["Check whether cultural heritage objects are present in area of influence of project. If yes, PS 8 is applicable"]}}</md-table-cell>	
        <md-table-cell md-label="Customer/patient welfare">{{item["Customer/patient welfare"]}}</md-table-cell>	
        <md-table-cell md-label="Fair marketing and advertising">{{item["Fair marketing and advertising"]}}</md-table-cell>	
        <md-table-cell md-label="Opportunity a">{{item["Opportunity a"]}}</md-table-cell>	
        <md-table-cell md-label="Opportunity b">{{item["Opportunity b"]}}</md-table-cell>	
        <md-table-cell md-label="Opportunity c">{{item["Opportunity c"]}}</md-table-cell>	
        <md-table-cell md-label="Opportunity d">{{item["Opportunity d"]}}</md-table-cell>	
        <md-table-cell md-label="Opportunity level">{{item["Opportunity level"]}}</md-table-cell>	
        <md-table-cell md-label="CDC Guideline 1">{{item["CDC Guideline 1"]}}</md-table-cell>	
        <md-table-cell md-label="CDC Guideline 1 hyperlink">{{item["CDC Guideline 1 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="CDC Guideline 2">{{item["CDC Guideline 2"]}}</md-table-cell>	
        <md-table-cell md-label="CDC Guideline 2 hyperlink">{{item["CDC Guideline 2 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 1">{{item["WBG Guideline 1"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 1 hyperlink">{{item["WBG Guideline 1 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 2">{{item["WBG Guideline 2"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 2 hyperlink">{{item["WBG Guideline 2 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 3">{{item["WBG Guideline 3"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 3 hyperlink">{{item["WBG Guideline 3 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 4">{{item["WBG Guideline 4"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 4 hyperlink">{{item["WBG Guideline 4 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 5">{{item["WBG Guideline 5"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 5 hyperlink">{{item["WBG Guideline 5 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 6">{{item["WBG Guideline 6"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 6 hyperlink">{{item["WBG Guideline 6 hyperlink"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 7">{{item["WBG Guideline 7"]}}</md-table-cell>	
        <md-table-cell md-label="WBG Guideline 7 hyperlink">{{item["WBG Guideline 7 hyperlink"]}}</md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.sector).includes(toLower(term)) || toLower(item.code).includes(toLower(term)) || toLower(item.sector_code).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/sector/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}data/sectors/grid/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
