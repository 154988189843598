<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-field :class="getValidationClass('name')">
        <label for="name">Name</label>
        <md-input
          type="text"
          id="name"
          required
          v-model="form.name"/>
        <span class="md-error" v-if="!$v.form.name.required">The name is required</span>
      </md-field>
      <md-field :class="getValidationClass('email')">
        <label for="email">Email</label>
        <md-input
          type="email"
          id="email"
          required
          v-model="form.email"/>
        <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
        <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
      </md-field>
      <md-field>
        <label for="password">Password</label>
        <md-input
          type="password"
          id="password"
          v-model="form.password"/>
      </md-field>
      <md-field :class="getValidationClass('role')">
        <label>Role</label>
        <md-select v-model="form.role" required>
          <md-option value="0">Usuário</md-option>
          <md-option value="1">Admin</md-option>
        </md-select>
        <span class="md-error" v-if="!$v.form.role.required">The role is required</span>
      </md-field>
      <md-field>
        <label>Status</label>
        <md-select v-model="form.status" required>
          <md-option value="1">Ativo</md-option>
          <md-option value="0">Inativo</md-option>
        </md-select>
      </md-field>
      <div class="md-layout-item">
        <md-button to="/admin/users" class="back">Back</md-button>
        <md-button type="submit" class="md-primary md-raised save">{{ button }}</md-button>
      </div>
      <md-snackbar
        md-position="center"
        :md-active.sync="showSnackbar"
        md-persistent>
        <span>{{ message }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import router from "@/router";

export default {
  name: "Form",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
        name: '',
        role: '',
        status: 0,
      },
      button: parseInt(this.$route.params.type) > 0 ? 'Update' : 'Create',
      message: "",
      showSnackbar: false,
      users: this.$store.state.admin.users,
      user: this.$store.state.user,
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      role: {
        required,
      },
    },
  },
  mounted() {
    if (parseInt(this.$route.params.type) > 0) {
      this.$store.commit('toggleLoading')
      if (this.users) {
        axios
          .get(`${window.api}users/?jwt=${this.user.token}`)
          .then(response => {
            const user = response.data.filter((item) => item.id === parseInt(this.$route.params.type));
            this.form = user[0]
            this.form.password = '';
            this.$store.commit('toggleLoading')
          })
      } else {
        const user = this.users.filter((item) => item.id === parseInt(this.$route.params.type));
        this.form = user[0]
        this.form.password = '';
        this.$store.commit('toggleLoading')
      }
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.save()
      }
    },
    save() {
      this.$store.commit('toggleLoading')
      if (this.form.id) {
        axios
          .post(`${window.api}user/edit/${this.form.id}/?jwt=${this.user.token}`, this.form)
          .then(() => {
            this.$store.commit('toggleLoading')
            this.message = "User updated";
            this.showSnackbar = true;
            router.push({ name: "adminUser" });
          })
          .catch(() => {
            this.$store.commit('toggleLoading')
            this.message = "Something went wrong, try again later.";
            this.showSnackbar = true;
          });
      } else {
        axios
          .post(`${window.api}user/admin/create/?jwt=${this.user.token}`, this.form)
          .then(() => {
            this.$store.commit('toggleLoading')
            this.message = "User created";
            this.showSnackbar = true;
            this.form.password = this.form.role = this.form.email = this.form.name = null;
            this.form.status = 0;
            this.$v.$reset()
          })
          .catch(() => {
            this.$store.commit('toggleLoading')
            this.message = "Something went wrong, try again later.";
            this.showSnackbar = true;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.back {
  float: left;
}
.save {
  float: right;
}
</style>
