<template>
  <md-card id="investments-sector" :class="{'hideText': !selectedLabel}" v-on:resize="setChart()">
    <md-card-header>
      <div class="md-title">Investments per Sector</div>
      <div class="utils">
        <md-button v-if="selectedLabel" @click="back" class="md-raised back">Back</md-button>
        <md-icon>
          info
          <md-tooltip v-if="data.content" v-html="data.content['Info: Investments per Sector']" md-direction="top"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content v-if="chartDataSector[0]">
      <div class="holder">
        <vue-plotly :autoResize="true" :options="options" :data="chartDataSector" v-on:click="drilldown" :layout="layout" />
      </div>
    </md-card-content>
  </md-card>
</template>

<style lang="scss">
#investments-sector {
  position: relative;
  .md-card-header {
    background: rgba(2, 84, 149, 0.08);
    border-bottom: 1px solid rgba(2, 84, 149, 0.5);
    display: flex;
    justify-content: space-between;
    .utils {
      display: flex;
      align-items: center;
      i {
        margin-left: 10px;
      }
    }
  }
  .md-card-content {
    padding-top: 20px;
    min-height: 194px;
  }
  .holder {
    position: relative;
    button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .surface {
    marker-start: url(#arrow);
    marker-end: url(#arrow);
  }
  .textline {
    display: none;
  }
  &.hideText .slicetext text {
    fill: none !important;
  }
  span {
    position: absolute;
    width: 14px;
    height: 12px;
    display: block;
    border: 1px solid #000;
    z-index: -1;
    &.t {
      border-width: 1px 0 0 0;
    }
    &.tr {
      border-width: 1px 1px 0 0;
    }
    &.tl {
      border-width: 1px 0 0 1px;
    }
    &.br {
      border-width: 0 1px 1px 0;
    }
    &.bl {
      border-width: 0 0 1px 1px;
    }
  }
  img {
    position: absolute;
    width: 30px;
    z-index: -1;
  }
}
</style>

<script>
import VuePlotly from '@statnett/vue-plotly'

export default {
  name: "CardPieSector",
  props: ["data", "investments"],
  components: {
    VuePlotly
  },
  mounted() {
    this.setChart();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  watch: {
    windowWidth() {
      this.setChart();
    },
    investments() {
      this.setChart();
    },
    selectedLabel() {
      if (this.selectedLabel) {
        this.chartDataSector = []
        const names = []
        const texts = []
        const values = []
        this.investments.map((item) => {
          if (item.sector_name === this.selectedLabel) {
            if (names.indexOf(item.subsector_name) < 0) {
              names.push(item.subsector_name);
              texts.push(item.subsector_name.length > 20 ? item.subsector_name.substr(0, 20) + '...' : item.subsector_name);
              values.push(0)
            }
            const index = names.indexOf(item.subsector_name)
            values[index] = values[index] + 1;
          }
        })
        this.chartDataSector.push({
          type: "pie",
          text: texts,
          labels: names,
          hole: 0.6,
          textinfo: 'text',
          hoverinfo: 'label',
          marker: {
            colors: this.shadesOfBlue
          },
          textposition: "outside",
          values: values
        });
      }
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    drilldown(event) {
      if (!this.selectedLabel) {
        this.selectedLabel = event.points[0].text;
      }
    },
    back() {
      this.selectedLabel = null;
      this.setChart();
    },
    setChart() {
      document.querySelectorAll('#investments-sector img').forEach((item) => item.remove())
      document.querySelectorAll('#investments-sector span').forEach((item) => item.remove())
      if (!this.investments)
        return false;
      this.chartDataSector = [];
      if (this.investments.length > 0) {
        this.getData();
      } else {
        this.chartDataSector.push({
          type: "pie",
          labels: 'No data',
          hole: 0.6,
          textinfo: "none",
          hoverinfo: "none",
          textposition: "outside",
          texttemplate: "",
          marker: {
            colors: ["#ddd"]
          },
          values: [1]
        });
      }
    },
    getData() {
      const texts = [];
      const labels = [];
      const values = [];
      this.investments.map((item) => {
        if (labels.indexOf(item.sector_name) < 0) {
          texts.push(`${item.sector_name}`);
          labels.push(item.sector_name);
          values.push(0)
        }
        const index = labels.indexOf(item.sector_name)
        values[index] = values[index] + 1;
      })
      labels.map((item, index) => {
        labels[index] = `${item} (${values[index]})`;
      })
      this.chartDataSector.push({
        type: "pie",
        text: texts,
        labels: labels,
        hole: 0.6,
        textinfo: "text",
        hoverlabel: {
          namelength: -1,
          bgcolor: "#FFF"
        },
        hoverinfo: "label",
        textposition: "outside",
        marker: {
          colors: this.shadesOfBlue
        },
        values: values
      });
      let interval = setInterval(() => {
        if (document.querySelectorAll('#investments-sector text')) {
          let labels = document.querySelectorAll('#investments-sector text');
          document.querySelectorAll('#investments-sector img').forEach((item) => item.remove())
          document.querySelectorAll('#investments-sector span').forEach((item) => item.remove())
          for (const item of labels) {
            const img = document.createElement('img');
            const span = document.createElement('span');
            img.src = `/images/icons/${item.getAttribute('data-unformatted').split('|')[0]}.svg`;
            const pos = item.getBoundingClientRect();
            if (pos.y > 220) {
              img.style.top = `${pos.y - 60}px`;
              if (pos.x > 220) {
                span.style.top = `${pos.y - 60}px`;
                span.style.left = `${(pos.x) - 10}px`;
                span.classList.add('bl');
              } else {
                span.style.top = `${pos.y - 60}px`;
                span.style.left = `${(pos.x + pos.width) - 24}px`;
                span.classList.add('br');
              }
            } else if(pos.y < 220 && pos.y > 180) {
              if (pos.x > 220) {
                span.style.top = `${pos.y - 55}px`;
                span.style.left = `${(pos.x) - 10}px`;
              } else {
                span.style.top = `${pos.y - 55}px`;
                span.style.left = `${(pos.x + pos.width) - 24}px`;
              }
              span.classList.add('t');
              img.style.top = `${pos.y - 70}px`;
            } else {
              if (pos.x > 220) {
                span.style.top = `${pos.y - 60}px`;
                span.style.left = `${(pos.x) - 10}px`;
                span.classList.add('tl');
              } else {
                span.style.top = `${pos.y - 60}px`;
                span.style.left = `${(pos.x + pos.width) - 24}px`;
                span.classList.add('tr');
              }
              img.style.top = `${pos.y - 70}px`;
            }
            if (pos.x > 220)
              img.style.left = `${pos.x + 5}px`;
            else 
              img.style.left = `${(pos.x + pos.width) - 55}px`;

            document.getElementById('investments-sector').appendChild(img);
            document.getElementById('investments-sector').appendChild(span);
          }
          clearInterval(interval);
        }
      }, 500);
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      options: {
        autosizable: true,
        responsive: true
      },
      selectedLabel: null,
      chartDataSector: [],
      shadesOfBlue: ["#c1f0f6", "#39b7cd", "#c3e4ed", "#63d1f4", "#00688b", "#009acd", "#00bfff", "#33a1c9", "#38b0de", "#38b0de", "#67c8ff", "#87cefa", "#67c8ff"],
      layout: {
        height: 150,
        paper_bgcolor: "transparent",
        margin: {"t": 20, "b": 20, "l": 0, "r": 0},
        showlegend: false
      },
    }
  }
}
</script>