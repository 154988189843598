<template>
  <main>
    <HeaderColumnsHigher />
    <div class="block" :class="{'active': step === 0}">
      <span class="title">
        <span>Policy</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Policy', 's1', 0)" :class="{'active': answers.Policy === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Policy', 's1', 1)" :class="{'active': answers.Policy === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            Limited or no E&S policies in place. No E&S standards adopted. Sporadic, conflicting or confusing procedures.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            Limited or no E&S policies in place. No E&S standards adopted. Sporadic, conflicting or confusing procedures.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Policy', 's1', 2)" :class="{'active': answers.Policy === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Policies and procedures in place meeting selected E&S standards. Sporadic communication, implementation and review.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            Policies and procedures in place meeting selected E&S standards. Sporadic communication, implementation and review.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Policy', 's1', 3)" :class="{'active': answers.Policy === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Full set of E&S policies, procedures and records, centrally maintained and routinely reviewed. Wide awareness in company.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            Full set of E&S policies, procedures and records, centrally maintained and routinely reviewed. Wide awareness in company.
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 1}">
      <span class="title">
        <span>ESG Risk and Opportunity Identification</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('ESGRiskOpportunityIdentification', 's2', 0)" :class="{'active': answers.ESGRiskOpportunityIdentification === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('ESGRiskOpportunityIdentification', 's2', 1)" :class="{'active': answers.ESGRiskOpportunityIdentification === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            No, or basic, identification and assessment of E&S risks and impacts, but limited to a few activities.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            No, or basic, identification and assessment of E&S risks and impacts, but limited to a few activities.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ESGRiskOpportunityIdentification', 's2', 2)" :class="{'active': answers.ESGRiskOpportunityIdentification === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Awareness and engagement of staff in identification and prioritization of E&S risks and impacts. External experts involved as required. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            Awareness and engagement of staff in identification and prioritization of E&S risks and impacts. External experts involved as required. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ESGRiskOpportunityIdentification', 's2', 3)" :class="{'active': answers.ESGRiskOpportunityIdentification === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Systematic, documented identification and prioritization of E&S risks and impacts, routinely reviewed and updated as part of a continual improvement plan. Internal and external inputs. Procedures extended to contractors, subcontractors, third parties and supply chain as relevant. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            Systematic, documented identification and prioritization of E&S risks and impacts, routinely reviewed and updated as part of a continual improvement plan. Internal and external inputs. Procedures extended to contractors, subcontractors, third parties and supply chain as relevant. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 2}">
      <span class="title">
        <span>Organisational Capacity and Competency</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('OrganisationalCapacityandCompetency', 's3', 0)" :class="{'active': answers.OrganisationalCapacityandCompetency === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('OrganisationalCapacityandCompetency', 's3', 1)" :class="{'active': answers.OrganisationalCapacityandCompetency === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            No assigned staff with E&S management responsibilities. No, or limited, awareness and E&S roles and responsibilities starting to get defined.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            No assigned staff with E&S management responsibilities. No, or limited, awareness and E&S roles and responsibilities starting to get defined.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('OrganisationalCapacityandCompetency', 's3', 2)" :class="{'active': answers.OrganisationalCapacityandCompetency === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            All levels of the company are involved in awareness training. E&S roles and responsibilities are assigned and part of daily operations. E&S staff is trained and competent. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            All levels of the company are involved in awareness training. E&S roles and responsibilities are assigned and part of daily operations. E&S staff is trained and competent. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('OrganisationalCapacityandCompetency', 's3', 3)" :class="{'active': answers.OrganisationalCapacityandCompetency === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            All levels of the company are trained, and E&S is managed as an integrated system. Management commitment is reflected in resources devoted to E&S management and training. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            All levels of the company are trained, and E&S is managed as an integrated system. Management commitment is reflected in resources devoted to E&S management and training. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 3}">
      <span class="title">
        <span>Processes</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Processes', 's4', 0)" :class="{'active': answers.Processes === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Processes', 's4', 1)" :class="{'active': answers.Processes === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            No, or few, monitoring plans. Sole intention is to satisfy regulatory requirements. No formal review activities.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            No, or few, monitoring plans. Sole intention is to satisfy regulatory requirements. No formal review activities.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Processes', 's4', 2)" :class="{'active': answers.Processes === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Routine review of monitoring and supervision activities, including participation of workers. Corrective actions routinely implemented. An E&S internal audit plan is in place. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            Routine review of monitoring and supervision activities, including participation of workers. Corrective actions routinely implemented. An E&S internal audit plan is in place. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Processes', 's4', 3)" :class="{'active': answers.Processes === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Monitoring, supervising and auditing activities are integrated and included in management review. Senior management receives periodic reports about E&S performance and progress toward E&S objectives and targets. All key project decisions consider E&S.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            Monitoring, supervising and auditing activities are integrated and included in management review. Senior management receives periodic reports about E&S performance and progress toward E&S objectives and targets. All key project decisions consider E&S.
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 4}">
      <span class="title">
        <span>Performance Management and Monitoring</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('PerformanceManagementMonitoring', 's5', 0)" :class="{'active': answers.PerformanceManagementMonitoring === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('PerformanceManagementMonitoring', 's5', 1)" :class="{'active': answers.PerformanceManagementMonitoring === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            No, or limited/informal programs or activities to mitigate E&S impacts. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            No, or limited/informal programs or activities to mitigate E&S impacts. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('PerformanceManagementMonitoring', 's5', 2)" :class="{'active': answers.PerformanceManagementMonitoring === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Actions/activities in place to manage E&S risks and impacts. Measurable company-wide objectives and targets. Periodic review and update.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            Actions/activities in place to manage E&S risks and impacts. Measurable company-wide objectives and targets. Periodic review and update.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('PerformanceManagementMonitoring', 's5', 3)" :class="{'active': answers.PerformanceManagementMonitoring === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Routine, consistent implementation of actions/activities to proactively manage E&S risks and impacts. Significant improvements in E&S performance. Demonstrated commitment to continual improvement using annual improvement plans. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            Routine, consistent implementation of actions/activities to proactively manage E&S risks and impacts. Significant improvements in E&S performance. Demonstrated commitment to continual improvement using annual improvement plans. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 5}">
      <span class="title">
        <span>Reporting</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Reporting', 's6', 0)" :class="{'active': answers.Reporting === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Reporting', 's6', 1)" :class="{'active': answers.Reporting === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            Minimal amounts of communication and reporting is provided, usually or entirely initiated by investors rather than the company itself. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            Minimal amounts of communication and reporting is provided, usually or entirely initiated by investors rather than the company itself. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Reporting', 's6', 2)" :class="{'active': answers.Reporting === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Reporting procedures are in place, mostly with a reactive and minimum (as opposed to comprehensive) approach. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            Reporting procedures are in place, mostly with a reactive and minimum (as opposed to comprehensive) approach. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Reporting', 's6', 3)" :class="{'active': answers.Reporting === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Regular reporting occurs, is comprehensive in nature, evaluates past and current performance. Ad hoc reporting is provided when warranted, and with minimum delay. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            Regular reporting occurs, is comprehensive in nature, evaluates past and current performance. Ad hoc reporting is provided when warranted, and with minimum delay. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 6}">
      <span class="title">
        <span>Emergency Response and Preparedness</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('EmergencyResponsePreparedness', 's7', 0)" :class="{'active': answers.EmergencyResponsePreparedness === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('EmergencyResponsePreparedness', 's7', 1)" :class="{'active': answers.EmergencyResponsePreparedness === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            Individual parts of an Emergency Plan in place, but low awareness and no signs of implementation and rehearsals.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 6 && !expandStatus">
            Individual parts of an Emergency Plan in place, but low awareness and no signs of implementation and rehearsals.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('EmergencyResponsePreparedness', 's7', 2)" :class="{'active': answers.EmergencyResponsePreparedness === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Most parts of an Emergency Plan in place. Likely scenarios and crisis events described. Some awareness and occasional rehearsals. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 6 && !expandStatus">
            Most parts of an Emergency Plan in place. Likely scenarios and crisis events described. Some awareness and occasional rehearsals. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('EmergencyResponsePreparedness', 's7', 3)" :class="{'active': answers.EmergencyResponsePreparedness === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Comprehensive Emergency Plan in place. Likely scenarios and crisis events described, and specific responses to each developed, to ensure business continuity. Widely communicated and scheduled or regular rehearsals. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 6 && !expandStatus">
            Comprehensive Emergency Plan in place. Likely scenarios and crisis events described, and specific responses to each developed, to ensure business continuity. Widely communicated and scheduled or regular rehearsals. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Next</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="nextSection()">Next</md-button>
    </div>
  </main>
</template>

<script>
import HeaderColumnsHigher from '../HeaderColumnsHigher';

export default {
  name: "esms",
  components: {
    HeaderColumnsHigher,
  },
  data() {
    return {
      step: 0,
      disabled: true,
      expandStatus: this.$store.state.expandStatus,
      answers: [],
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
    const check = this.review.higher.filter((item) => item.level === "esms")
    this.step = check.length;
    if (this.step >= 6)
      this.step = 0;
  },
  methods: {
    toggle(key, section, index) {
      this.$store.commit("answerReview", {level: "esms", type: "higher", key: key, value: index, section: section});
      const check = this.review.higher.filter((item) => item.level === "esms")
      this.step = check.length;
    },
    assembleAnswers() {
      this.answers = []
      if (this.review.higher) {
        this.review.higher.map((item) => {
          this.answers[item.key] = item.value;
        });
      }
      if (this.reviewCount.higher.esms >= 7) {
        this.disabled = false;
      }
    },
    nextSection() {
      this.$store.commit("toggleSection", {type: "higher", section: 1})
    }
  }
}
</script>
