<template>
  <div class="button">
    <div>
      <span class="text" @click="Edit('title', item.title)" v-html="item.title"></span>
      <md-tooltip md-direction="bottom">Click here to edit the text</md-tooltip>
    </div>
    <hr />
    <div>
      <span class="text" @click="Edit('text', item.text)" v-html="item.text"></span>
      <md-tooltip md-direction="bottom">Click here to edit the definition</md-tooltip>
    </div>
    <md-dialog :md-active.sync="edit">
      <md-dialog-title>
        <span>Editing</span>
      </md-dialog-title>
      <md-dialog-content>
        <ckeditor v-if="editor" id="edit" :editor="editor" v-model="value"></ckeditor>
        <md-button @click="edit = false">Close</md-button>
        <md-button @click="Save">Save</md-button>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "Option",
  props: ['item'],
  data() {
    return {
      edit: false,
      editor: null,
      field: null,
      value: "",
      user: this.$store.state.user,
    };
  },
  methods: {
    Edit(field, text) {
      this.field = field;
      this.value = text;
      this.edit = true;
    },
    Save() {
      this.item[this.field] = this.value;
      this.edit = false;
      axios
        .post(`${window.api}questionnaire/option/${this.item.id}/update/?jwt=${this.user.token}`, this.item);
    }
  },
  mounted() {
    this.editor = ClassicEditor;
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  margin: 0 10px;
  .text {
    background: rgba(0, 0, 0, 0.02);
    min-height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
