<template>
  <div>
    <div class="questions">
      <SlickList lockAxis="y" v-model="questions" :useDragHandle="true" @input="Reorder(questions)">
        <SlickItem v-for="(item, index) in questions" :index="item.order" v-bind:key="index">
          <QuestionQuick :item="item"/>
          <div class="utils">
            <md-button v-handle class="move">
              <md-icon>unfold_more</md-icon>
              <md-tooltip md-direction="right">Reorder question</md-tooltip>
            </md-button>
            <md-button class="delete" @click="Delete(index, item.id)">
              <md-icon>delete</md-icon>
              <md-tooltip md-direction="left">Remove question</md-tooltip>
            </md-button>
          </div>
        </SlickItem>
      </SlickList>
    </div>
    <button class="md-button full md-raised" @click="Add">Add question</button>
  </div>
</template>

<script>
import axios from "axios";
import { HandleDirective } from 'vue-slicksort';
import { SlickList, SlickItem } from 'vue-slicksort';
import QuestionQuick from './Question-quick';

export default {
  props: ['session', 'step'],
  directives: { handle: HandleDirective },
  components: {
    SlickItem,
    SlickList,
    QuestionQuick,
  },
  name: "Session",
  data() {
    return {
      questions: [],
      user: this.$store.state.user,
    };
  },
  mounted() {
    axios
      .get(`${window.api}questionnaire/get/${this.session}/${this.step}/?jwt=${this.user.token}`)
      .then((response) => {
        this.questions = response.data;
      });
  },
  methods: {
    Add() {
      axios
        .get(`${window.api}questionnaire/create/quick/${this.step}/?jwt=${this.user.token}`)
        .then((response) => {
          this.questions = response.data;
        })
    },
    Delete(index, id) {
      axios
        .get(`${window.api}questionnaire/${id}/remove/?jwt=${this.user.token}`)
      this.questions.splice(index, 1);
    },
    Reorder(items) {
      const newOrder = []
      items.map((item, index) => {
        newOrder.push({'id': item.id, 'order': index})
      })
      axios
        .post(`${window.api}questionnaire/update/order/?jwt=${this.user.token}`, {items: newOrder});
    },
  }
};
</script>

<style lang="scss" scoped>
.questions {
  margin-top: 30px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
.utils {
  min-width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.1);
}
.full {
  width: 100%;
  margin-top: 40px;
}
</style>
