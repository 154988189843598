<template>
  <main id="app-holder">
    <Bar />
    <div class="wrapper">
      <div class="breadcrumbs">
        <router-link to="/my-investments">Home</router-link> / My Account
      </div>
      <Form />
    </div>
  </main>
</template>

<script>
import Form from './../components/account/Form';
import Bar from './../components/commom/Bar';

export default {
  name: "MyAccount",
  components: {
    Bar,
    Form,
  },
};
</script>
