<template>
  <main>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Feedbacks</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No feedbacks with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="ID">{{item.id}}</md-table-cell>
        <md-table-cell md-label="Title">{{item.title}}</md-table-cell>
        <md-table-cell md-label="Name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="E-mail">{{item.email}}</md-table-cell>
        <md-table-cell md-label="Type">{{item.type === 1 ? 'Idea' : 'Bug'}}</md-table-cell>
        <md-table-cell md-label="">
          <md-button :to="`/admin/feedbacks/${item.id}`">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button @click="remove(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.email).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    remove(id) {
      if ( confirm('Do you want to remove this feedback?') ) {
        this.$store.commit('toggleLoading')
        axios
          .get(`${window.api}feedbacks/${id}/delete/?jwt=${this.user.token}`)
          .then(() => {
            this.$store.commit('toggleLoading')
            this.getData();
          });
      }
    },
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}feedbacks/?jwt=${this.user.token}`)
        .then(response => {
          response.data = response.data.reverse()
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
          this.$store.commit('toogleAdminData', {feedbacks: this.data})
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
