<template>
  <div>
    <md-button @click="modalStatus = true" class="md-fab md-plain md-fab-bottom-right md-fixed">
      <md-icon>feedback</md-icon>
    </md-button>

    <md-dialog :md-active.sync="modalStatus">
      <md-dialog-title>Give us your feedback!</md-dialog-title>

      <md-tabs md-dynamic-height>
        <md-tab md-label="Add new idea">
          <div class="md-layout md-gutter">
            <FeedbackIdea />
          </div>
        </md-tab>

        <md-tab md-label="Report a Bug">
          <div class="md-layout md-gutter">
            <FeedbackBug />
          </div>
        </md-tab>
      </md-tabs>
    </md-dialog>
  </div>
</template>

<script>
import FeedbackBug from './feedback/Bug';
import FeedbackIdea from './feedback/Idea';

export default {
  name: 'Feedback',
  components: {
    FeedbackIdea,
    FeedbackBug
  },
  data() {
    return {
      modalStatus: false,
    };
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10000;
  .holder {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -5px 0 0 -15px;
    .dot-flashing {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
      &::before,
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
      }
      &::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }
      &::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>