<template>
  <md-card>
    <md-card-header>
      <div class="md-title">ESG Management Score over time</div>
      <div class="utils">
        <md-icon>
          info
          <md-tooltip v-if="data.content" v-html="data.content['Info: ESG Management Score over time']" md-direction="top"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content>
      <div v-if="this.item.review">
        <vue-plotly :autoResize="true" :options="options" :data="chartData" :layout="layout" />
      </div>
      <div v-if="!this.item.review">
        <p>
          This investment doesn't have a review yet.
        </p>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly'

export default {
  name: "CardScoreOverTime",
  components: {
    VuePlotly
  },
  props: ["item", "data"],
  data() {
    return {
      options: {
        autosizable: true,
        responsive: true
      },
      chartData: [{
        x: [],
        y: [],
        name: 'E&S',
        type: 'scatter',
        hoverinfo: 'name+y',
      },{
        x: [],
        y: [],
        name: 'CG',
        type: 'scatter',
        hoverinfo: 'name+y',
      },{
        x: [],
        y: [],
        name: 'BI',
        type: 'scatter',
        hoverinfo: 'name+y',
      }],
      layout: {
        height: 250,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 30, "l": 20, "r": 0},
        showlegend: false,
        yaxis: {
          range: [0, 105],
        },
      }
    }
  },
  watch: {
    item() {
      this.getData();
    }
  },
  methods: {
    getData() {
      if (!this.item.reviews)
        return;
      const reviews = this.item.reviews;
      reviews.map((item) => {
        const date = new Date(item.created_at);
        this.chartData[0].x.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        this.chartData[0].y.push(parseInt(item.reviewScore.esms.total));
        this.chartData[1].x.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        this.chartData[1].y.push(parseInt(item.reviewScore.corporate.total));
        this.chartData[2].x.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
        this.chartData[2].y.push(parseInt(item.reviewScore.bi.total));
      })
    }
  },
  mounted() {
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  display: flex;
  justify-content: space-between;
  .utils {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
  }
}
.md-card-content {
  padding-top: 20px;
  height: 280px;
}
</style>