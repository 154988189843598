import { render, staticRenderFns } from "./CardBubble.vue?vue&type=template&id=7ca6e1d0&scoped=true&"
import script from "./CardBubble.vue?vue&type=script&lang=js&"
export * from "./CardBubble.vue?vue&type=script&lang=js&"
import style0 from "./CardBubble.vue?vue&type=style&index=0&id=7ca6e1d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca6e1d0",
  null
  
)

export default component.exports