<template>
  <main id="app-holder">
    <Bar />
    <div v-if="done">
      <div class="line">
        <div class="holder">
          <div class="column single">
            <CardId :item="investment" />
            <CardRisk :data="data" :item="investment" />
            <CardEconomic :item="investment" />
            <CardSectorOpportunities :data="data" :item="investment" />
          </div>
          <div class="column-holder">
            <div class="column">
              <CardSectorRisk :data="data" :item="investment" />
              <CardLibrarySector :item="investment" />
            </div>
            <div class="column">
              <CardEsgScore :item="investment" />
              <CardScoreOverTime :data="data" :item="investment" />
              <CardManagementComparison :item="investment" />
              <CardLogos />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import Bar from './../components/commom/Bar';
import CardRisk from '../components/dashboard/CardRisk';
import CardId from '../components/dashboard/CardId';
import CardManagementComparison from '../components/dashboard/CardManagementComparison';
import CardScoreOverTime from '../components/dashboard/CardScoreOverTime';
import CardLibrarySector from '../components/dashboard/CardLibrarySector';
import CardEconomic from '../components/dashboard/CardEconomic';
import CardSectorRisk from '../components/dashboard/CardSectorRisk';
import CardEsgScore from '../components/dashboard/CardEsgScore';
import CardLogos from '../components/dashboard/CardLogos';
import CardSectorOpportunities from '../components/dashboard/CardSectorOpportunities';

export default {
  name: "Dashboard",
  components: {
    Bar,
    CardId,
    CardRisk,
    CardManagementComparison,
    CardScoreOverTime,
    CardLibrarySector,
    CardEconomic,
    CardSectorRisk,
    CardEsgScore,
    CardLogos,
    CardSectorOpportunities,
  },
  data() {
    return {
      done: false,
      data: [],
      fund: [],
      investmentId: 0,
      investment: {}
    }
  },
  mounted() {
    if (this.$route.params.id === '')
      router.push('/overview/');
    this.$store.commit("loadData");
    this.investmentId = parseInt(this.$route.params.id);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "selectFund") {
        this.data = state.data
        this.fund = state.fund
        this.setData();
      }
    });
  },
  methods: {
    setData() {
      this.investment = this.fund.investments.filter((item) => item.id === this.investmentId)[0];
      this.investment.amountFormat = parseFloat(this.investment.amount).toLocaleString()
      const country = this.data.countries.filter((item) => item.id === this.investment.country_id)[0];
      this.investment.country = country.name;
      this.investment.overallRiskRating = country.data['overall-risk-rating'];
      this.investment.stage = this.data.stages.filter((item) => item.id === this.investment.investment_stage_id)[0].name;
      this.investment.type = this.data.types.filter((item) => item.id === this.investment.investment_type_id)[0].name;
      const sector = this.data.sectors.filter((item) => item.id === this.investment.sector_id)[0];
      this.investment.sector = sector.name;
      this.investment.subsector = sector.subsectors.filter((item) => item.id === this.investment.subsector_id)[0].name;
      this.done = true;
    }
  },
};
</script>

<style lang="scss" scoped>
#app-holder {
  background: rgba(0, 0, 0, .1);
  min-height: 100vh;
  .line {
    .column {
      width: 100%;
      margin: 0 5px;
      max-width: 38vw;
      &.single {
        max-width: 24vw;

        @media (max-width: 789px) {
          max-width: 100%;
        }
      }
      .md-card {
        margin-bottom: 10px;
      }
    }
    .holder {
      display: flex;
      width: 100%;
      @media (max-width: 789px) {
        flex-wrap: wrap;
      }
      .column-holder {
        width: 100%;
        display: flex;
        @media (max-width: 1240px) {
          flex-wrap: wrap;
          .column {
            max-width: inherit;
            width: 100%;
          }
        }
      }
    }
  }
  .box {
    width: 100%;
    margin: 0 5px 10px;
  }

}
</style>
