<template>
  <main>
    <div class="buttons tabs">
      <button :class="{'active': section === 0}" @click="toggle(0)">
        Environmental and Social
        <md-progress-bar md-mode="determinate" :md-value="amountEsms"></md-progress-bar>
      </button>
      <button :class="{'active': section === 1}" @click="toggle(1)">
        Corporate Governance
        <md-progress-bar md-mode="determinate" :md-value="amountCorporate"></md-progress-bar>
      </button>
      <button :class="{'active': section === 2}" @click="toggle(2)">
        Business Integrity
        <md-progress-bar md-mode="determinate" :md-value="amountBi"></md-progress-bar>
      </button>
    </div>
    <esms v-if="section === 0" />
    <corporate v-if="section === 1" />
    <bi v-if="section === 2" />
  </main>
</template>

<script>
import esms from "@/components/review/higher/esms";
import corporate from "@/components/review/higher/corporate";
import bi from "@/components/review/higher/bi";

export default {
  name: "HigherLevel",
  components: {
    esms,
    corporate,
    bi,
  },
  data() {
    return {
      section: this.$store.state.reviewSection.higher,
      amountEsms: (this.$store.state.reviewCount.higher.esms / 7) * 100,
      amountCorporate: (this.$store.state.reviewCount.higher.corporate / 5) * 100,
      amountBi: (this.$store.state.reviewCount.higher.bi / 6) * 100, 
    }
  },
  methods: {
    toggle(section) {
      this.$store.commit('toggleSection', {'type': 'higher', 'section': section})
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "answerReview") {
        this.amountEsms = (this.$store.state.reviewCount.higher.esms / 7) * 100;
        this.amountCorporate = (this.$store.state.reviewCount.higher.corporate / 5) * 100;
        this.amountBi = (this.$store.state.reviewCount.higher.bi / 6) * 100;
      }
      if (mutation.type === "toggleSection") {
        this.section = state.reviewSection.higher;
        window.scrollTo(0,0)
      }
      if (mutation.type === "startReview") {
        this.section = state.reviewSection.higher;
        this.amountEsms = (this.$store.state.reviewCount.higher.esms / 7) * 100;
        this.amountCorporate = (this.$store.state.reviewCount.higher.corporate / 5) * 100;
        this.amountBi = (this.$store.state.reviewCount.higher.bi / 6) * 100;
      }
    });
  },
}
</script>
