<template>
  <main>
    <HeaderColumnsLowerSMECG />
    <div class="group" :class="{'active': section[0].status}">
      <div class="group-title" @click="toggleSection(0)">
        <span class="title">
          <button>
            <span v-if="!section[0].status">+</span>
            <span v-if="section[0].status">-</span>
          </button>
          Commitment to CG
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s1')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s1')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s1')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s1')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s1')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Formalised view on Corporate Governance</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('FormalisedviewCorporateGovernance', 's1', 0)" :class="{'active': answers.FormalisedviewCorporateGovernance === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('FormalisedviewCorporateGovernance', 's1', 1)" :class="{'active': answers.FormalisedviewCorporateGovernance === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                The company has a charter or articles of incorporation, with provisions on matters of authority, information disclosure, and the protection of shareholder rights and the equitable treatment of shareholders.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company has a charter or articles of incorporation, with provisions on matters of authority, information disclosure, and the protection of shareholder rights and the equitable treatment of shareholders.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormalisedviewCorporateGovernance', 's1', 2)" :class="{'active': answers.FormalisedviewCorporateGovernance === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The company has a written code of ethics approved by the Board of Directors. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company has a written code of ethics approved by the Board of Directors. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('FormalisedviewCorporateGovernance', 's1', 3)" :class="{'active': answers.FormalisedviewCorporateGovernance === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company has a written corporate governance code. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company has a written corporate governance code. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('FormalisedviewCorporateGovernance', 's1', 4)" :class="{'active': answers.FormalisedviewCorporateGovernance === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Authority</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Authority', 's1', 0)" :class="{'active': answers.Authority === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 1)" :class="{'active': answers.Authority === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                There is a distribution of authority between the Annual General Meeting of Shareholders, the Board of Directors and executive bodies is defined/codified. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                There is a distribution of authority between the Annual General Meeting of Shareholders, the Board of Directors and executive bodies is defined/codified. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 2)" :class="{'active': answers.Authority === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Management/Board of Directors approves annual calendar of corporate events (Board meetings, General Shareholders' Meeting, etc.).
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Management/Board of Directors approves annual calendar of corporate events (Board meetings, General Shareholders' Meeting, etc.).
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Authority', 's1', 3)" :class="{'active': answers.Authority === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company has a designated officer responsible for ensuring compliance with the company's corporate governance policies and has a compliance officer responsible for ensuring compliance with its code of ethics. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company has a designated officer responsible for ensuring compliance with the company's corporate governance policies and has a compliance officer responsible for ensuring compliance with its code of ethics. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Authority', 's1', 4)" :class="{'active': answers.Authority === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Information disclosure</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Informationdisclosure', 's1', 0)" :class="{'active': answers.Informationdisclosure === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Informationdisclosure', 's1', 1)" :class="{'active': answers.Informationdisclosure === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Information disclosure and transparency of the company's activities is provided with regular intervals.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Information disclosure and transparency of the company's activities is provided with regular intervals.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Informationdisclosure', 's1', 2)" :class="{'active': answers.Informationdisclosure === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The company periodically discloses to shareholders its corporate governance code and practices.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The company periodically discloses to shareholders its corporate governance code and practices.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Informationdisclosure', 's1', 3)" :class="{'active': answers.Informationdisclosure === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company periodically discloses the extent to which its corporate governance practices conform to the country's voluntary code of best practices, if applicable.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The company periodically discloses the extent to which its corporate governance practices conform to the country's voluntary code of best practices, if applicable.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Informationdisclosure', 's1', 4)" :class="{'active': answers.Informationdisclosure === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[1].status}">
      <div class="group-title" @click="toggleSection(1)">
        <span class="title">
          <button>
            <span v-if="!section[1].status">+</span>
            <span v-if="section[1].status">-</span>
          </button>
          Board Structure and Functioning
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s2')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s2')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s2')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s2')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s2')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Board meetings and size</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Boardmeetingssize', 's2', 0)" :class="{'active': answers.Boardmeetingssize === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Boardmeetingssize', 's2', 1)" :class="{'active': answers.Boardmeetingssize === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Board meetings are held periodically and deliberates independently of executive management. Board members are given sufficient information and time to prepare for Board meetings. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Board meetings are held periodically and deliberates independently of executive management. Board members are given sufficient information and time to prepare for Board meetings. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Boardmeetingssize', 's2', 2)" :class="{'active': answers.Boardmeetingssize === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Board meetings are held according to a regular schedule (at least 4 times a year), the agenda is prepared in advance, and minutes are recorded and approved. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Board meetings are held according to a regular schedule (at least 4 times a year), the agenda is prepared in advance, and minutes are recorded and approved. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Boardmeetingssize', 's2', 3)" :class="{'active': answers.Boardmeetingssize === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The Board of Directors is elected on a regular basis.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The Board of Directors is elected on a regular basis.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Boardmeetingssize', 's2', 4)" :class="{'active': answers.Boardmeetingssize === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Board composition</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Boardcomposition', 's2', 0)" :class="{'active': answers.Boardcomposition === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Boardcomposition', 's2', 1)" :class="{'active': answers.Boardcomposition === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                No or little consideration has been made of the Boards composition and skill mix. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                No or little consideration has been made of the Boards composition and skill mix. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Boardcomposition', 's2', 2)" :class="{'active': answers.Boardcomposition === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The Board includes members who are neither executives of the company and its affiliates, nor controlling shareholders. Board composition (competencies / skill mix) is adequate for its strategy and oversight duties.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The Board includes members who are neither executives of the company and its affiliates, nor controlling shareholders. Board composition (competencies / skill mix) is adequate for its strategy and oversight duties.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Boardcomposition', 's2', 3)" :class="{'active': answers.Boardcomposition === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The Board of Directors is composed of a majority of independent Directors, and with at least one financial expert. The Board of Directors and executive management receive induction and regular training. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The Board of Directors is composed of a majority of independent Directors, and with at least one financial expert. The Board of Directors and executive management receive induction and regular training. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Boardcomposition', 's2', 4)" :class="{'active': answers.Boardcomposition === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Board Committees</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('BoardCommittees', 's2', 0)" :class="{'active': answers.BoardCommittees === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('BoardCommittees', 's2', 1)" :class="{'active': answers.BoardCommittees === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                No or few committees have been established. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                No or few committees have been established. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BoardCommittees', 's2', 2)" :class="{'active': answers.BoardCommittees === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                A number of committees have been established, and reflect the business and operational needs well. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                A number of committees have been established, and reflect the business and operational needs well. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BoardCommittees', 's2', 3)" :class="{'active': answers.BoardCommittees === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The Board of Directors has an Audit Committee chaired by an independent Director.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The Board of Directors has an Audit Committee chaired by an independent Director.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('BoardCommittees', 's2', 4)" :class="{'active': answers.BoardCommittees === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[2].status}">
      <div class="group-title" @click="toggleSection(2)">
        <span class="title">
          <button>
            <span v-if="!section[2].status">+</span>
            <span v-if="section[2].status">-</span>
          </button>
          Control Environment and Processes
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s3')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s3')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s3')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s3')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s3')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Internal controls</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Internalcontrols', 's3', 0)" :class="{'active': answers.Internalcontrols === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Internalcontrols', 's3', 1)" :class="{'active': answers.Internalcontrols === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                An adequate internal control system is in place.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                An adequate internal control system is in place.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Internalcontrols', 's3', 2)" :class="{'active': answers.Internalcontrols === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The internal control system is in accordance with the highest national standards.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The internal control system is in accordance with the highest national standards.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Internalcontrols', 's3', 3)" :class="{'active': answers.Internalcontrols === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company has a comprehensive compliance program that is annually reviewed. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company has a comprehensive compliance program that is annually reviewed. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Internalcontrols', 's3', 4)" :class="{'active': answers.Internalcontrols === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Audits</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Audits', 's3', 0)" :class="{'active': answers.Audits === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Audits', 's3', 1)" :class="{'active': answers.Audits === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                There is internal audit function.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                There is internal audit function.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Audits', 's3', 2)" :class="{'active': answers.Audits === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The internal audit function is in accordance with applicable national standards.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The internal audit function is in accordance with applicable national standards.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Audits', 's3', 3)" :class="{'active': answers.Audits === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The internal audit function is fully independent. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The internal audit function is fully independent. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Audits', 's3', 4)" :class="{'active': answers.Audits === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Oversight responsibility</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Oversightresponsibility', 's3', 0)" :class="{'active': answers.Oversightresponsibility === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Oversightresponsibility', 's3', 1)" :class="{'active': answers.Oversightresponsibility === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                The internal control system is periodically reviewed by an independent internal audit function.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The internal control system is periodically reviewed by an independent internal audit function.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Oversightresponsibility', 's3', 2)" :class="{'active': answers.Oversightresponsibility === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The Board of Directors approves and regularly monitors the company's risk management and compliance policies and procedures.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The Board of Directors approves and regularly monitors the company's risk management and compliance policies and procedures.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Oversightresponsibility', 's3', 3)" :class="{'active': answers.Oversightresponsibility === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The internal audit function is overseen by, and reports directly to, an Audit Committee.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The internal audit function is overseen by, and reports directly to, an Audit Committee.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Oversightresponsibility', 's3', 4)" :class="{'active': answers.Oversightresponsibility === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[3].status}">
      <div class="group-title" @click="toggleSection(3)">
        <span class="title">
          <button>
            <span v-if="!section[3].status">+</span>
            <span v-if="section[3].status">-</span>
          </button>
          Transparency and Disclosure
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s4')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s4')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s4')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s4')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s4')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Financial disclosure</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Financialdisclosure', 's4', 0)" :class="{'active': answers.Financialdisclosure === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 1)" :class="{'active': answers.Financialdisclosure === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Financial statements are prepared in accordance with national accounting standards.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Financial statements are prepared in accordance with national accounting standards.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 2)" :class="{'active': answers.Financialdisclosure === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The company discloses information on Affiliated parties and the affiliation of members of the Board of Directors in meetings of the Board of Directors and Annual General Meeting of Shareholders. All disclosure to and communications with shareholders (e.g., materials for the shareholders’ meetings, minutes of the most recent meetings etc.) are made available on the internet in a timely fashion.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company discloses information on Affiliated parties and the affiliation of members of the Board of Directors in meetings of the Board of Directors and Annual General Meeting of Shareholders. All disclosure to and communications with shareholders (e.g., materials for the shareholders’ meetings, minutes of the most recent meetings etc.) are made available on the internet in a timely fashion.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialdisclosure', 's4', 3)" :class="{'active': answers.Financialdisclosure === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company prepares and presents all financial statements and reporting in accordance with a widely accepted, internationally recognized system of accounting (IFRS or U.S. GAAP). 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The company prepares and presents all financial statements and reporting in accordance with a widely accepted, internationally recognized system of accounting (IFRS or U.S. GAAP). 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Financialdisclosure', 's4', 4)" :class="{'active': answers.Financialdisclosure === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Financial transparency</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Financialtransparency', 's4', 0)" :class="{'active': answers.Financialtransparency === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Financialtransparency', 's4', 1)" :class="{'active': answers.Financialtransparency === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Financial statements are audited by a recognized independent external auditing firm.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Financial statements are audited by a recognized independent external auditing firm.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialtransparency', 's4', 2)" :class="{'active': answers.Financialtransparency === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The company’s financial statements are audited in accordance with International Standards on Auditing. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company’s financial statements are audited in accordance with International Standards on Auditing. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Financialtransparency', 's4', 3)" :class="{'active': answers.Financialtransparency === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company discloses information on the remuneration of the members of the Board of Directors and senior management. The company publishes a comprehensive annual report that includes a corporate governance section. The company rotates its external auditors or at least rotates its audit engagement partner. The company discloses its beneficial owners.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The company discloses information on the remuneration of the members of the Board of Directors and senior management. The company publishes a comprehensive annual report that includes a corporate governance section. The company rotates its external auditors or at least rotates its audit engagement partner. The company discloses its beneficial owners.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Financialtransparency', 's4', 4)" :class="{'active': answers.Financialtransparency === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[4].status}">
      <div class="group-title" @click="toggleSection(4)">
        <span class="title">
          <button>
            <span v-if="!section[4].status">+</span>
            <span v-if="section[4].status">-</span>
          </button>
          Shareholder Rights
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s5')}">
            <span>
              Basic
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s5')}">
            <span>
              Developing
            </span>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s5')}">
            <span>
              Developed
            </span>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s5')}">
            <span>
              Advanced
            </span>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s5')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Shareholder meetings</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Shareholdermeetings', 's5', 0)" :class="{'active': answers.Shareholdermeetings === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Shareholdermeetings', 's5', 1)" :class="{'active': answers.Shareholdermeetings === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                Minority shareholders are provided with adequate notice and an agenda of the shareholders' meetings; and are permitted to participate and vote at such shareholders' meetings. All shareholders' meetings are convened at an easily accessible location.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Minority shareholders are provided with adequate notice and an agenda of the shareholders' meetings; and are permitted to participate and vote at such shareholders' meetings. All shareholders' meetings are convened at an easily accessible location.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Shareholdermeetings', 's5', 2)" :class="{'active': answers.Shareholdermeetings === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                Shareholders can propose items to the agenda and to ask questions at the shareholders' meetings. Effective representation of minority shareholders is provided by cumulative voting or similar mechanisms.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                Shareholders can propose items to the agenda and to ask questions at the shareholders' meetings. Effective representation of minority shareholders is provided by cumulative voting or similar mechanisms.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Shareholdermeetings', 's5', 3)" :class="{'active': answers.Shareholdermeetings === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company has effective shareholder voting mechanisms in place to protect minority shareholders against unfairly prejudiced actions of controlling shareholders where ownership is especially concentrated or controlling shareholders have strong conflicts of interest (e.g., super-majority or "majority of minority" provisions). 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 0">
                The company has effective shareholder voting mechanisms in place to protect minority shareholders against unfairly prejudiced actions of controlling shareholders where ownership is especially concentrated or controlling shareholders have strong conflicts of interest (e.g., super-majority or "majority of minority" provisions). 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Shareholdermeetings', 's5', 4)" :class="{'active': answers.Shareholdermeetings === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Conflicts of interest</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Conflictsinterest', 's5', 0)" :class="{'active': answers.Conflictsinterest === 0}">
              <span>
                Basic
              </span>
            </span>
            <span class="button" @click="toggle('Conflictsinterest', 's5', 1)" :class="{'active': answers.Conflictsinterest === 1}">
              <span class="short">
                Developing
              </span>
              <span class="long">
                All shareholders of the same class are treated equally with respect to voting rights, subscription rights and transfer rights. Shareholders are provided with accurate and timely information regarding the number of shares of all classes held by controlling shareholders and their affiliates (ownership concentration).
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                All shareholders of the same class are treated equally with respect to voting rights, subscription rights and transfer rights. Shareholders are provided with accurate and timely information regarding the number of shares of all classes held by controlling shareholders and their affiliates (ownership concentration).
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Conflictsinterest', 's5', 2)" :class="{'active': answers.Conflictsinterest === 2}">
              <span class="short">
                Developed
              </span>
              <span class="long">
                The company has a well understood policy and practice of full and timely disclosure to shareholders of all material transactions with affiliates of the controlling shareholders, directors or management (conflicts of interest). The company has a dividend policy which is disclosed to shareholders.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                The company has a well understood policy and practice of full and timely disclosure to shareholders of all material transactions with affiliates of the controlling shareholders, directors or management (conflicts of interest). The company has a dividend policy which is disclosed to shareholders.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Conflictsinterest', 's5', 3)" :class="{'active': answers.Conflictsinterest === 3}">
              <span class="short">
                Advanced
              </span>
              <span class="long">
                The company has clearly articulated and enforceable policies with respect to the treatment of minority shareholders in changes of control situations (tag-along rights). The company pays dividends shortly after a decision to declare dividends is made.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step === 1">
                The company has clearly articulated and enforceable policies with respect to the treatment of minority shareholders in changes of control situations (tag-along rights). The company pays dividends shortly after a decision to declare dividends is made.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Conflictsinterest', 's5', 4)" :class="{'active': answers.Conflictsinterest === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised" @click="previousSection()">Previous</md-button>
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Next</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="nextSection()">Next</md-button>
    </div>
  </main>
</template>

<script>
import HeaderColumnsLowerSMECG from '../HeaderColumnsLowerSMECG';

export default {
  name: "corporate",
  components: {
    HeaderColumnsLowerSMECG,
  },
  data() {
    return {
      step: 0,
      answers: [],
      expandStatus: this.$store.state.expandStatus,
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
      disabled: true,
      section: [
        { status: false, count: 0, total: 3, name: 's1' },
        { status: false, count: 0, total: 3, name: 's2' },
        { status: false, count: 0, total: 3, name: 's3' },
        { status: false, count: 0, total: 2, name: 's4' },
        { status: false, count: 0, total: 2, name: 's5' },
      ],
      sectionMedian: {
        s1: null,
        s2: null,
        s3: null,
        s4: null,
        s5: null,
      },
      level: 'corporate',
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
  },
  methods: {
    toggle(key, section, index) {
      const total = this.section.filter((item) => item.name === section)[0].total;
      this.$store.commit("answerReview", {level: "corporate", type: "lower", key: key, value: index, section: section, total: total});
      const check = this.review.lower.filter((item) => item.section === section && item.level === "corporate")
      this.step = check.length;
      if (this.step === total) {
        this.section.forEach((item) => {
          item.status = false;
        });
      }
    },
    assembleAnswers() {
      this.answers = []
      this.review.lower.map((item) => {
        this.answers[item.key] = item.value;
      });
      this.getMedian();
      if (this.reviewCount.lower.corporate >= 13) {
        this.disabled = false;
      }
    },
    toggleSection(key) {
      if (this.section[key].status) {
        this.section[key].status = false;
        return false;
      }
      this.section.forEach((item) => {
        item.status = false;
      });
      this.section[key].status = !this.section[key].status;
      const check = this.review.lower.filter((item) => item.section === this.section[key].name && item.level === "corporate")
      const total = this.section[key].total;
      this.step = check.length === total ? 0 : check.length;
    },
    nextSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 2})
    },
    previousSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 0})
    },
    getMedian() {
      this.section.map((section) => {
        let total = 0
        if (this.review.lower) {
          const item = this.review.lower.filter((item) => item.section === section.name && item.level === this.level);
          if (item)
            item.map((item) => {
              total += item.value;
            });
        }
        this.sectionMedian[section.name] = parseInt(total / section.total);
      });
    },
    getLabel(section) {
      const total = this.section.filter((item) => item.name === section)[0].total
      const check = this.review.lower.filter((item) => item.section === section && item.level === this.level)
      if (check.length < total)
        return '';
      return this.sectionMedian[section];
    }
  }
}
</script>
