<template>
  <main>
    <md-app>
      <md-app-toolbar>
        <Bar />
      </md-app-toolbar>
      <md-app-drawer md-permanent="full">
        <Menu />
      </md-app-drawer>
      <md-app-content>
      </md-app-content>
    </md-app>
  </main>
</template>

<script>
import Bar from '../components/admin/Bar';
import Menu from '../components/admin/Menu';

export default {
  name: "Admin",
  components: {
    Menu,
    Bar,
  }
};
</script>
