<template>
  <md-card>
    <md-card-header>
      <div class="md-title">E&S Risk Category Portfolio Overview</div>
      <div class="utils">
        <md-icon>
          info
          <md-tooltip v-if="data.content" v-html="data.content['Info: E&S Risk Category Portfolio Overview']" md-direction="top"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content v-if="chartDataSector[0]">
      <vue-plotly :autoResize="true" :data="chartDataSector" :options="options" :layout="layout" />
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  display: flex;
  justify-content: space-between;
  .utils {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
  }
}
.md-card-content {
  padding: 20px 0;
}
.holder {
  display: flex;
  .item {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    strong {
      font-size: 16px;
      margin-bottom: 20px;
      width: 100%; 
    }
  }
}
</style>

<script>
import VuePlotly from '@statnett/vue-plotly'

export default {
  name: "CardPieEsgReview",
  props: ["data", "investments"],
  components: {
    VuePlotly
  },
  methods: {
    getData() {
      let high = 0;
      let medium = 0;
      let low = 0;
      let labels = [];
      let values = [];
      let colors = [];
      this.investments.map((item) => {
        if (!item.risk)
          return;
        switch (item.risk.trim()) {
          case "High":
            high += 1;
            break;
          case "Medium":
            medium += 1;
            break;
          case "Low":
            low += 1;
            break;
          default:
            return;
        }
      });
      if (high > 0) {
        labels.push("High");
        values.push(high);
        colors.push("#d43501");
      }
      if (medium > 0) {
        labels.push("Medium");
        values.push(medium);
        colors.push("#ff9502");
      }
      if (low > 0) {
        labels.push("Low");
        values.push(low);
        colors.push("#869c66");
      }
      this.chartDataSector.push({
        type: "pie",
        labels: labels,
        textinfo: "none",
        textposition: "outside",
        hole: 0.6,
        hoverinfo: "label+value",
        hoverlabel: {
          namelength: -1
        },
        marker: {
          colors: colors
        },
        values: values
      });
      this.layout = {
        height: 160,
        paper_bgcolor: "transparent",
        margin: {"t": 0, "b": 10, "l": 0, "r": 0},
        showlegend: true,
        hoverlabel: {
          namelength: -1,
          bgcolor: "#FFF"
        },
        legend: {
          itemclick: false,
          itemdoubleclick: false,
        },
      };
    },
    setData() {
      if (!this.investments)
        return false;
      this.chartDataSector = []
      if (this.investments.length > 0) {
        this.getData();
      } else {
        this.chartDataSector.push({
          type: "pie",
          labels: ['No data'],
          textinfo: "none",
          hole: 0.6,
          hoverinfo: "none",
          marker: {
            colors: ["#eee"]
          },
          values: [1]
        });
        this.layout = {
          height: 160,
          paper_bgcolor: "transparent",
          margin: {"t": 0, "b": 10, "l": 0, "r": 0},
          showlegend: false
        };
      }
    }
  },
  mounted() {
    this.setData();
  },
  watch: {
    investments() {
      this.setData();
    },
  },
  data() {
    return {
      options: {
        autosizable: true,
        responsive: true
      },
      chartDataSector: [],
      chartData: [{
        type: "pie",
        labels: ["High", "Medium", "Low"],
        textinfo: "none",
        textposition: "outside",
        hole: 0.6,
        hoverinfo: "label+value",
        hoverlabel: {
          namelength: -1,
          bgcolor: "#FFF"
        },
        marker: {
          colors: ["#015495", "#5290E9", "#AACCFF"]
        },
        values: [14, 14, 12]
      }],
    }
  }
}
</script>