<template>
  <main id="app-holder">
    <Bar />
    <div id="esg-review" :class="{'expanded': expand}">
      <div class="wrapper">
        <div class="breadcrumbs">
          <router-link to="/overview">Overview</router-link> / {{ investment.name }} / ESG Review
        </div>
        <div class="header">
          <span class="md-title">
            ESG Review
            <md-button @click="toggleHistory()" class="md-raised" v-if="investment.reviews.length > 0">see history</md-button>
            <small v-if="preview.status">This is an old review, you can't update the answers.</small>
            <p>
              Conduct the ESG review by selecting the most appropriate scoring for each area of the assessment. The ESG review is divided into three assessment (Environmental and Social, Corporate Governance and Business Integrity). The progression of the ESG review is illustrated by the blue bar under the three assessment sections.  
            </p>
          </span>
          <div class="utils">
            <div class="diligence">
              <div class="options">
                <md-button class="md-raised margin" to="/overview">
                  Save and continue later
                </md-button>
                <md-button class="md-raised md-primary" @click="changeScope = true">
                  Change analysis level
                  <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: Review button change analysis level']"></md-tooltip>
                </md-button>
              </div>
            </div>
            <div class="switch">
              <span>
                <md-switch v-model="expand" class="md-primary">See definitions</md-switch>
                <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: Review button see definitions']"></md-tooltip>
              </span>
            </div>
          </div>
        </div>
        <HigherLevel v-if="section === 1" />
        <LowerLevel v-if="section === 2" />
      </div>
      <md-dialog :md-active.sync="firstReview">
        <md-dialog-title>Information for starting an ESG Review</md-dialog-title>
        <md-dialog-content>
          <p>
            This is the start of the ESG review for investment {{ investment.name }}.
          </p>
          <p>
            This review can be completed by assigning a score on each topic.
          </p>
          <p>
            Scoring for Environmental and Social Risk management and Business Integrity risk management are as follows:
          </p>
          <p>
            &bull; Non existing; <br/>
            &bull; Basic; <br/>
            &bull; Good; <br/>
            &bull; Very good. 
          </p>
          <p>
            The scoring scale for Corporate Governance management differs since a company’s corporate governance does not require the same level of development depending its size. A small company needs appropriate corporate governance for their stage of development otherwise this might have a negative effect on the company’s performances. See below the scoring for Corporate and Governance management: 
          </p>
          <p>
            &bull; Basic; <br/>
            &bull; Developing; <br/>
            &bull; Developed; <br/>
            &bull; Advanced. 
          </p>
          <p>
            The ESG review can be done on two different levels: Quick level where you review the investment on 18 areas, or In-depth level where each area is divided into sub-areas and a score needs to be assigned for 58 sub-areas.
          </p>
          <p v-if="!investment.is_sme">
            If the investee is a SME, you can have a more appropriate questionnaire for the ESG review by switching on the SME button. 
          </p>
          <p>
            Select your level by clicking on either one below: 
          </p>
          <div class="diligence">
            <div class="options">
              <md-button class="md-raised" @click="startFirstReview('quick')">
                Quick analysis
              </md-button>
              <md-button class="md-raised" @click="startFirstReview('indepth')">
                In-depth analysis
              </md-button>
            </div>
          </div>
        </md-dialog-content>
      </md-dialog>
      <md-dialog :md-active.sync="reviewHistory">
        <md-dialog-title>Review history</md-dialog-title>
        <md-dialog-content>
          <p>
            Please select whether you want to edit an existing ESG review or conduct a new ESG review
          </p>
          <div class="buttons space-between">
            <md-button class="md-raised md-primary" @click="reviewHistory = false">
              Edit current review
            </md-button>
            <md-button class="md-raised" @click="startNew()">
              Add new review
            </md-button>
          </div>
        </md-dialog-content>
      </md-dialog>
      <md-dialog :md-active.sync="selectStage">
        <md-dialog-title>Start new</md-dialog-title>
        <md-dialog-content>
          <p>
            You have the possibility to create several ESG review for a same investment. This will allow you to track the ESG management score over the investment process (see ESG Management Score over time in the investment dashboard). You can visualize the history of your ESG reviews in the next page. 
          </p>
          <p>
            Please select below the stage of the investment process for which you want to conduct this ESG review.  
          </p>
          <md-field>
            <label>Select stage</label>
            <md-select v-model="investment_stage_id">
              <md-option>Select stage</md-option>
              <md-option v-for="item in data.stages" v-bind:key="item.id" :value="item.id">{{item.name}}</md-option>
            </md-select>
          </md-field>
          <div class="buttons space-between">
            <md-button class="md-raised" @click="goBack()">
              Go back
            </md-button>
            <div class="holder">
              <md-button class="md-raised" @click="toggleHistory()">
                View history
              </md-button>
              <md-button class="md-raised md-primary" v-if="!investment_stage_id" disabled>
                Add new review
              </md-button>
              <md-button class="md-raised md-primary" v-if="investment_stage_id" @click="start()">
                Add new review
              </md-button>
            </div>
          </div>
        </md-dialog-content>
      </md-dialog>
      <md-dialog :md-active.sync="changeScope">
        <md-dialog-title>Changing analysis level</md-dialog-title>
        <md-dialog-content>
          <p>
            This allows you to change the current analysis level. This will delete the current ESG Review for this investment. If you're sure you want to do so choose the new due diligence level below. Else just close this box and proceed the existing review.
          </p>
          <div class="diligence">
            <p>Analysis level:</p>
            <div class="options buttons-dismiss">
              <div>
                <md-button class="md-raised" @click="toogleSectionModal('quick')">
                  Quick analysis
                </md-button>
                <md-button class="md-raised" @click="toogleSectionModal('indepth')">
                  In-depth analysis
                </md-button>
              </div>
              <md-button class="md-raised md-accent" @click="changeScope = false">
                Close
              </md-button>
            </div>
          </div>
        </md-dialog-content>
      </md-dialog>
      <md-dialog :md-active.sync="history">
        <md-dialog-title>
          Review history
          <md-button @click="history = false">
            <md-icon>clear</md-icon>
          </md-button>
        </md-dialog-title>
        <md-dialog-content>
          <p>
            This allows you to change the current analysis level. This will delete the current ESG Review for this investment. If you're sure you want to do so choose the new due diligence level below. Else just close this box and proceed the existing review.
          </p>
          <md-table>	
            <md-table-row>
              <md-table-cell>Stage</md-table-cell>
              <md-table-cell>Type of analysis</md-table-cell>
              <md-table-cell>Started at</md-table-cell>
              <md-table-cell>Last updated at</md-table-cell>
              <md-table-cell>Status</md-table-cell>
              <md-table-cell>Score</md-table-cell>
              <md-table-cell></md-table-cell>
            </md-table-row>
            <md-table-row v-for="(item, index) in investment.reviews" v-bind:key="index">
              <md-table-cell>{{ item.investment_stage_name }}</md-table-cell>
              <md-table-cell>{{ getType(item) }}</md-table-cell>
              <md-table-cell>{{ item.created_at | moment("from") }}</md-table-cell>
              <md-table-cell>{{ item.updated_at | moment("from") }}</md-table-cell>
              <md-table-cell>{{ getDone(item) }}</md-table-cell>
              <md-table-cell>
                <span v-if="item.reviewScore && item.reviewScore.total">{{ `${item.reviewScore.total.toFixed(0)}%` }}</span>
              </md-table-cell>
              <md-table-cell md-label="">
                <md-button @click="previewReview(item)" v-if="isDone(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button @click="resumeReview(item)" v-if="!isDone(item)">
                  <md-icon>play_arrow</md-icon>
                </md-button>
                <md-button @click="setReviewDelete(item.id)">
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="buttons space-between">
            <md-button class="md-raised" @click="startNew()">
              Add new review
            </md-button>
          </div>
        </md-dialog-content>
      </md-dialog>
      <md-dialog :md-active.sync="deleteDialog">
        <md-dialog-title>Remove review</md-dialog-title>
        <md-dialog-content>
          <p>
            Do you really want to delete this review? This action is permanent.
          </p>
          <div class="options buttons-dismiss">
            <md-button class="md-raised md-accent" @click="deleteDialog = false">
              No
            </md-button>
            <md-button class="md-raised md-primary" @click="remove">
              Yes
            </md-button>
          </div>
        </md-dialog-content>
      </md-dialog>
    </div>
  </main>
</template>

<script>
import HigherLevel from './../components/review/HigherLevel';
import LowerLevel from './../components/review/LowerLevel';
import Bar from './../components/commom/Bar';
import router from "@/router";

export default {
  name: "EsgReview",
  components: {
    Bar,
    HigherLevel,
    LowerLevel,
  },
  data() {
    return {
      preview: this.$store.state.preview,
      changeScope: false,
      firstReview: false,
      selectStage: false,
      reviewHistory: false,
      panel: 'first',
      section: 0,
      lowerCount: this.$store.state.reviewCount.lower.total,
      higherCount: this.$store.state.reviewCount.higher.total,
      higherTotal: 18,
      lowerTotal: 58,
      investment: this.$store.state.investment,
      data: this.$store.state.data,
      expand: false,
      investment_stage_id: 0,
      optionsStage: [],
      history: false,
      deleteDialog: false,
      deleteId: 0,
    }
  },
  methods: {
    toggleHistory() {
      this.selectStage = false;
      this.history = !this.history;
    },
    getType(review) {
      return review.type === 1 ? 'Quick' : 'Indeepth';
    },
    getDone(review) {
      if (review.type === 1) {
        return review.done_quick === 1 ? 'Done' : 'In progress';
      } else {
        return review.done_indeepth === 1 ? 'Done' : 'In progress';
      }
    },
    isDone(review) {
      if (review.type === 1) {
        return review.done_quick === 1;
      } else {
        return review.done_indeepth === 1;
      }
    },
    goBack() {
      this.selectStage = false;
      this.reviewHistory = true;
    },
    startNew() {
      this.history = false;
      this.reviewHistory = false;
      this.selectStage = true;
    },
    startFirstReview(url) {
      this.$store.commit('startFirstReview');
      this.section = url === 'quick' ? 1 : 2;
      this.$store.commit('toggleReviewType', this.section);
      router.push(`/esg-review/${this.$route.params.id}/${url}/`);
      this.firstReview = this.changeScope = false;
    },
    toogleSectionModal(url) {
      this.section = url === 'quick' ? 1 : 2;
      this.$store.commit('toggleReviewType', this.section);
      router.push(`/esg-review/${this.$route.params.id}/${url}/`);
      this.firstReview = this.changeScope = false;
    },
    getLastUpdate(id) {
      let LastUpdate = ''
      this.investment.reviews.map((item) => {
        if (item.investment_stage_id === id) {
          LastUpdate = item.updated_at;
        }
      });
      return LastUpdate;
    },
    start() {
      this.selectStage = false;
      this.$store.commit('updateInvestmentStage', this.investment_stage_id);
      this.$store.commit('startReview');
    },
    remove() {
      this.$store.commit('removeReview', this.deleteId);
    },
    hasStarted(id) {
      let started = false
      this.investment.reviews.map((item) => {
        if (item.investment_stage_id === id) {
          started = true;
        }
      });
      return started;
    },
    setReviewDelete(id) {
      this.deleteId = id;
      this.deleteDialog = !this.deleteDialog; 
    },
    previewReview(item) {
      this.history = false;
      this.$store.commit('togglePreview', item);
    },
    removePreview() {
      this.$store.commit('togglePreview', false);
    },
    resumeReview() {
      this.history = false;
    }
  },
  watch: {
    expand() {
      this.$store.commit("toggleExpandStatus", this.expand);
    }
  },
  mounted() {
    if (!this.investment.name)
      router.push('/overview/');
    if (!this.data.stages)
      router.push({ name: "overview" });
    this.data.stages.map((item) => {
      this.optionsStage.push({"value": item.id, "text": item.name});
    })
    if (this.$route.params.type) {
      this.section = this.$route.params.type === 'quick' ? 1 : 2;
      if (
        (this.investment.review.done_quick === 1 && this.investment.review.type === 1) || 
        (this.investment.review.done_indeepth === 1 && this.investment.review.type === 2)
      ) {
        this.selectStage = true;
      } else {
        this.reviewHistory = true;
      }
    } else {
      this.firstReview = true;
    }
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "answerReview":
          this.higherCount = state.reviewCount.higher ? state.reviewCount.higher.total : 0;
          this.lowerCount = state.reviewCount.lower ? state.reviewCount.lower.total : 0;
          break;
        case "removeReview":
          this.deleteId = null;
          this.deleteDialog = false; 
          break;
        case "selectFund":
          this.investment = state.investment;
          break;
        case "togglePreview":
          this.preview = state.preview;
      }
    });
  },
};
</script>

<style lang="scss">
.md-dialog {
  .buttons {
    display: flex;
    &.space-between {
      margin-top: 20px;
      justify-content: space-between;
      .holder {
        display: flex;
        button {
          margin-left: 10px;
        }
      }
      button {
        margin: 0;
        &[disabled] {
          opacity: 0.5
        }
      }
    }
  }
}
#esg-review {
  .header {
    display: flex;
    align-items: center;
    .md-title {
      flex-grow: 1;
      width: 100%;
      small {
        font-size: 12px;
        button {
          background: none;
          display: inline-block;
          margin: 0;
          font-size: 12px;
          padding: 0;
          border: 0;
        }
      }
      button {
        display: block;
        margin: 15px 0 0;
        font-size: 12px;
        height: 22px;
      }
      p {
        font-size: 12px;
      }
    }
    .utils {
      width: 30%;
      text-align: right;
      .switch {
        width: 100%;
        margin-top: 20px;
        .md-switch {
          margin: 0;
          label {
            font-size: 14px;
          }
        }
      }
      .diligence {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .options {
          text-align: right;
          .md-button {
            margin: 5px 0;
          }
          button {
            width: 100%;
            margin: 0;
            display: block;
          }
        }
      }
    }
  }
  .tabs {
    margin-top: 40px;
    display: flex;
    button {
      cursor: pointer;
      background: none;
      padding: 20px 0 25px;
      width: 100%;
      border: 0;
      color: rgba(0,0,0,0.7);
      position: relative;
      font-size: 16px;
      &.active {
        background: rgba(0,0,0,0.1);
      }
      .md-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
      }
    }
  }
  .row-header {
    display: flex;
    margin-top: 20px;
    padding: 16px 10px 0;
    background: #fff;
    &.fixed {
      position: fixed;
      top: 0;
    }
    div {
      width: 100%;
    }
  }
  .block {
    padding: 20px 10px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &.switch {
      justify-content: flex-start;
      display: flex;
    }
    &:first-child {
      border-top: 0;
      margin-top: 0;
    }
    &.active {
      .button {
        .long {
          display: block;
        }
        .short {
          display: none;
        }
      }
    }
  }
  .buttons {
    display: flex;
    width: 100%;
    .button-disabled {
      width: 100%;
      border-radius: 4px;
      margin: 0 10px;
      padding: 10px 16px;
    }
    .button {
      width: 100%;
      transition: all 0.3s;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      padding: 10px 16px;
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      &:hover,
      &.active { 
        background: rgb(2, 84, 149);
        color: #fff;
        &.na {
          background: rgba(255, 82, 82, 1);
        }
      }
      small {
        display: block;
      }
      &.na {
        max-width: 7%;
        background: rgba(255, 82, 82, 0.2);
      }
    }
    .long {
      display: none;
      font-size: 12px;
      word-break: break-word;
    }
    &.active {
      .long {
        display: block;
      }
      .short {
        display: none;
      }
    }
  }
  .button-nav {
    padding: 40px 0 180px;
    button {
      float: left;
      &:last-child {
        float: right;
      }
    }
  }
  .group {
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,0.2); 
    transition: background 0.3s;

    .group-title {
      display: flex;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      .title {
        button {
          border: 0;
          padding: 0 10px;
          width: 40px;
          font-size: 16px;
          background: none;
        }
      }
      .buttons {
        .button:hover:not(.active) {
          background: rgba(0, 0, 0, 0.09);
          color: rgb(44, 62, 80);
        }
        .long {
          display: none;
        }
        .short {
          display: block;
        }
      }
    }
    .blocks {
      margin-top: 10px;
      display: none;
      .block {
        padding: 20px 0 20px 10px;
      }
    }
    &.active {
      padding: 0;
      background: rgba(0, 0, 0, 0.08);
      .group-title {
        padding: 10px 0;
        margin-bottom: -10px;
        background: rgba(0, 0, 0, 0.06);
      }
      .blocks {
        display: block;
      }
    }
  }
  .title {
    min-width: 20%;
    width: 20%;
    @media (min-width: 1040px) {
      min-width: 30%;
      width: 30%;
    }
    text-align: left;
    display: flex;
    align-items: center;
    span {
      flex-grow: 1;
    }
    .md-icon {
      margin-right: 10px;
    }
  }
  &.expanded {
    .group.active,
    .button {
      .long {
        display: block;
      }
      .short {
        display: none;
      }
    }
  }
}
.buttons-dismiss {
  display: flex;
  justify-content: space-between;
}
</style>
