<template>
  <md-card>
    <md-card-content>
      <div class="holder">
        <div class="option">
          <md-field>
            <label for="sector">Select sector:</label>
            <md-select id="sector" v-model="sectorId">
              <md-option value="All">All</md-option>
              <md-option :value="item.id" v-for="item in optionsSectors" v-bind:key="item.id">{{ item.name }}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="option">
          <md-field>
            <label for="sector">ESG Risk:</label>
            <md-select id="sector" v-model="risk">
              <md-option value="All">All</md-option>
              <md-option :value="item.id" v-for="item in optionsLevels" v-bind:key="item.id">{{ item.name }}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardSelect",
  props: ["fund"],
  data() {
    return {
      optionsSectors: [],
      optionsLevels: [],
      sectors: [],
      region: 'All',
      sectorId: 'All',
      risk: 'All',
    };
  },
  mounted() {
    this.$store.commit("toggleFilter", {sector: this.sectorId, risk: this.risk});
    this.setData();
  },
  methods: {
    toggleFilter() {
      this.$store.commit("toggleFilter", {sector: this.sectorId, risk: this.risk});
      this.setData();
    },
    setData() {
      if (!this.fund.investments)
        return false;
      const options = []
      this.optionsSectors = []
      this.optionsLevels = [];
      this.fund.investments.map((item) => {
        if (options.indexOf(item.sector_name) < 0) {
          if (this.risk === 'All') {
            this.optionsSectors.push({"name": item.sector_name, "id": item.sector_id});
            options.push(item.sector_name);
          } else {
            if (item.risk === this.risk) {
              this.optionsSectors.push({"name": item.sector_name, "id": item.sector_id});
              options.push(item.sector_name);
            }
          }
        }
        if (options.indexOf(item.risk) < 0) {
          if (this.sectorId === 'All') {
            this.optionsLevels.push({"name": item.risk, "id": item.risk});
            options.push(item.risk);
          } else {
            if (item.sector_id === this.sectorId) {
              this.optionsLevels.push({"name": item.risk, "id": item.risk});
              options.push(item.risk);
            }
          }
        }
      });
    }
  },
  watch: {
    fund() {
      this.setData();
    },
    sectorId() {
      this.toggleFilter();
    },
    risk() {
      this.toggleFilter();
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  .holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .option {
      width: 48%;
      .md-field {
        margin: 0;
      }
    }
  }
}
</style>