<template>
  <main id="app-holder" :class="fund.investments && fund.investments.length < 1 ? 'start' : ''">
    <Bar />
    <div>
      <div class="line">
        <div class="util">
          <CardPieSector :data="data" :investments="investments" />
          <CardAddInvestment />
          <CardInvestments :fund="fund" :data="data" :investments="investments" />
        </div>
        <div class="column-holder">
          <div class="columns">
            <div class="column">
              <CardSelect :fund="fund" />
              <CardTheFund :fund="fund" :investments="investments" />
              <CardPieEsgReview :data="data" :investments="investments" />
              <CardMap :data="data" :investments="investments" />
            </div>
            <div class="column">
              <CardBubble :fund="fund" :data="data" :investments="investments" />
              <CardLibrary />
              <CardLogos />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import Bar from './../components/commom/Bar';
import CardLibrary from '../components/dashboard/CardLibrary';
import CardTheFund from '../components/dashboard/CardTheFund';
import CardBubble from '../components/dashboard/CardBubble';
import CardPieEsgReview from '../components/dashboard/CardPieEsgReview';
import CardPieSector from '../components/dashboard/CardPieSector';
import CardSelect from '../components/dashboard/CardSelect';
import CardAddInvestment from '../components/dashboard/CardAddInvestment';
import CardMap from '../components/dashboard/CardMap';
import CardInvestments from '../components/dashboard/CardInvestments';
import CardLogos from '../components/dashboard/CardLogos';

export default {
  name: "Dashboard",
  components: {
    Bar,
    CardLibrary,
    CardAddInvestment,
    CardTheFund,
    CardPieEsgReview,
    CardBubble,
    CardPieSector,
    CardSelect,
    CardInvestments,
    CardMap,
    CardLogos,
  },
  data() {
    return {
      investmentId: null,
      data: this.$store.state.data,
      investments: this.$store.state.fund.investments,
      fund: this.$store.state.fund,
    };
  },
  mounted() {
    this.$store.commit("loadData");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "selectFund") {
        this.data = state.data;
        if (state.fund) {
          this.fund = state.fund
          this.investments = state.fund.investments
        }
      }
      if (mutation.type === "toggleFilter") {
        if (!state.fund.investments || !state.data)
          return false;
        let investments = state.fund.investments;
        if (state.filterHome.sector !== 'All') {
          investments = investments.map((item) => {
            if (item.sector_id === state.filterHome.sector)
              return item;
          }).filter(item => item !== undefined);
        }
        if (state.filterHome.risk !== 'All') {
          investments = investments.map((item) => {
            if (item.risk === state.filterHome.risk)
              return item;
          }).filter(item => item !== undefined);
        }
        this.investments = investments
      }
    });
  },
  watch: {
    investmentId() {
      router.push(`/dashboard/`)
    }
  }
};
</script>

<style lang="scss" scoped>
.no-padding {
  padding: 0;
}
.util {
  width: 25vw;
  margin-right: 10px;
  flex-wrap: wrap;
  display: flex;
  position: fixed;

  @media (max-width: 1366px) {
    position: relative;
    min-width: 400px;
    display: block;
  }

  @media (max-width: 789px) {
    position: relative;
    min-width: 100%;
    display: block;
  }
}
#app-holder {
  background: rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  .md-card {
    width: 100%;
    margin-bottom: 10px;
  }
  .line {
    display: flex;
    min-height: calc(100vh - 59px);
    margin: 0 10px;
    @media (max-width: 789px) {
      flex-wrap: wrap;
    }
    .column-holder {
      margin-left: calc(25vw + 10px);
      flex-wrap: wrap;
      width: 100%;

      @media (max-width: 1366px) {
        margin-left: 0;
      }

      .columns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1240px) {
          flex-wrap: wrap;
          .column {
            max-width: inherit;
            width: 100%;
            margin: 0;
          }
        }
      }
      .column {
        &:first-child {
          margin-left: 0;
        }
        margin-left: 10px;

        width: 100%;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
