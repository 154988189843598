<template>
  <div>
    <md-tabs md-alignment="fixed" md-sync-route>
      <md-tab id="tab-home" md-label="Quick analysis" to="/admin/review/quick-analysis/" exact>
        <Quick />
      </md-tab>
      <md-tab id="admin-review-indepth" md-label="In-depth analysis" to="/admin/review/indepth-analysis/" exact>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Quick from './Quick';

export default {
  name: "Form",
  components: {
    Quick
  },
  data() {
    return {
      editor: null,
      field: '',
      message: "",
      user: this.$store.state.user,
      showSnackbar: false,
    }
  },
  mounted() {
    this.editor = ClassicEditor;
    this.field = this.$store.state.content.value;
  },
  methods: {
    save() {
      this.$store.commit('toggleLoading')
      axios
        .post(`${window.api}content/${this.$store.state.content.id}/update/?jwt=${this.user.token}`, {"value": this.field})
        .then(() => {
          this.$store.commit('toggleLoading')
          this.message = "Content updated";
          this.showSnackbar = true;
        })
        .catch(() => {
          this.$store.commit('toggleLoading')
          this.message = "Something went wrong, try again later.";
          this.showSnackbar = true;
        });
    }
  }
};
</script>

<style lang="scss">
.md-tabs-content {
  height: auto !important;
  overflow-y: visible !important;
}
</style>
