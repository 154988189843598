<template>
  <main id="home">
    <span class="overlay"></span>
    <aside>
      <img src="/images/fmo+50+blue-okerlarge.png" />
      <CreateAccountForm />
      <img src="@/assets/logo-steward-redqueen.png" />
    </aside>
  </main>
</template>

<script>
import CreateAccountForm from "@/components/CreateAccountForm.vue";

export default {
  name: "createAccount",
  components: {
    CreateAccountForm,
  },
};
</script>

<style lang="scss" scoped>
.createAccount {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
