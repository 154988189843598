<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Company ESG Performance</div>
      <div class="filter">
        <md-field>
          <md-select id="sector" v-model="type">
            <md-option value="esms">E&S Risk Management</md-option>
            <md-option value="bi">Business Integrity</md-option>
          </md-select>
        </md-field>
        <md-icon>
          info
          <md-tooltip md-direction="top" v-if="data.content" v-html="data.content['Info: Bubble chart']"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="holder">
        <span class="vertical" v-show="type === 'esms'">E&S Risk Management Score</span>
        <span class="vertical" v-show="type === 'bi'">BI Risk Management Score</span>
        <vue-plotly :autoResize="true" :options="options" :data="chartData" :layout="layout"/>
        <span class="horizontal" v-show="type === 'esms'">E&S Risk</span>
        <span class="horizontal" v-show="type === 'bi'">BI Integrity Risks</span>
      </div>
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  display: flex;
  justify-content: space-between;
  .md-title {
    margin: 0;
  }
  .filter {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
    .md-field {
      margin: 0; 
      min-height: 32px;
      padding-top: 0;
    }
  }
}
.md-card-content {
  min-height: 396px;
}
.vertical {
  writing-mode: vertical-rL;
  text-orientation: sideways;
  position: absolute;
  text-align: center;
  height: 312px;
  left: 15px;
  transform: rotate(180deg);
  transform-origin: center;
}
.holder {
  margin-left: 30px;
}
</style>

<script>
import VuePlotly from '@statnett/vue-plotly'

export default {
  name: "CardBubble",
  props: ["data", "investments", "fund"],
  components: {
    VuePlotly
  },
  watch: {
    type() {
      this.getChartData();
    },
    fund() {
      this.getChartData();
    },
    investments() {
      this.getChartData();
    },
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    getBiRisk(investment) {
      this.data.sectors.map((sector) => {
        if (investment.sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (investment.subsector_id === subsector.id)
              this.subsectorData = subsector;
          });
        }
      });
      this.data.countries.map((country) => {
        if (investment.country_id === country.id) {
          this.countryTi = country.ti;
        }
      });
      const bi = this.subsectorData["sector corruption risk"];
      let riskContryTi = 'Low'
      if (this.countryTi < 20) {
        riskContryTi = 'High'
      }
      if (this.countryTi < 70) {
        riskContryTi = 'Medium'
      }
      if ((bi === 'medium' || bi === 'low') && riskContryTi === 'High')
        return 'High';
      if (bi === 'low')
        return riskContryTi;
      return bi
    },
    getChartData() {
      if (!this.investments || !this.fund)
        return false;
      this.chartData = [];
      let total = 0;
      const elow = []
      const low = []
      const medium = []
      const high = [];
      this.currency = this.fund.currency === 0 ? '€' : '$';
      this.currencyName = this.fund.currency === 0 ? 'eur' : 'usd';
      const investmentsArr = []
      this.investments.map((item) => {
        if (typeof item.amount === 'string')
          item.amount = JSON.parse(item.amount);
        if (!item.risk || !item.review || (item.review && (item.review.done_quick === 0 && item.review.done_indeepth === 0)))
          return;
        if (!item.reviewScore || (!item.reviewScore.total && !item.reviewScore.esms && !item.reviewScore.corporate && !item.reviewScore.bi))
          return;
        total += parseInt(item.amount[this.currencyName]);
        investmentsArr.push(item);
        item.biRisk = this.getBiRisk(item)
      });
      investmentsArr
      .sort((a, b) => {
        return a.amount[this.currencyName] - b.amount[this.currencyName]
      })
      .reverse()
      .map((item) => {
        if (typeof item.amount === 'string')
          item.amount = JSON.parse(item.amount)
        item.amountFormat = parseInt(item.amount[this.currencyName]).toLocaleString(undefined, {minimumFractionDigits: 0});
        let size = 0;
        let scoreChart = ''
        switch (this.type) {
          case 'esms':
            scoreChart = item.reviewScore.esms.total.toFixed(0)
            break;
          case 'bi':
            scoreChart = item.reviewScore.bi.total.toFixed(0)
            break;
        }
        size = (((item.amount[this.currencyName] / total) * 100) * 1.3)
        if (this.type === 'esms')
          switch (item.risk.trim()) {
            case 'Low':
              low.push(
                {
                  x: ['Low'],
                  y: [scoreChart],
                  name: 'Low',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  hoverinfo: 'text',
                  textposition: 'middle center',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  line: {
                    color: '#000',
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#869c66'],
                    line: {
                      color: '#FFF',
                      width: 1,
                    },
                  }
                }
              )
              break;
            case 'Medium':
              medium.push(
                {
                  x: ['Medium'],
                  y: [scoreChart],
                  name: 'Medium',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  textposition: 'middle center',
                  hoverinfo: 'text',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#ff9502'],
                    line: {
                      color: '#FFF',
                      width: 1,
                    },
                  }
                }
              )
              break;
            case 'High':
              high.push(
                {
                  x: ['High'],
                  y: [scoreChart],
                  name: 'High',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  textposition: 'middle center',
                  hoverinfo: 'text',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#d43501'],
                    line: {
                      color: '#fff',
                      width: 1,
                    },
                  }
                }
              )
              break;
          }
        if (this.type === 'bi')
          switch (item.risk.trim()) {
            case 'extremely low':
              elow.push(
                {
                  x: ['Extremely Low'],
                  y: [scoreChart],
                  name: 'Low',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  hoverinfo: 'text',
                  textposition: 'middle center',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  line: {
                    color: '#000',
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#869c66'],
                    line: {
                      color: '#FFF',
                      width: 1,
                    },
                  }
                }
              )
              break;
            case 'Low':
              low.push(
                {
                  x: ['Low'],
                  y: [scoreChart],
                  name: 'Low',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  hoverinfo: 'text',
                  textposition: 'middle center',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  line: {
                    color: '#000',
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#869c66'],
                    line: {
                      color: '#FFF',
                      width: 1,
                    },
                  }
                }
              )
              break;
            case 'Medium':
              medium.push(
                {
                  x: ['Medium'],
                  y: [scoreChart],
                  name: 'Medium',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  textposition: 'middle center',
                  hoverinfo: 'text',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#ff9502'],
                    line: {
                      color: '#FFF',
                      width: 1,
                    },
                  }
                }
              )
              break;
            case 'High':
              high.push(
                {
                  x: ['High'],
                  y: [scoreChart],
                  name: 'High',
                  text: [`<b>${item.name}</b><br>Amount: ${this.currency} ${item.amountFormat}<br>Score: ${scoreChart}`],
                  mode: 'markers',
                  type: 'scatter',
                  textposition: 'middle center',
                  hoverinfo: 'text',
                  hoverlabel: {
                    namelength: -1,
                    bgcolor: "#FFF"
                  },
                  marker: {
                    size: size < 10 ? 10 : size,
                    color: ['#d43501'],
                    line: {
                      color: '#fff',
                      width: 1,
                    },
                  }
                }
              )
              break;
          }
      });
      if (elow.length < 1)
        elow.push(
          {
            x: ['Extremely Low'],
            y: [-10],
            name: 'Extremely Low',
            text: [''],
            mode: 'markers',
            type: 'scatter',
            hoverinfo: 'none',
            marker: {
              size: 0
            }
          }
        );
      if (low.length < 1)
        low.push(
          {
            x: ['Low'],
            y: [-10],
            name: 'Low',
            text: [''],
            mode: 'markers',
            type: 'scatter',
            hoverinfo: 'none',
            marker: {
              size: 0
            }
          }
        );
      if (medium.length < 1)
        medium.push(
          {
            x: ['Medium'],
            y: [-10],
            name: 'Medium',
            text: [''],
            mode: 'markers',
            type: 'scatter',
            hoverinfo: 'none',
            marker: {
              size: 0
            }
          }
        );
      if (high.length < 1)
        high.push(
          {
            x: ['High'],
            y: [-10],
            name: 'High',
            text: [''],
            mode: 'markers',
            type: 'scatter',
            hoverinfo: 'none',
            marker: {
              size: 0
            }
          }
        );
      if (this.type === 'esms')
        this.chartData = [...low, ...medium, ...high];
      if (this.type === 'bi')
        this.chartData = [...elow, ...low, ...medium, ...high];
    }
  },
  data() {
    return {
      options: {
        autosizable: true,
        responsive: true,
        doubleclick: false,
        click: false,
      },
      type: 'esms',
      currency: '',
      currencyName: '',
      chartData: [],
      layout: {
        height: 330,
        yaxis: {
          range: [0, 100],
        },
        paper_bgcolor: "transparent",
        margin: {"t": 50, "b": 20, "l": 20, "r": 0},
        showlegend: false
      },
    }
  }
}
</script>