<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Sector opportunities</div>
    </md-card-header>
    <md-card-content v-if="item && opportunities">
      <ul v-if="opportunities.length > 0">
        <li v-for="(opportunity, index) in opportunities"  v-bind:key="index">
          {{opportunity}}
        </li>
      </ul>
      <p v-if="opportunities.length === 0">
        No opportunities for this sector.
      </p>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardSectorOpportunities",
  props: ["item", "data"],
  data() {
    return {
      subsectorData: {},
      opportunities: [],
    }
  },
  watch: {
    data() {
      this.getData();
    },
    item() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.data.sectors.map((sector) => {
        if (this.item.sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (this.item.subsector_id === subsector.id)
              this.subsectorData = subsector;
          });
        }
      });
      ['a', 'b', 'c', 'd'].map((letter) => {
        if (this.subsectorData[`Opportunity ${letter}`] && this.subsectorData[`Opportunity ${letter}`].length > 1)
          this.opportunities.push(this.subsectorData[`Opportunity ${letter}`]);
      })
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.md-card-header {
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
}
.md-card-content {
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
    li {
      display: block;
      padding: 10px 20px;
      text-align: center;
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  p {
    padding: 15px 0;
    text-align: center;
  }
}
</style>