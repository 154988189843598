<template>
  <main>
    <HeaderColumnsHigherCG />
    <div class="block" :class="{'active': step === 0}">
      <span class="title">
        <span>Commitment to CG</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('CommitmentCG', 's1', 0)" :class="{'active': answers.CommitmentCG === 0}">
          <span>
            Basic
          </span>
        </span>
        <span class="button" @click="toggle('CommitmentCG', 's1', 1)" :class="{'active': answers.CommitmentCG === 1}">
          <span class="short">
            Developing
          </span>
          <span class="long">
            There is a charter with basic corporate governance articles including wording on minority shareholder protection. Authority of CG is described, and information is disclosed with regular intervals.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            There is a charter with basic corporate governance articles including wording on minority shareholder protection. Authority of CG is described, and information is disclosed with regular intervals.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('CommitmentCG', 's1', 2)" :class="{'active': answers.CommitmentCG === 2}">
          <span class="short">
            Developed
          </span>
          <span class="long">
            The Board has approved a written code of CG, as well as the annual calendar of events, and CG disclosures to the shareholders. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            The Board has approved a written code of CG, as well as the annual calendar of events, and CG disclosures to the shareholders. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('CommitmentCG', 's1', 3)" :class="{'active': answers.CommitmentCG === 3}">
          <span class="short">
            Advanced
          </span>
          <span class="long">
            There is a designated CG officer and the corporate governance is evaluated against external benchmarks of best practices, and disclosed. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            There is a designated CG officer and the corporate governance is evaluated against external benchmarks of best practices, and disclosed. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 1}">
      <span class="title">
        <span>Board Structure and Functioning</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('BoardStructureFunctioning', 's2', 0)" :class="{'active': answers.BoardStructureFunctioning === 0}">
          <span>
            Basic
          </span>
        </span>
        <span class="button" @click="toggle('BoardStructureFunctioning', 's2', 1)" :class="{'active': answers.BoardStructureFunctioning === 1}">
          <span class="short">
            Developing
          </span>
          <span class="long">
            Board meetings are held regularly and with adequate upfront notice and information sharing. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            Board meetings are held regularly and with adequate upfront notice and information sharing. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BoardStructureFunctioning', 's2', 2)" :class="{'active': answers.BoardStructureFunctioning === 2}">
          <span class="short">
            Developed
          </span>
          <span class="long">
            Board meetings are scheduled and held regularly. The Board composition considers adequacy of the skills mix, and has various committees established. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            Board meetings are scheduled and held regularly. The Board composition considers adequacy of the skills mix, and has various committees established. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BoardStructureFunctioning', 's2', 3)" :class="{'active': answers.BoardStructureFunctioning === 3}">
          <span class="short">
            Advanced
          </span>
          <span class="long">
            Directors are elected regularly, a majority of whom are independent, and one of whom chairs the Audit Committee. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            Directors are elected regularly, a majority of whom are independent, and one of whom chairs the Audit Committee. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 2}">
      <span class="title">
        <span>Control Environment and Processes</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('ControlEnvironmentProcesses', 's3', 0)" :class="{'active': answers.ControlEnvironmentProcesses === 0}">
          <span>
            Basic
          </span>
        </span>
        <span class="button" @click="toggle('ControlEnvironmentProcesses', 's3', 1)" :class="{'active': answers.ControlEnvironmentProcesses === 1}">
          <span class="short">
            Developing
          </span>
          <span class="long">
            There is an internal audit function which reviews the internal control system regularly. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            There is an internal audit function which reviews the internal control system regularly. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ControlEnvironmentProcesses', 's3', 2)" :class="{'active': answers.ControlEnvironmentProcesses === 2}">
          <span class="short">
            Developed
          </span>
          <span class="long">
            The internal control system and the audit function are in line with national standards. The Board monitors risk and compliance. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            The internal control system and the audit function are in line with national standards. The Board monitors risk and compliance. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ControlEnvironmentProcesses', 's3', 3)" :class="{'active': answers.ControlEnvironmentProcesses === 3}">
          <span class="short">
            Advanced
          </span>
          <span class="long">
            There is a comprehensive compliance programme, and an audit function overseen by an Audit Committee
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            There is a comprehensive compliance programme, and an audit function overseen by an Audit Committee
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 3}">
      <span class="title">
        <span>Transparency and Disclosure</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('TransparencyDisclosure', 's4', 0)" :class="{'active': answers.TransparencyDisclosure === 0}">
          <span>
            Basic
          </span>
        </span>
        <span class="button" @click="toggle('TransparencyDisclosure', 's4', 1)" :class="{'active': answers.TransparencyDisclosure === 1}">
          <span class="short">
            Developing
          </span>
          <span class="long">
            Financial statements follow national accounting standards, and are externally audited. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            Financial statements follow national accounting standards, and are externally audited. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('TransparencyDisclosure', 's4', 2)" :class="{'active': answers.TransparencyDisclosure === 2}">
          <span class="short">
            Developed
          </span>
          <span class="long">
            The company discloses additional information on affiliated parties. All communication to shareholders is available online, and in a timely fashion. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            The company discloses additional information on affiliated parties. All communication to shareholders is available online, and in a timely fashion. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('TransparencyDisclosure', 's4', 3)" :class="{'active': answers.TransparencyDisclosure === 3}">
          <span class="short">
            Advanced
          </span>
          <span class="long">
            Financial statements follow international accounting standards, includes information on remuneration and beneficial owners, and rotates its external auditors regularly. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            Financial statements follow international accounting standards, includes information on remuneration and beneficial owners, and rotates its external auditors regularly. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 4}">
      <span class="title">
        <span>Shareholder Rights</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('ShareholderRights', 's5', 0)" :class="{'active': answers.ShareholderRights === 0}">
          <span>
            Basic
          </span>
        </span>
        <span class="button" @click="toggle('ShareholderRights', 's5', 1)" :class="{'active': answers.ShareholderRights === 1}">
          <span class="short">
            Developing
          </span>
          <span class="long">
            Minority shareholders can participate in, and vote at such shareholders' meetings. All shareholders of the same class are treated equally. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            Minority shareholders can participate in, and vote at such shareholders' meetings. All shareholders of the same class are treated equally. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ShareholderRights', 's5', 2)" :class="{'active': answers.ShareholderRights === 2}">
          <span class="short">
            Developed
          </span>
          <span class="long">
            There are mechanisms for effective representation of minority shareholders. There is a policy and process for full and timely information dissemination to all shareholders. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            There are mechanisms for effective representation of minority shareholders. There is a policy and process for full and timely information dissemination to all shareholders. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('ShareholderRights', 's5', 3)" :class="{'active': answers.ShareholderRights === 3}">
          <span class="short">
            Advanced
          </span>
          <span class="long">
            The voting mechanisms protect minority shareholders against prejudiced actions of controlling shareholders. There are policies regulating treatment of minority shareholders in changes of control situations. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            The voting mechanisms protect minority shareholders against prejudiced actions of controlling shareholders. There are policies regulating treatment of minority shareholders in changes of control situations. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised" @click="previousSection()">Previous</md-button>
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Next</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="nextSection()">Next</md-button>
    </div>
  </main>
</template>

<script>
import HeaderColumnsHigherCG from '../HeaderColumnsHigherCG';

export default {
  name: "corporate",
  components: {
    HeaderColumnsHigherCG,
  },
  data() {
    return {
      step: 0,
      answers: [],
      expandStatus: this.$store.state.expandStatus,
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
      disabled: true,
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
    const check = this.review.higher.filter((item) => item.level === "corporate")
    this.step = check.length;
    if (this.step === 4)
      this.step = 0;
  },
  methods: {
    toggle(key, section, index) {
      this.$store.commit("answerReview", {level: "corporate", type: "higher", key: key, value: index, section: section});
      const check = this.review.higher.filter((item) => item.level === "corporate")
      this.step = check.length;
    },
    assembleAnswers() {
      this.answers = []
      if (this.review.higher)
        this.review.higher.map((item) => {
          this.answers[item.key] = item.value;
        });
      if (this.reviewCount.higher.corporate >= 5) {
        this.disabled = false;
      }
    },
    nextSection() {
      this.$store.commit("toggleSection", {type: "higher", section: 2})
    },
    previousSection() {
      this.$store.commit("toggleSection", {type: "higher", section: 0})
    }
  }
}
</script>
