<template>
  <div id="app">
    <router-view />
    <Loading />
    <Feedback />
  </div>
</template>

<script>
window.api = '//api.esg-toolkit.com/';

if (window.location.href.indexOf('staging') > 0)
  window.api = '//ec2-3-125-40-39.eu-central-1.compute.amazonaws.com:3000/';

if (window.location.href.indexOf('localhost') > 0 || window.location.href.indexOf('127.0.0.1') > 0)
  // window.api = 'https://api.esg-toolkit.com/';
  window.api = 'http://127.0.0.1:3000/';

import Vue from "vue";
import VueMaterial from "vue-material";
import VueMoment from 'vue-moment'
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css"; 
import Loading from './components/commom/Loading';
import Feedback from './components/commom/Feedback';
import moment from 'moment-timezone'
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from "axios";

Vue.use(CKEditor);
Vue.use(VueMaterial);
Vue.use(VueMoment, { moment });

export default {
  components: {
    Loading,
    Feedback,
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  mounted() {
    if (this.loggedUser)
      axios
        .post(
          `${window.api}refresh-token/?jwt=${this.user.token}`, 
          {email: this.user.email}
        ).then((response) => {
          this.$store.commit("updateToken", response.data.token);
        })
    setInterval(() => {
      if (this.$store.state.user.token)
        axios
          .post(
            `${window.api}refresh-token?jwt=${this.$store.state.user.token}`,
            {email: this.$store.state.user.email}
          )
          .then((response) => {
            this.$store.commit("updateToken", response.data.token);
          })
    }, 60000)
  }
};
</script>

<style lang="scss">
@import "../node_modules/vue-search-select/dist/VueSearchSelect.css";
@import "../node_modules/vue-wysiwyg-lite/dist/vueWysiwyg.css";
@import "../node_modules/@ag-grid-community/all-modules/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Montserrat|Open+Sans&display=swap");
@viewport {
  min-zoom: 0.8;
}
p,
li {
  color: #333;
}
[data-f-id="pbf"] {
  display: none !important;
}
.modebar-container {
  display: none;
}
#app-holder {
  padding-top: 60px;
}
.md-app {
  height: 100vh;
}
.wrapper {
  max-width: 1480px;
  margin: 40px auto;
  padding: 0 30px;
}
.breadcrumbs {
  text-align: left;
  margin-bottom: 20px;
}
.md-title {
  text-align: left;
  font-size: 30px;
}
.md-tooltip {
  max-width: 300px;
  white-space: inherit;
  height: auto;
  font-size: 12px;
  &.big {
    max-width: 500px;
  }
  li,
  p {
    color: #fff;
  }
}
.js-plotly-plot .plotly .cursor-crosshair {
  cursor: default !important;
}
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.md-card-header:first-child>.md-title:first-child {
  margin-top: 0;
}
.md-overlay {
  bottom: inherit;
  height: 100vh;
}
.md-button-content {
  z-index: 10 !important;
}
.md-ripple {
  -webkit-mask-image: none !important;
}
button.md-primary {
  background: rgb(2, 84, 149) !important;
  color: #fff !important;
}
.md-theme-default a:not(.md-button) {
  color: rgb(2, 84, 149) !important;
}
.md-progress-bar.md-theme-default.md-determinate .md-progress-bar-fill {
  background: rgb(2, 84, 149) !important;
}
.md-icon.md-theme-default.md-icon-font.red {
  color: #B00020;
}
.md-icon.md-theme-default.md-icon-font.green {
  color: #03DAC6;
}
.md-card .md-title {
  font-size: 20px;
}
.md-dialog-title {
  button {
    min-width: inherit;
    position: absolute;
    top: 14px;
    right: 3px;
  }
}

.currency .md-switch.md-theme-default {
  .md-switch-container {
    background: rgba(0,0,0,0.38);
  }
  &.md-checked {
    .md-switch-thumb {
      background: #f5f5f5 !important;
    }
  }
}

#home {
  height: 100vh;
  width: 100vw;
  background:rgba(0,0,0,0.4);
  .overlay {
    background: url(/images/20151006_081.jpg) center;
    background-size: cover;
    opacity: 0.4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  aside {
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    max-width: 400px;
    padding: 20px 25px;
    flex-wrap: wrap;
    display: flex;
    align-content: space-between;

    img {
      max-height: 130px;
      max-width: 70%;
      margin: 0 auto;
      display: block;
    }
  }
}
  .option-archived {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(0,0,0,0.3);
    padding: 10px 0;
    min-width: 350px;

    &:first-child {
      border-top: 0;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
    }

    button {
      margin: 0;
    }
  }
</style>
