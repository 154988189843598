<template>
  <md-card v-if="fund && fund.name">
    <md-card-content>
      <div class="md-title">
        {{ fund.name }}
      </div>
      <div class="holder">
        <div>
          {{count}}
          <small>Investments</small>
        </div>
        <div>
          {{currency}} {{total}}
          <small>Investment volume</small>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardTheFund",
  props: ["investments", "fund"],
  data() {
    return {
      total: 0,
      count: 0,
      currencyName: '',
      currency: '',
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      if (!this.investments || !this.fund)
        return false;
      this.total = 0;
      this.currencyName = this.fund.currency === 0 ? 'eur' : 'usd';
      this.currency = this.fund.currency === 0 ? '€' : '$';
      this.investments.map((item) => {
        if (typeof item.amount === 'string')
          item.amount = JSON.parse(item.amount)
        this.total = parseFloat(this.total) + parseFloat(item.amount[this.currencyName])
      });
      this.total = parseInt(this.total).toLocaleString(undefined, {minimumFractionDigits: 0});
      this.count = this.investments.length;
    }
  },
  watch: {
    investments() {
      this.setData();
    },
    fund() {
      this.setData();
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  color: rgb(2, 84, 149);
  .md-card-content {
    padding: 14px;
  }
  .md-title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
  }
  .holder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    text-transform: uppercase;
    div {
      width: 100%;
      font-size: 26px;
      font-weight: bold;
      small {
        display: block;
        color: #444444;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
</style>