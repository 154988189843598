<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="save">
      <md-field>
        <label>Content</label>
        <ckeditor v-if="editor" id="edit" :editor="editor" v-model="field"></ckeditor>
      </md-field>
      <div class="md-layout-item">
        <md-button to="/admin/users" class="back">Back</md-button>
        <md-button type="submit" class="md-primary md-raised save">Update</md-button>
      </div>
      <md-snackbar
        md-position="center"
        :md-active.sync="showSnackbar"
        md-persistent>
        <span>{{ message }}</span>
        <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "Form",
  data() {
    return {
      editor: null,
      field: '',
      message: "",
      user: this.$store.state.user,
      showSnackbar: false,
    }
  },
  mounted() {
    this.editor = ClassicEditor;
    this.field = this.$store.state.content.value;
  },
  methods: {
    save() {
      this.$store.commit('toggleLoading')
      axios
        .post(`${window.api}content/${this.$store.state.content.id}/update/?jwt=${this.user.token}`, {"value": this.field})
        .then(() => {
          this.$store.commit('toggleLoading')
          this.message = "Content updated";
          this.showSnackbar = true;
        })
        .catch(() => {
          this.$store.commit('toggleLoading')
          this.message = "Something went wrong, try again later.";
          this.showSnackbar = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.back {
  float: left;
}
.save {
  float: right;
}
</style>
