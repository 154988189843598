<template>
  <md-card class="add">
    <md-card-content class="no-padding">
      <md-button to="/my-investments/new">
        <md-icon>add</md-icon>
        New investment
      </md-button>
    </md-card-content>
  </md-card>
</template>

<style lang="scss" scoped>
.md-card {
  height: 58px;
  .md-card-content {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      height: 58px;
      width: 100%;
      margin: 0;
      font-size: 16px;
    }
  }
}
</style>

<script>
export default {
  name: "CardAddInvestment"
}
</script>