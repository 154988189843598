<template>
  <div class="md-layout-item md-small-size-100">
    <form id="validateFeedbackBug" novalidate class="md-layout" @submit.prevent="validateForm" autocomplete="off">
      <md-field :class="getValidationClass('title')">
        <label for="bug-topic">Topic</label>
        <md-input name="bug-topic" v-model="form.title" id="bug-topic" />
        <span class="md-error" v-if="!$v.form.title.required">The topic is required</span>
      </md-field>
      <md-field :class="getValidationClass('text')">
        <label>Description of the idea</label>
        <md-textarea v-model="form.text" md-autogrow></md-textarea>
        <span class="md-error" v-if="!$v.form.text.required">The description is required</span>
      </md-field>
      <md-field :class="getValidationClass('name')">
        <label for="bug-name">Name</label>
        <md-input name="bug-name" v-model="form.name" id="bug-name" />
        <span class="md-error" v-if="!$v.form.name.required">The name is required</span>
      </md-field>
      <md-field :class="getValidationClass('email')">
        <label for="bug-email">E-mail</label>
        <md-input name="bug-email" v-model="form.email" id="bug-email" />
        <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
        <span class="md-error" v-if="!$v.form.email.email">The email should be valid</span>
      </md-field>
      <md-button type="submit" class="md-primary">Submit</md-button>
    </form>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: 'FeedbackIdea',
  mixins: [validationMixin],
  data() {
    return {
      showSnackbar: false,
      message: '',
      modalStatus: false,
      form: {
        name: '',
        email: '',
        text: '',
        title: '',
        type: 1
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      text: {
        required,
      },
      title: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.save()
      }
    },
    save() {
      this.$store.commit("toggleLoading")
      axios
        .post(`${window.api}feedbacks/create/`, this.form)
        .then(() => {
          this.$store.commit("toggleLoading")
          this.message = "Thanks for submiting your feedback!";
          this.showSnackbar = true;          
          this.$v.$reset()        
          this.form.name = ''
          this.form.email = ''
          this.form.text = ''
          this.form.title = ''
        })
        .catch(() => {
          this.message = "Something went wrong creating the feedback";
          this.showSnackbar = true;       
          this.$store.commit("toggleLoading")   
        });
    },
  },
}
</script>
