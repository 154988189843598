<template>
  <div>
    <md-tabs md-alignment="fixed">
      <md-tab id="tab-home" md-label="Environmental and Social">
        <Session session="Quick" step="Environmental and Social" />
      </md-tab>
      <md-tab id="admin-review-quick-analysis-corporate-governance" md-label="Corporate governance">
      </md-tab>
      <md-tab id="admin-review-quick-analysis-business-integrity" md-label="Business Integrity">
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import Session from './Session';

export default {
  name: "Quick",
  components: {
    Session
  }
};
</script>

<style lang="scss" scoped>
.back {
  float: left;
}
.save {
  float: right;
}
</style>
