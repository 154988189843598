<template>
  <main>
    <div class="buttons tabs">
      <button :class="{'active': section === 0}" @click="toggle(0)">
        Environmental and Social
        <md-progress-bar md-mode="determinate" :md-value="amountEsms"></md-progress-bar>
      </button>
      <button :class="{'active': section === 1}" @click="toggle(1)">
        Corporate Governance
        <md-progress-bar md-mode="determinate" :md-value="amountCorporate"></md-progress-bar>
      </button>
      <button :class="{'active': section === 2}" @click="toggle(2)">
        Business Integrity
        <md-progress-bar md-mode="determinate" :md-value="amountBi"></md-progress-bar>
      </button>
    </div>
    <esms v-if="section === 0" />
    <corporate v-if="section === 1 && investment.is_sme === 0" />
    <smecorporate v-if="section === 1 && investment.is_sme === 1" />
    <bi v-if="section === 2" />
    <md-dialog :md-active.sync="threshold">
      <md-dialog-title>N/A threshold</md-dialog-title>
      <md-dialog-content>
        <p>
          You just reached the limit on N/A answers.
        </p>
        <div class="options">
          <md-button class="md-raised" @click="threshold = false">
            Dismiss
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </main>
</template>

<script>
import esms from "@/components/review/lower/esms";
import corporate from "@/components/review/lower/corporate";
import smecorporate from "@/components/review/lower/SmeCorporate";
import bi from "@/components/review/lower/bi";

export default {
  name: "LowerLevel",
  components: {
    esms,
    corporate,
    smecorporate,
    bi,
  },
  data() {
    return {
      investment: this.$store.state.investment,
      threshold: this.$store.state.thresholdNAStatus,
      section: this.$store.state.reviewSection.lower,
      amountEsms: (this.$store.state.reviewCount.lower.esms / 24) * 100,
      amountCorporate: 0,
      amountBi: (this.$store.state.reviewCount.lower.bi / 20) * 100, 
    }
  },
  methods: {
    toggle(section) {
      this.$store.commit('toggleSection', {'type': 'lower', 'section': section})
    }
  },
  mounted() {
    this.amountCorporate = (this.$store.state.reviewCount.lower.corporate / (this.$store.state.investment.is_sme === 1 ? 20 : 13)) * 100;
    this.$store.subscribe((mutation, state) => {
      this.investment = state.investment
      if (mutation.type === "answerReview") {
        this.amountEsms = (this.$store.state.reviewCount.lower.esms / 24) * 100;
        this.amountCorporate = (this.$store.state.reviewCount.lower.corporate / (this.$store.state.investment.is_sme === 1 ? 20 : 13)) * 100;
        this.amountBi = (this.$store.state.reviewCount.lower.bi / 20) * 100;
        this.threshold = state.thresholdNAStatus;
      }
      if (mutation.type === "toggleSection") {
        this.section = state.reviewSection.lower;
        window.scrollTo(0,0)
      }
      if (mutation.type === "startReview") {
        this.section = state.reviewSection.lower;
        this.amountEsms = (this.$store.state.reviewCount.higher.esms / 7) * 100;
        this.amountCorporate = (this.$store.state.reviewCount.higher.corporate / 5) * 100;
        this.amountBi = (this.$store.state.reviewCount.higher.bi / 6) * 100;
      }
    });
  },
}
</script>
