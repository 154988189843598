<template>
  <main>
    <md-card>
      <md-card-header>
        <div class="md-title">Tailored Sector Risk Sources</div>
        <div class="utils">
          <md-icon>
            info
            <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: Tailored Sector Risk Sources']"></md-tooltip>
          </md-icon>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="holder">
          <div class="item" v-if="wbg.length > 0">
            <img src="/images/ifc-logo-5.png"/>
            <div v-for="(guideline, index) in wbg" v-bind:key="index">
              <a :href="guideline.link" target="_blank">
                <span>{{guideline.text}}</span>
              </a>
            </div>
          </div>
          <div class="item" v-if="cdc.length > 0"> 
            <img src="/images/cdc-group-squarelogo-1392052466661.png"/>
            <div v-for="(guideline, index) in cdc" v-bind:key="index">
              <a :href="guideline.link" target="_blank">
                <span>{{guideline.text}}</span>
              </a>
            </div>
          </div>
          <div class="item" v-if="fmo.length > 0"> 
            <img src="@/assets/logo-fmo.png"/>
            <div v-for="(guideline, index) in fmo" v-bind:key="index">
              <a :href="guideline.link" target="_blank">
                <span>{{guideline.text}}</span>
              </a>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </main>
</template>

<script>
export default {
  name: "CardLibrarySector",
  props: ["item"],
  data() {
    return {
      data: this.$store.state.data,
      numbers: [1, 2, 3, 4, 5, 6],
      guidelines: [],
      showFullTable: false,
      wbg: [],
      cdc: [],
      fmo: [],
    }
  },
  watch: {
    item() {
      this.getData();
    },
    data() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.data.sectors.map((sector) => {
        if (this.item.sector_id === sector.id) {
          sector.subsectors.map((subsector) => {
            if (this.item.subsector_id === subsector.id)
              this.subsectorData = subsector;
          })
        }
      });
      this.numbers.map((item) => {
        if (this.subsectorData && this.subsectorData[`WBG Guideline ${item}`]) {
          this.wbg.push({"link": this.subsectorData[`WBG Guideline ${item} hyperlink`], "text": this.subsectorData[`WBG Guideline ${item}`]});
        }
        if (this.subsectorData && this.subsectorData[`CDC Guideline ${item}`]) {
          this.cdc.push({"link": this.subsectorData[`CDC Guideline ${item} hyperlink`], "text": this.subsectorData[`CDC Guideline ${item}`].replace('CDC toolkit - ', '')});
        }
      })
      if (this.subsectorData) {
        if ((this.item.investment_type_id === 2 || this.item.investment_type_id === 3) && this.subsectorData['Check whether investment involves Physical or Economic Displacement, Consultation and/or Grievance requirements.  If yes, PS 5 is applicable'])
          this.fmo.push({"link": '/files/position-statement-land-governance.pdf', "text": "FMO's position statement on Land governance"});
        if (this.subsectorData['Animal welfare'])
          this.fmo.push({"link": `/files/position-statement-animal-welfare.pdf`, "text": "FMO's position statement on Animal welfare"});
        if (this.subsectorData['Worker relationship'])
          this.fmo.push({"link": `/files/position-statement-gender.pdf`, "text": "FMO's position statement on Gender"});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  margin-bottom: 10px;
  .md-card-header {
    background: rgba(2, 84, 149, 0.08);
    border-bottom: 1px solid rgba(2, 84, 149, 0.5);
    display: flex;
    justify-content: space-between;
    .utils {
      display: flex;
      align-items: center;
      i {
        margin-left: 10px;
      }
      
    }
  }
  .md-card-content {
    padding-top: 20px;
    .holder {
      display: flex;
      div {
        width: 50%;
        text-align: center;
        div {
          width: 100%;
          padding: 10px 0;
        }
      }
      img {
        height: 30px;
        filter: grayscale(1);
        margin: 0 auto 20px;
      }
    }
  }
}
.item {
  margin: 10px 0;
} 
.md-dialog {
  .md-dialog-title {
    button {
      min-width: inherit;
      position: absolute;
      top: 14px;
      right: 3px;
    }
    img {
      height: 26px;
      filter: grayscale(1);
    }
  }
  .blocks {
    div {
      width: 33%;
    }
  }
}
</style>
