<template>
  <main>
    <md-list>
      <md-list-item to="/admin">
        <md-icon>home</md-icon>
        <span class="md-list-item-text">Home</span>
      </md-list-item>
      <md-list-item to="/admin/feedbacks">
        <md-icon>feedback</md-icon>
        <span class="md-list-item-text">Feedbacks</span>
      </md-list-item>
      <md-list-item to="/admin/users">
        <md-icon>person</md-icon>
        <span class="md-list-item-text">Users</span>
      </md-list-item>
      <md-list-item to="/admin/content">
        <md-icon>format_align_justify</md-icon>
        <span class="md-list-item-text">Content</span>
      </md-list-item>
      <md-list-item to="/admin/review">
        <md-icon>format_align_justify</md-icon>
        <span class="md-list-item-text">Review</span>
      </md-list-item>
      <md-list-item to="/admin/sectors">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Sectors</span>
      </md-list-item>
      <md-list-item to="/admin/country/data">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Country Data</span>
      </md-list-item>
      <md-list-item to="/admin/country/risk">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Country Risk</span>
      </md-list-item>
      <md-list-item to="/admin/investment/type">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Investment Type</span>
      </md-list-item>
      <md-list-item to="/admin/investment/ps">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Investment PS</span>
      </md-list-item>
      <md-list-item to="/admin/ciri-score">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Ciri Score</span>
      </md-list-item>
      <md-list-item to="/admin/ti-score">
        <md-icon>grid_on</md-icon>
        <span class="md-list-item-text">Ti Score</span>
      </md-list-item>
    </md-list>
  </main>
</template>

<script>
export default {
  name: 'Menu',
}
</script>
