<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Powered by</div>
    </md-card-header>
    <md-card-content>
      <a href="https://www.fmo.nl/" target="_blank">
        <img src="@/assets/logo-fmo.png" />
      </a>
      <a href="http://stewardredqueen.com/" target="_blank">
        <img src="@/assets/logo-steward-redqueen.png" />
      </a>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardLogos",
}
</script>

<style lang="scss" scoped>
.md-card-content {
  padding: 20px 0 28px;
  display: flex;
  justify-content: space-around;
  img {
    height: 30px;
  }
}
</style>
