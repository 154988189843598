<template>
  <main>
    <div class="utils">
      <md-button to="/admin/users/new" class="md-dense md-raised md-primary">Add</md-button>
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
      <md-button :href="`${window.api}public/survey.xlsx?${new Date().getTime()}`" class="md-dense md-raised md-primary" download>Download survey</md-button>
    </div>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Users</h1>
        </div>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No users with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="ID">{{item.id}}</md-table-cell>
        <md-table-cell md-label="Name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="E-mail">{{item.email}}</md-table-cell>
        <md-table-cell md-label="Role">{{item.role === 1 ? 'Admin' : 'Usuário'}}</md-table-cell>
        <md-table-cell md-label="Status">{{item.status === 0 ? 'Inactive' : 'Active'}}</md-table-cell>
        <md-table-cell md-label="">
          <md-button :to="`/admin/users/${item.id}`">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button @click="remove(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)) || toLower(item.email).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    remove(id) {
      if ( confirm('Do you want to remove this user?') ) {
        this.$store.commit('toggleLoading')
        axios
          .get(`${window.api}users/delete/${id}?jwt=${this.user.token}`)
          .then(() => {
            this.$store.commit('toggleLoading')
            this.getData();
          });
      }
    },
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/users/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}users/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
          this.$store.commit('toogleAdminData', {users: this.data})
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
