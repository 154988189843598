<template>
  <main>
    <form id="investmentForm" novalidate class="md-layout" @submit.prevent="validateInvestment" autocomplete="off">
      <div class="third">
        <md-field :class="getValidationClass('name')">
          <label for="name">Investment name</label>
          <md-input autocomplete="none" v-model="form.name" id="name" />
          <span class="md-error" v-if="!$v.form.name.required">The name is required</span>
        </md-field>
        <md-field class="amount" :class="getValidationClass('amount')">
          <label for="amount" class="title">Amount ({{currency}})</label>
          <money v-model="form.amount" v-bind="money" id="amount"></money>
          <span class="md-error" v-if="!$v.form.amount.required">The amount is required</span>
        </md-field>
        <md-field class="amount" :class="getValidationClass('year')">
          <label for="year" class="title">Year</label>
          <md-input autocomplete="none" v-model="form.year" id="year" />
          <span class="md-error" v-if="!$v.form.year.required">The year is required</span>
        </md-field>
      </div>
      <md-field class="funds" v-if="funds.length > 1">
        <label class="title">Funds</label>
        <md-checkbox v-for="item in funds" v-bind:key="item.id" v-model="form.fund_id" :value="item.id">{{item.name}}</md-checkbox>
        <span class="md-error" v-if="form.fund_id.length < 1">The fund is required</span>
      </md-field>
      <div class="half">
        <md-field :class="getValidationClass('investment_stage_id')" class="md-has-value">
          <label for="stage">
            <md-icon>
              info
            </md-icon>
            Investment stage
            <md-tooltip class="big" md-direction="right" v-html="stageInfo"></md-tooltip>
          </label>
          <model-select :options="optionsStage"
            v-model="form.investment_stage_id"
            placeholder="Select stage">
          </model-select>
          <span class="md-error" v-if="!$v.form.investment_stage_id.required">The stage is required</span>
        </md-field>
        <md-field :class="getValidationClass('investment_type_id')" class="md-has-value">
          <label for="type">Investment type</label>
          <model-select :options="optionsType"
            v-model="form.investment_type_id"
            placeholder="Select type">
          </model-select>
          <span class="md-error" v-if="!$v.form.investment_type_id.required">The type is required</span>
        </md-field>
      </div>
      <div class="half">
        <md-field :class="getValidationClass('sector_id')" class="md-has-value">
          <label for="sector">
            <md-icon>
              info
            </md-icon>
            Sector (<a href="https://ec.europa.eu/eurostat/documents/3859598/5902521/KS-RA-07-015-EN.PDF" target="_blank">NACE classification</a>)
            <md-tooltip md-direction="top">For detailed structure and explanatory notes on sectors according to NACE classification, go to page 61. </md-tooltip>
          </label>
          <model-select :options="optionsSector"
            v-model="sector_id"
            placeholder="Select sector">
          </model-select>
          <span class="md-error" v-if="!$v.form.sector_id.required">The sector is required</span>
        </md-field>
        <md-field :class="getValidationClass('subsector_id')" class="md-has-value">
          <label for="subsector">Sub-sector</label>
          <model-select :options="optionsSubsectors"
            v-model="subsector_id"
            placeholder="Select sub-sector">
          </model-select>
          <span class="md-error" v-if="!$v.form.subsector_id.required">The sub-sector is required</span>
        </md-field>
      </div>
      <p class="text-left" v-if="sector_warning">
        This sector is unacceptable and part of FMO’s exclusion list.
      </p>
      <div class="selects">
        <div class="item">
          <md-field :class="getValidationClass('country_id')" class="md-has-value">
            <label for="country">Country</label>
            <model-select :options="optionsCountries"
              v-model="country_id"
              autocomplete="none"
              placeholder="Select country">
            </model-select>
            <span class="md-error" v-if="!$v.form.country_id.required">The country is required</span>
          </md-field>
        </div>
        <div class="item">
          <md-field :class="getValidationClass('country_id')" class="md-has-value">
            <label for="country">Subsidiaries countries</label>
            <multi-select :options="optionsCountries"
              :selected-options="form.countries_subsidiaries"
              autocomplete="none"
              placeholder="Select subsidiaries country"
              @select="onSelect">
            </multi-select>
            <span class="md-error" v-if="!$v.form.country_id.required">The country is required</span>
          </md-field>
        </div>
      </div>
      <p class="text-left" v-if="country_warning">
        This country is categorized as unacceptable according to FMO KYC's policy 
      </p>
      <md-field class="sme md-has-value">
        <label>
          <md-icon>
            info
            <md-tooltip md-direction="top">
              <p>
                Corporate Governance should be appropriate for the size, sophistication and growth phase of the investee. Some companies like SMEs, family owned businesses or venture investments, may not have the policies, structures, and processes in place that a more established or larger company would have. When dealing with a company that is at the very early stages of corporate governance development, switch this button on to activate a tailored Corporate Governance questionnaire. If the switch is off, the Corporate Governance analysis is based on the DFI Corporate Governance Development Framework.
              </p>
            </md-tooltip>
          </md-icon>
          Is this investee 
          <strong>
            SMEs
            <md-tooltip md-direction="top">
              <p>
                A company qualifies as an SME if it meets at least two out of the three following criteria at the time of investment by the Fund Manager:
              </p>
              <ul>
                <li>The number of employees is less than 300;</li>
                <li>Total annual sales are less than USD 15,000,000; and/or</li>
                <li>Total assets are less than USD 15,000,000.</li>
              </ul>
              <p>
                The qualification is set once at the time of investment and cannot change during the life of investment even when the SME becomes a bigger company over the life of the Fund.
              </p>
            </md-tooltip>
          </strong>
          /Family owned companies/early stage companies (This will influence corporate governance analysis.)
        </label>
        <md-switch v-model="form.is_sme" class="md-primary">
          <span v-if="form.is_sme">Yes</span>
          <span v-if="!form.is_sme">No</span>
        </md-switch>
      </md-field>
      <md-field class="sme md-has-value">
        <label>
          Does this investment have potential exposure to FMO’s exclusion list?
        </label>
        <md-switch v-model="form.potential_exposure" class="md-primary">
          <span v-if="form.potential_exposure">Yes</span>
          <span v-if="!form.potential_exposure">No</span>
        </md-switch>
      </md-field>
      <md-field class="sme md-has-value" v-if="form.sector_id == 841">
        <label>Does the portfolio of the FI has more than 10% exposure to Project Finance or more than 20% in high risk sectors (according to <a href="/files/EDFI%20Harmonized%20High%20Risk%20Sector%20List.pdf" target="_blank">EDFI definitions</a>)?</label>
        <md-switch v-model="form.high_risk" class="md-primary">
          <span v-if="form.high_risk">Yes</span>
          <span v-if="!form.high_risk">No</span>
        </md-switch>
      </md-field>
      <div class="buttons-utils">
        <md-button v-if="isEdit" @click="deleteDialog = true" class="md-accent md-raised">Delete</md-button>
        <md-button type="submit" class="md-primary md-raised save">{{ buttonText }}</md-button>
      </div>
    </form>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
    <md-dialog :md-active.sync="deleteDialog">
      <md-dialog-title>Remove investment</md-dialog-title>
      <md-dialog-content>
        <p>
          Do you really want to delete this investment? This action is permanent.
        </p>
        <div class="options buttons-dismiss">
          <md-button class="md-raised md-accent" @click="deleteDialog = false">
            No
          </md-button>
          <md-button class="md-raised md-primary" @click="remove">
            Yes
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </main>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import router from "@/router";
import { ModelSelect, MultiSelect } from 'vue-search-select'
import {Money} from 'v-money'

export default {
  name: "investmentForm",
  components: {
    ModelSelect,
    MultiSelect,
    Money,
  },
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      deleteDialog: false,
      amount: null,
      buttonText: '',
      optionsStage: [],
      optionsSector: [],
      optionsCountries: [],
      optionsType: [],
      optionsSubsectors: [],
      investment: this.$store.state.investment,
      funds: this.$store.state.funds,
      data: this.$store.state.data,
      user: this.$store.state.user,
      fund: this.$store.state.fund,
      convertCurrency: this.$store.state.currency,
      sector_id: 0,
      subsector_id: 0,
      country_id: 0,
      message: "",
      showSnackbar: false,
      currency: '€',
      country_warning: false,
      sector_warning: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '€ ',
        precision: 0,
        masked: false
      },
      form: {
        amount: 0,
        is_sme: 0,
        potential_exposure: 0,
        high_risk: 0,
        year: '',
        countries_subsidiaries: [],
        name: null,
        investment_stage_id: null,
        sector_id: null,
        subsector_id: null,
        country_id: null,
        fund_id: [],
        investment_type_id: null
      },
      stageInfo: `<p>Definitions of each investment stage:</p>
          <p><strong>Pipeline:</strong> The fund has targeted a potential investment and is in the preparatory stages of the investment process in which only general investment data is available to the fund; i.e. (sub-) sector, country, and investment type and amount;</p>
          <p><strong>Pre-analysis:</strong> Usually the stage where the first contacts are made with a potential investee. Non-disclosure agreements for the exchange of non-public information about the investee and the first exchanges of such documents also happen either at this or the next stage.</p>
          <p><strong>Due Diligence:</strong> The fund investigates the potential investment’s financial, environmental, social and governance performance, which will be the basis of the fund’s investment decision;</p>
          <p><strong>Contracting:</strong> The fund decides to invest in the potential investment and is in the process of formalization of the investment decision;</p>
          <p><strong>Portfolio:</strong> The fund has formally invested in the investment company and now needs to monitor the investment on its financial and ESG performance;</p>
          <p><strong>Exit:</strong> The fund has divested the company;</p>`
    }
  },
  validations: {
    form: {
      amount: {
        required,
      },
      name: {
        required,
      },
      year: {
        required,
      },
      investment_stage_id: {
        required,
      },
      sector_id: {
        required,
      },
      subsector_id: {
        required,
      },
      country_id: {
        required,
      },
      investment_type_id: {
        required,
      },
    },
  },
  methods: {
    remove() { 
      this.$store.commit('deleteInvestment', {id: this.investment.id});
      this.deleteDialog = false;
      router.push({ name: "overview" });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    onSelect (items) {
      this.form.countries_subsidiaries = items
    },
    assembleForm() {
      if (!this.investment || !this.fund || !this.investment.name)
        return false;
      this.sector_id = this.investment.sector_id;
      this.form.name = this.investment.name;
      this.form.investment_stage_id = this.investment.investment_stage_id;
      this.form.investment_type_id = this.investment.investment_type_id;
      this.form.subsector_id = this.investment.subsector_id;
      this.form.countries_subsidiaries = JSON.parse(this.investment.countries_subsidiaries);
      this.form.year = this.investment.year;
      this.subsector_id = this.investment.subsector_id;
      this.country_id = this.investment.country_id;
      this.sector_id = this.investment.sector_id;
      this.form.is_sme = this.investment.is_sme === 1;
      this.form.potential_exposure = this.investment.potential_exposure === 1;
      this.form.high_risk = this.investment.high_risk === 1;
      this.form.fund_id = this.investment.fund_id;
      let value = this.investment.amount
      if (typeof value === 'string')
        value = JSON.parse(value)
      const currencyName = this.fund.currency === 0 ? 'eur' : 'usd';
      this.form.amount = parseInt(value[currencyName]);
    },
    validateInvestment() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.save()
      }
    },
    save() {
      if (this.form.fund_id.length < 1) {
        this.form.fund_id.push(this.fund.id);
      }
      const value = this.form.amount;
      this.form.amount = {}
      this.form.amount[this.currency === '€' ? 'eur' : 'usd'] = value
      this.form.amount[this.currency === '€' ? 'usd' : 'eur'] = this.currency === '€' ? parseInt(value * this.convertCurrency.eurToUsd) : parseInt(value * this.convertCurrency.usdToEur);
      let url = `${window.api}funds/${this.fund.id}/investments/${this.user.id}/create/?jwt=${this.user.token}`
      if (this.$route.params.type !== 'new')
        url = `${window.api}funds/${this.fund.id}/investments/${this.user.id}/update/${this.investment.id}/?jwt=${this.user.token}`
      axios
        .post(url, this.form)
        .then(response => {
          if (response.data.status)
            router.push({ name: "overview" });
        })
        .catch(() => {
          this.message = "Something went wrong creating the investment";
          this.showSnackbar = true;          
        });
    },
  },
  mounted() {
    if (this.$route.params.type !== 'new') {
      this.assembleForm();
      this.isEdit = true;
      this.buttonText = "Update";
    } else {
      this.buttonText = "Create";
      this.form.fund_id.push(this.fund.id)
    }
    this.optionsSubsectors = []
    this.data.stages.map((item) => {
      this.optionsStage.push({"value": item.id, "text": item.name});
    })
    this.data.sectors.map((item) => {
      this.optionsSector.push({"value": item.id, "text": `${item.code} - ${item.name}`});
      item.subsectors.map((subitem) => {
        this.optionsSubsectors.push({"value": subitem.id, "text": `${subitem.sector_code}.${subitem.code} - ${subitem.name}`});
      })
    })
    const countries = []
    const options = []
    this.data.countries.map((item) => {
      if (countries.indexOf(item.name) < 0) {
        countries.push(item.name);
        options.push({"value": item.id, "text": item.name});
      }
    })
    const optionSorted = options.sort((a, b) => a.text.localeCompare(b.text));
    this.optionsCountries = optionSorted
    this.data.types.map((item) => {
      this.optionsType.push({"value": item.id, "text": item.name});
    })
    this.money.prefix = `${this.fund.currency === 0 ? '€' : '$'} `;
    this.currency = `${this.fund.currency === 0 ? '€' : '$'}`;
    this.$store.subscribe((mutation, state) => {
      this.funds = state.funds;
      this.fund = state.fund;
      this.investment = state.investment;
      this.money.prefix = this.fund.currency === 0 ? '€ ' : '$ ';
      this.currency = this.fund.currency === 0 ? '€' : '$';
      if (this.$route.params.type !== 'new')
        this.assembleForm()
    });
  },
  watch: {
    sector_id() {
      if (!this.data.sectors)
        return false;
      this.optionsSubsectors = []
      this.form.sector_id = this.sector_id;
      if (!this.investment)
        this.form.subsector_id = null;
      const sector = this.data.sectors.filter((item) => item.id === this.sector_id);
      if (sector) {
        sector[0].subsectors.map((item) => {
          this.optionsSubsectors.push({"value": item.id, "text": `${item.sector_code}.${item.code} - ${item.name}`});
        })
      }
    },
    subsector_id() {
      this.form.subsector_id = this.subsector_id;
      this.data.sectors.map((item) => {
        item.subsectors.map((subitem) => {
          if (subitem.id === this.subsector_id) {
            this.sector_id = item.id;
            this.form.sector_id = item.id;
            this.sector_warning = subitem['E & S risk category'] === "Excluded";
          }
        });
      });
    },
    country_id() {
      if (this.country_id === 0)
        return;
      const country = this.data.countries.filter((item) => item.id === this.country_id)[0];
      if (country.data['overall-risk-rating'] === 'Unacceptable') {
        this.country_warning = true;
      } else {
        this.country_warning = false;
      }
      this.form.country_id = this.country_id
    },
    investment() {
      this.assembleForm();
    },
    fund() {
      this.assembleForm();
    },
  }
}
</script>

<style lang="scss">
#investmentForm {
  display: block;
  width: 100%;
  margin-top: 30px;
  .text-left {
    text-align: left;
    margin: 0 0 30px;
  }
  label {
    .md-icon {
      margin: -3px 6px 0 0;
    }
  }
  .sme {
    .md-switch {
      label {
        top: 0;
        font-size: 16px;
      }
    }
    &:after {
      background: none;
    }
  }
  .amount,
  .funds {
    label {
      &.title {
        font-size: 12px;
      }
      top: 0;
    }
    #amount {
      width: 100%;
      border: 0;
      outline: none;
      font-size: 16px;
      background: none;
    }
  }
  .ui.selection.dropdown {
    background: none;
  }
  .buttons-utils {
    display: flex;
    justify-content: space-between;
  }
  .selection.dropdown {
    border: 0;
    padding: 11px 0;
    i {
      font-size: 16px;
      color: rgba(0,0,0,0.54);
      padding: 8px 4px;
      opacity: 1;
    }
    input.search {
      padding: 9px 0;
    }
  }
  .selects {
    display: flex;
    justify-content: space-between;
    .item {
      width: 49%;
      .md-field {
        width: 100%;
      }
      .dropdown.multiple {
        padding: 0;
        input {
          margin: 0 0 0 15px;
        }
      }
    }
  }
  .half {
    display: flex;
    justify-content: space-between;
    .md-field {
      width: 49%;
    }
  }
  .third {
    display: flex;
    justify-content: space-between;
    .md-field {
      width: 32%;
    }
  }
}
</style>
