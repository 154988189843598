<template>
  <main id="headerReview">
    <div class="wrapper">
      <div class="spacer"></div>
      <div>
        Entirely lacking
      </div>
      <div>
        Basic
        <small>Significant improvement potential</small>
      </div>
      <div>
        Good
        <small>Some improvement potential</small>
      </div>
      <div>
        Very good
        <small>No or minor improvement potential</small>
      </div>
      <div class="spacer-small"></div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HeaderColumnsLower",
  methods: {
    handleScroll() {
      if (!document.querySelector("#headerReview"))
        return false;
      const el = document.querySelector("#headerReview")
      if (window.scrollY > el.offsetTop && !document.querySelector("#headerReview.fixed")) {
        const clone = el.cloneNode(true)
        clone.classList.add("fixed")
        document.querySelector("body").appendChild(clone)
      } else if(window.scrollY < el.offsetTop && document.querySelector("#headerReview.fixed")) {
        document.querySelector("#headerReview.fixed").remove();
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  }, 
  destroy() {
    window.removeEventListener('scroll');
  }
}
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  margin-top: 48px;
  .wrapper {
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: 30px;
    .spacer {
      min-width: 20%;
      @media (min-width: 1040px) {
        min-width: 30%;
      }
    }
    .spacer-small {
      max-width: 7%;
    }
  }
  div {
    width: 100%;
    text-align: center;
    small {
      display: block;
    }
  }
  &.fixed {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    color: #2c3e50;
    border-bottom: 1px solid rgba(0,0,0,.12);
    .wrapper {
      margin: 20px auto;
    }
  }
}
</style>