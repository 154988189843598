<template>
  <main>
    <HeaderColumnsHigher />
    <div class="block" :class="{'active': step === 0}">
      <span class="title">
        <span>Business Integrity Management System (BIMS)</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('BusinessIntegrityManagementSystem', 's1', 0)" :class="{'active': answers.BusinessIntegrityManagementSystem === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityManagementSystem', 's1', 1)" :class="{'active': answers.BusinessIntegrityManagementSystem === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            There are policies for required and business critical areas, and they are integrated into the operation and management processes. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            There are policies for required and business critical areas, and they are integrated into the operation and management processes. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityManagementSystem', 's1', 2)" :class="{'active': answers.BusinessIntegrityManagementSystem === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            The policies state applicable business and operational requirements, define accountability for all relevant Business Integrity areas, and are evaluated regularly. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            The policies state applicable business and operational requirements, define accountability for all relevant Business Integrity areas, and are evaluated regularly. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityManagementSystem', 's1', 3)" :class="{'active': answers.BusinessIntegrityManagementSystem === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            There is accountability on senior management level. The company engages and communicates with external stakeholders proactively. The Board receives Business Integrity performance reports and evaluations. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 0 && !expandStatus">
            There is accountability on senior management level. The company engages and communicates with external stakeholders proactively. The Board receives Business Integrity performance reports and evaluations. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 1}">
      <span class="title">
        <span>Business Integrity Alert and Economic Sanctions</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('BusinessIntegrityAlertEconomicSanctions', 's2', 0)" :class="{'active': answers.BusinessIntegrityAlertEconomicSanctions === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityAlertEconomicSanctions', 's2', 1)" :class="{'active': answers.BusinessIntegrityAlertEconomicSanctions === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            One (or several) Politically Exposed Persons, potentially posing a risk to the Company. No company staff or associated persons appear on the EU, UN or OFAC sanctions lists. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            One (or several) Politically Exposed Persons, potentially posing a risk to the Company. No company staff or associated persons appear on the EU, UN or OFAC sanctions lists. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityAlertEconomicSanctions', 's2', 2)" :class="{'active': answers.BusinessIntegrityAlertEconomicSanctions === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            One (or several) Politically Exposed Persons, but without risk to the Company. Regular reviews of EU, UN or OFAC sanctions lists against all employees and persons associated with the company. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            One (or several) Politically Exposed Persons, but without risk to the Company. Regular reviews of EU, UN or OFAC sanctions lists against all employees and persons associated with the company. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('BusinessIntegrityAlertEconomicSanctions', 's2', 3)" :class="{'active': answers.BusinessIntegrityAlertEconomicSanctions === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            No Politically Exposed Persons. Procedures established to handle situation where individuals appear on sanctions lists. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 1 && !expandStatus">
            No Politically Exposed Persons. Procedures established to handle situation where individuals appear on sanctions lists. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 2}">
      <span class="title">
        <span>Compliance with laws and regulations</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Compliancelawsregulations', 's3', 0)" :class="{'active': answers.Compliancelawsregulations === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Compliancelawsregulations', 's3', 1)" :class="{'active': answers.Compliancelawsregulations === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            Awareness of, but not full compliance with applicable legislation and regulations. Occasional external audits. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            Awareness of, but not full compliance with applicable legislation and regulations. Occasional external audits. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Compliancelawsregulations', 's3', 2)" :class="{'active': answers.Compliancelawsregulations === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            Monitoring and awareness of all relevant legislation and regulation, and intention to reach full compliance. Regular external audits. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            Monitoring and awareness of all relevant legislation and regulation, and intention to reach full compliance. Regular external audits. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Compliancelawsregulations', 's3', 3)" :class="{'active': answers.Compliancelawsregulations === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            Full compliance, and processes to identify and monitor relevant legislation and regulation and ensure continuous compliance. Audits and compliance reports provided to the Board. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 2 && !expandStatus">
            Full compliance, and processes to identify and monitor relevant legislation and regulation and ensure continuous compliance. Audits and compliance reports provided to the Board. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 3}">
      <span class="title">
        <span>Anti-Bribery and Corruption (ABC) Management</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('AntiBriberyCorruptionManagement', 's4', 0)" :class="{'active': answers.AntiBriberyCorruptionManagement === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('AntiBriberyCorruptionManagement', 's4', 1)" :class="{'active': answers.AntiBriberyCorruptionManagement === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            There is an anti-corruption policy, endorsed by senior management. Training is provided for select staff, and there is an anti-corruption officer. Policy implementation is audited. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            There is an anti-corruption policy, endorsed by senior management. Training is provided for select staff, and there is an anti-corruption officer. Policy implementation is audited. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('AntiBriberyCorruptionManagement', 's4', 2)" :class="{'active': answers.AntiBriberyCorruptionManagement === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            The policy also mandate staff to report suspected corruption. The anti-corruption reports directly to the Board. External auditors dedicate specific resources and time for the auditing of the anti-corruption policy implementation. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            The policy also mandate staff to report suspected corruption. The anti-corruption reports directly to the Board. External auditors dedicate specific resources and time for the auditing of the anti-corruption policy implementation. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('AntiBriberyCorruptionManagement', 's4', 3)" :class="{'active': answers.AntiBriberyCorruptionManagement === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            The policy is communicated to all external business partners. Training is required for all employees. The Board confirms in the annual report every year that it is satisfied with the anti-corruption policy implementation. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 3 && !expandStatus">
            The policy is communicated to all external business partners. Training is required for all employees. The Board confirms in the annual report every year that it is satisfied with the anti-corruption policy implementation. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 4}">
      <span class="title">
        <span>Whistleblower system</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Whistleblowersystem', 's5', 0)" :class="{'active': answers.Whistleblowersystem === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Whistleblowersystem', 's5', 1)" :class="{'active': answers.Whistleblowersystem === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            There is a whistleblowing policy available to internal parties. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            There is a whistleblowing policy available to internal parties. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Whistleblowersystem', 's5', 2)" :class="{'active': answers.Whistleblowersystem === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            The whistleblowing policy is endorsed by senior management and the Board, is available to external parties, and is subject to periodical internal audits. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            The whistleblowing policy is endorsed by senior management and the Board, is available to external parties, and is subject to periodical internal audits. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Whistleblowersystem', 's5', 3)" :class="{'active': answers.Whistleblowersystem === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            The whistleblowing policy is regularly communicated to all staff, and overseen by senior management. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 4 && !expandStatus">
            The whistleblowing policy is regularly communicated to all staff, and overseen by senior management. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="block" :class="{'active': step === 5}">
      <span class="title">
        <span>Cyber and data security</span>
      </span>
      <div class="buttons">
        <span class="button" @click="toggle('Cyberdatasecurity', 's6', 0)" :class="{'active': answers.Cyberdatasecurity === 0}">
          <span>
            Entirely lacking
          </span>
        </span>
        <span class="button" @click="toggle('Cyberdatasecurity', 's6', 1)" :class="{'active': answers.Cyberdatasecurity === 1}">
          <span class="short">
            Basic
          </span>
          <span class="long">
            System, user and network activity is monitored, systems and security patches are up-to-date, and basic cyber threat and data security risk governance elements have been established. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            System, user and network activity is monitored, systems and security patches are up-to-date, and basic cyber threat and data security risk governance elements have been established. 
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Cyberdatasecurity', 's6', 2)" :class="{'active': answers.Cyberdatasecurity === 2}">
          <span class="short">
            Good
          </span>
          <span class="long">
            User privileges are reviewed periodically, generic training is provided, there are security protocols to prevent data breaches, and there is an appointed person in charge of cyber and data security.
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            User privileges are reviewed periodically, generic training is provided, there are security protocols to prevent data breaches, and there is an appointed person in charge of cyber and data security.
          </md-tooltip>
        </span>
        <span class="button" @click="toggle('Cyberdatasecurity', 's6', 3)" :class="{'active': answers.Cyberdatasecurity === 3}">
          <span class="short">
            Very good
          </span>
          <span class="long">
            User privileges are restricted to the necessary minimum, tailored training is provided to specific staff and needs, there are regular security reviews, and the company discloses externally it has a cyber security team. 
          </span>
          <md-tooltip md-direction="top" v-if="step !== 5 && !expandStatus">
            User privileges are restricted to the necessary minimum, tailored training is provided to specific staff and needs, there are regular security reviews, and the company discloses externally it has a cyber security team. 
          </md-tooltip>
        </span>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised" @click="previousSection()">Previous</md-button>
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Finish ESG Review</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="done()">Finish ESG Review</md-button>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import HeaderColumnsHigher from '../HeaderColumnsHigher';

export default {
  name: "bi",
  components: {
    HeaderColumnsHigher,
  },
  data() {
    return {
      step: 0,
      expandStatus: this.$store.state.expandStatus,
      answers: [],
      fund: this.$store.state.fund,
      disabled: true,
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
    const check = this.review.higher.filter((item) => item.level === "bi")
    this.step = check.length;
    if (this.step === 5)
      this.step = 0;
  },
  methods: {
    toggle(key, section, index) {
      this.$store.commit("answerReview", {level: "bi", type: "higher", key: key, value: index, section: section});
      const check = this.review.higher.filter((item) => item.level === "bi")
      this.step = check.length;
    },
    assembleAnswers() {
      this.answers = []
      this.review.higher.map((item) => {
        this.answers[item.key] = item.value;
      });
      if (this.reviewCount.higher.bi >= 6) {
        this.disabled = false;
      }
    },
    done() {
      this.$store.commit("reviewDone");
      router.push(`/overview/`)
    },
    previousSection() {
      this.$store.commit("toggleSection", {type: "higher", section: 1})
    }
  }
}
</script>
