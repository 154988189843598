<template>
  <main>
    <span class="md-title">ESG Toolkit</span>
    <div class="utils">
      <span class="name">{{ user.name }}</span>
      <span class="fund" v-if="funds && fund">
        {{ fund.name }}
        <div class="options">
          <div class="content">
            <div class="option" v-for="item in funds" v-bind:key="item.id">
              <button @click="selectFund(item)">
                {{ item.name }}
              </button>
              <button class="edit" @click="editFund(item)">
                <md-icon>edit</md-icon>
              </button>
              <button class="delete" @click="deleteFund(item)" v-if="funds && funds.length > 1">
                <md-icon>archive</md-icon>
              </button>
            </div>
            <button @click="createFund()" to="/my-funds/new">
              New fund
            </button>
            <button @click="toggleDialog = true" v-show="funds_archived && funds_archived.length > 0">
              Archived funds
            </button>
          </div>
        </div>
      </span>
      <span class="currency">
        <label>Currency</label>
        <span>{{ getCurrency() }}</span>
        <div class="options">
          <div class="content">
            <div class="option">
              <button @click="toggleCurrency(0)">Euro (€)</button>
            </div>
            <div class="option">
              <button @click="toggleCurrency(1)">US Dollar ($)</button>
            </div>
          </div>
        </div>
      </span>
      <div class="mobile">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
      </div>
      <div class="desktop">
        <md-button to="/overview">
          <md-icon>home</md-icon>
        </md-button>
        <md-button to="/faq">
          <md-icon>help</md-icon>
        </md-button>
        <md-button to="/my-account">
          <md-icon>person</md-icon>
        </md-button>
        <md-button to="/admin" v-if="user.role === 1">
          <md-icon>settings_applications</md-icon>
        </md-button>
        <md-button v-on:click="logout()">
          <md-icon>exit_to_app</md-icon>
        </md-button>
      </div>
    </div>
    <md-dialog-confirm
      :md-active.sync="confirmDialog"
      md-title="Archive fund"
      md-content="Do you want to archive this fund?"
      md-confirm-text="Yes"
      md-cancel-text="No"
      @md-confirm="confirmDelete()" />
    <md-dialog-prompt
      :md-active.sync="fundDialog"
      v-model="fundSelected.name"
      @md-confirm="saveFund()"
      :md-title="dialogTitle"
      md-input-maxlength="30"
      md-input-placeholder="Name"
      md-confirm-text="Done" />
    <md-dialog :md-active.sync="toggleDialog">
      <md-dialog-title>Archived funds</md-dialog-title>
      <md-dialog-content>
        <div class="option-archived" v-for="item in funds_archived" v-bind:key="item.id">
          <span>
            {{ item.name }}
          </span>
          <md-button class="edit" @click="restoreFund(item)">
            <md-icon>restore</md-icon>
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </main>
</template>

<script>
import router from "@/router";

export default {
  name: 'Bar',
  data() {
    return {
      currency: false,
      fundSelected: {
        name: '',
        id: null,
      },
      toggleDialog: false,
      funds_archived: this.$store.state.funds_archived,
      confirmDialog: false,
      dialogTitle: "Add fund",
      fundDialog: false,
      menuVisible: false,
      user: this.$store.state.user,
      fund: this.$store.state.fund,
      funds: this.$store.state.funds,
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (["toggleFunds", "selectFund"].indexOf(mutation.type) >= 0) {
        this.funds = state.funds;
        this.funds_archived = state.funds_archived;
        this.fund = state.fund;
        this.user = state.user;
      }
    });
  },
  methods: {
    restoreFund(item) {
      this.$store.commit("restoreFund", item);
      this.toggleDialog = false;
    },
    createFund() {
      this.dialogTitle = "Add fund";
      this.fundSelected = {
        name: '',
        id: null
      };
      this.fundDialog = true;
    },
    editFund(fund) {
      this.dialogTitle = "Editing fund";
      this.fundSelected = fund;
      this.fundDialog = true;
    },
    getCurrency() {
      let currency = ''
      switch (this.fund.currency) {
        case 0:
          currency = 'Euro (€)'
          break;
        case 1:
          currency = 'US Dollar ($)'
          break;
      }
      return currency;
    },
    toggleCurrency(value) {
      this.$store.commit("toggleFundCurrency", value);
    },
    logout() {
      this.$store.commit("toggleLoggedUser", '');
      router.push({ name: "home" });
    },
    saveFund() {
      this.$store.commit("saveFund", this.fundSelected);
      this.fund.id = null;
    },
    selectFund(item) {
      this.$store.commit("selectFund", item);
      router.push({ name: "overview" });
    },
    deleteFund(item) {
      this.confirmDialog = true;
      this.fundSelected = item;
    },
    confirmDelete() {
      this.$store.commit("deleteFund", this.fundSelected);
      this.fundSelected = {
        name: '',
        id: null
      };
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  padding: 0 16px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 6;
  .options {
    position: absolute;
    left: 12px;
    display: none;
    top: 0;
    padding-top: 36px;
    min-width: 150px;
    .content {
      background: #fff;
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
      display: flex;
      flex-wrap: wrap;
      .option {
        &:hover {
          background: #ddd;
        }
        width: 100%;
        padding: 0 0 0 10px;
        display: flex;
        button {
          min-width: inherit;
          padding: 0;
          &:first-child {
            flex-grow: 1;
          }
          &.edit,
          &.delete {
            min-width: 20%;
            text-align: center;
          }
        }
      }
      button {
        color: #2c3e50;
        padding: 0 10px;
        line-height: 40px;
        background: none;
        margin: 0;
        min-width: 180px;
        text-align: left;
        border: 0;
        cursor: pointer;
        &:hover {
          background: #ddd;
        }
      }
    }
  }
  .currency {
    .options {
      top: 7px;
    }
  }
  .manual {
    position: relative;
    width: 148px;

    i {
      position: relative;
      top: -4px;
    }
    
    span {
      font-size: 12px;
      position: absolute;
      bottom: -7px;
      left: -50px;
      padding: 0 !important;
      right: 0;
      width: 112px;
    }
  }
  .fund {
    position: relative;
    cursor: pointer;
    &:hover .options {
      display: block;
    }
  }
  .utils {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-grow: 1;
    span {
      padding: 0 20px;
    }
    .currency {
      position: relative;
      display: flex;
      align-items: flex-end;
      span {
        margin: 0;
        padding: 14px 0 0;
      }
      label {
        position: absolute;
        top: -6px;
        left: 20px;
        font-size: 12px;
      }
      &:hover .options {
        display: block;
      }
    }
  }
  .md-drawer {
    height: 100vh;
    bottom: inherit;
    .md-icon {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
  .mobile,
  .desktop {
    display: none;
  }
  @media (max-width: 789px) {
    .mobile {
      display: block;
    }
  }
  @media (min-width: 789px) {
    .desktop {
      display: block;
    }
  }
}
</style>
