<template>
  <div>
    <h2>ESG Toolkit</h2>
    <p>Forgot my password</p>
    <form novalidate class="md-layout" @submit.prevent="validateForm">
      <md-field :class="getValidationClass('email')">
        <label for="email">Email</label>
        <md-input
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          v-model="form.email"
          :disabled="sending"/>
        <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
        <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
      </md-field>
      <md-button type="submit" class="md-primary raised">Recover password</md-button>
    </form>
    <hr />
    <md-button to="/" class="md-primary">Go back</md-button>
    <md-snackbar
      md-position="center"
      :md-active.sync="showSnackbar"
      md-persistent>
      <span>{{ message }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: null,
    },
    userSaved: false,
    sending: false,
    lastUser: null,
    message: "",
    showSnackbar: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.forgotPassword()
      }
    },
    forgotPassword() {
      this.$store.commit("toggleLoading")
      axios
        .post(`${window.api}user/forgot-password/`, {
          email: this.form.email,
        })
        .then(() => {
          this.$v.$reset()
          this.form.email = null
          this.$store.commit("toggleLoading")
          this.message = "If this account exists, an e-mail have been sent with informations to recovery this account.";
          this.showSnackbar = true;
        })
        .catch(() => {
          this.$store.commit("toggleLoading")
          this.message = "User not found";
          this.showSnackbar = true;          
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.raised {
  width: 100%;
  margin-top: 30px;
}
hr {
  margin: 40px 0;
}
</style>
