<template>
  <main id="app-holder">
    <Bar />
    <div class="wrapper">
      <div class="breadcrumbs">
        <router-link to="/overview">Overview</router-link> / {{ text }}
      </div>
      <div class="md-title">
        <md-button to="/overview">
          <md-icon>chevron_left</md-icon>
        </md-button>
        {{ title }}
        <span @click="setToggle()">
          <md-icon v-if="!toggle">
            info
          </md-icon>
          <md-icon v-if="toggle">
            highlight_off
          </md-icon>
        </span>
      </div>
      <div v-html="data.content['Info: Form investment']" class="info" v-if="toggle"></div>
      <investmentForm />
    </div>
  </main>
</template>

<style lang="scss" scoped>
.md-title {
  a.md-button {
    margin: 0 10px 0 0;
    min-width: inherit;
    height: 25px;
  }
  i {
    float: right;
    cursor: pointer;
  }
}
.info {
  text-align: left;
  margin-top: 40px;
  transition: all 0.3s;
}
</style>

<script>
import investmentForm from '../../components/investments/Form';
import Bar from '../../components/commom/Bar';

export default {
  name: "MyInvestmentsForm",
  components: {
    Bar,
    investmentForm,
  },
  data() {
    return {
      toggle: false,
      data: this.$store.state.data,
      text: '',
      title: '',
    }
  },
  methods: {
    setToggle() {
      this.toggle = !this.toggle;
    }
  },
  mounted() {
    if (this.$route.params.type !== 'new') {
      this.text = "Edit";
      this.title = "Editing investment";
    } else {
      this.text = "New";
      this.title = "Creating investment";
    }
  },
};
</script>
