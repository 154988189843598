<template>
  <main>
    <div class="utils">
      <md-button class="md-dense md-raised md-primary" >
        Import
        <input
          type="file"
          id="files"
          ref="files"
          multiple
          v-on:change="handleFileUploads()"/>
      </md-button>
    </div>
    <md-table v-model="data">	
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">TI Score</h1>
        </div>

        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Search by country..." v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        :md-label="grid.length < 1 ? 'Nothing here yet' : `No countries with the term '${search}' found`">
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
        <md-table-cell md-label="Country" md-sort-by="name">{{item.name}}</md-table-cell>
        <md-table-cell md-label="TI score">{{item.ti['TI Score']}}</md-table-cell>
        <md-table-cell md-label="TI rank">{{item.ti['TI Rank']}}</md-table-cell>
      </md-table-row>
    </md-table>
  </main>
</template>

<script>
import axios from "axios";
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)))
  }
  return items
}

export default {
  name: 'Grid',
  data() {
    return {
      search: null,
      grid: [],
      data: [],
      user: this.$store.state.user,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    searchOnTable () {
      this.data = searchByName(this.grid, this.search)
    },
    handleFileUploads() {
      this.$store.commit('toggleLoading')
      this.file = this.$refs.files.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(`${window.api}import/ti/?jwt=${this.user.token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.commit('toggleLoading')
          this.getData();
        });
    },
    getData() {
      this.$store.commit('toggleLoading')
      axios
        .get(`${window.api}data/countries/?jwt=${this.user.token}`)
        .then(response => {
          this.data = this.grid = response.data;
          this.$store.commit('toggleLoading')
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.utils {
  justify-content: flex-start;
  display: flex;
}
button {
  cursor: pointer;
}
input[type='file'] {
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  height: 36px;
  cursor: pointer;
}
</style>
