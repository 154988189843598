<template>
  <main>
    <HeaderColumnsLower />
    <div class="group" :class="{'active': section[0].status}">
      <div class="group-title" @click="toggleSection(0)">
        <span class="title">
          <button>
            <span v-if="!section[0].status">+</span>
            <span v-if="section[0].status">-</span>
          </button>
          Policy
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s1')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s1')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              Limited or no E&S policies in place. No E&S standards adopted. Sporadic, conflicting or confusing procedures.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">     
              Limited or no E&S policies in place. No E&S standards adopted. Sporadic, conflicting or confusing procedures.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s1')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Policies and procedures in place meeting selected E&S standards. Sporadic communication, implementation and review.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Policies and procedures in place meeting selected E&S standards. Sporadic communication, implementation and review.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s1')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Full set of E&S policies, procedures and records, centrally maintained and routinely reviewed. Wide awareness in company.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Full set of E&S policies, procedures and records, centrally maintained and routinely reviewed. Wide awareness in company.
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s1')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Objectives & Principles</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('ObjectivesPrinciples', 's1', 0)" :class="{'active': answers.ObjectivesPrinciples === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('ObjectivesPrinciples', 's1', 1)" :class="{'active': answers.ObjectivesPrinciples === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No objectives or principles stated
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                No objectives or principles stated
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ObjectivesPrinciples', 's1', 2)" :class="{'active': answers.ObjectivesPrinciples === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Objective is stated, while principles are not.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Objective is stated, while principles are not.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ObjectivesPrinciples', 's1', 3)" :class="{'active': answers.ObjectivesPrinciples === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Overall objectives and principles are clearly stated
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Overall objectives and principles are clearly stated
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ObjectivesPrinciples', 's1', 4)" :class="{'active': answers.ObjectivesPrinciples === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Legal compliance</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Legalcompliance', 's1', 0)" :class="{'active': answers.Legalcompliance === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Legalcompliance', 's1', 1)" :class="{'active': answers.Legalcompliance === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Legal compliance is not mentioned
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Legal compliance is not mentioned
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Legalcompliance', 's1', 2)" :class="{'active': answers.Legalcompliance === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Legal compliance is mentioned in general, while leaving out mention of specific laws and regulations.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Legal compliance is mentioned in general, while leaving out mention of specific laws and regulations.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Legalcompliance', 's1', 3)" :class="{'active': answers.Legalcompliance === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Intention to comply with applicable laws and regulations is clearly stated. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Intention to comply with applicable laws and regulations is clearly stated. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Legalcompliance', 's1', 4)" :class="{'active': answers.Legalcompliance === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Accountability</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Accountability', 's1', 0)" :class="{'active': answers.Accountability === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Accountability', 's1', 1)" :class="{'active': answers.Accountability === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No one is accountable for the ESG Policy.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                No one is accountable for the ESG Policy.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Accountability', 's1', 2)" :class="{'active': answers.Accountability === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Ultimate accountability has not been defined on senior management level
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Ultimate accountability has not been defined on senior management level
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Accountability', 's1', 3)" :class="{'active': answers.Accountability === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Accountability for implementing the ESG Policy has been defined on senior management level. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Accountability for implementing the ESG Policy has been defined on senior management level. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Accountability', 's1', 4)" :class="{'active': answers.Accountability === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Communicating policy</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}" >
            <span class="button" @click="toggle('Communicatingpolicy', 's1', 0)" :class="{'active': answers.Communicatingpolicy === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Communicatingpolicy', 's1', 1)" :class="{'active': answers.Communicatingpolicy === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The ESG Policy has not been communicated or shared with only a few, and not all relevant employees.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                The ESG Policy has not been communicated or shared with only a few, and not all relevant employees.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Communicatingpolicy', 's1', 2)" :class="{'active': answers.Communicatingpolicy === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The ESG Policy has been communicated generally, but without considering relevant audience and stakeholders
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                The ESG Policy has been communicated generally, but without considering relevant audience and stakeholders
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Communicatingpolicy', 's1', 3)" :class="{'active': answers.Communicatingpolicy === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The ESG Policy has been communicated to all relevant stakeholders, and these are defined in general terms. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                The ESG Policy has been communicated to all relevant stakeholders, and these are defined in general terms. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Communicatingpolicy', 's1', 4)" :class="{'active': answers.Communicatingpolicy === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 4}">
          <span class="title">
            <span>Board/Management approval</span>
          </span>
          <div class="buttons" :class="{'active': step === 4}">
            <span class="button" @click="toggle('BoardManagementapproval', 's1', 0)" :class="{'active': answers.BoardManagementapproval === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('BoardManagementapproval', 's1', 1)" :class="{'active': answers.BoardManagementapproval === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The Board and senior management have not reviewed the ESG Policy
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 4">
                The Board and senior management have not reviewed the ESG Policy
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BoardManagementapproval', 's1', 2)" :class="{'active': answers.BoardManagementapproval === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The ESG Policy has been approved by senior management, but not by the Board
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 4">
                The ESG Policy has been approved by senior management, but not by the Board
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('BoardManagementapproval', 's1', 3)" :class="{'active': answers.BoardManagementapproval === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The ESG Policy has been approved by the Board and/or senior management
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 4">
                The ESG Policy has been approved by the Board and/or senior management
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('BoardManagementapproval', 's1', 4)" :class="{'active': answers.BoardManagementapproval === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[1].status}">
      <div class="group-title" @click="toggleSection(1)">
        <span class="title">
          <button>
            <span v-if="!section[1].status">+</span>
            <span v-if="section[1].status">-</span>
          </button>
          ESG Risk and Opportunity Identification
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s2')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s2')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              No, or basic, identification and assessment of E&S risks and impacts, but limited to a few activities.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              No, or basic, identification and assessment of E&S risks and impacts, but limited to a few activities.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s2')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Awareness and engagement of staff in identification and prioritization of E&S risks and impacts. External experts involved as required. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Awareness and engagement of staff in identification and prioritization of E&S risks and impacts. External experts involved as required. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s2')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Systematic, documented identification and prioritization of E&S risks and impacts, routinely reviewed and updated as part of a continual improvement plan. Internal and external inputs. Procedures extended to contractors, subcontractors, third parties and supply chain as relevant. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Systematic, documented identification and prioritization of E&S risks and impacts, routinely reviewed and updated as part of a continual improvement plan. Internal and external inputs. Procedures extended to contractors, subcontractors, third parties and supply chain as relevant. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s2')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Risk assessment</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Riskassessment', 's2', 0)" :class="{'active': answers.Riskassessment === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Riskassessment', 's2', 1)" :class="{'active': answers.Riskassessment === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                ESG risks have not been identified
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                ESG risks have not been identified
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Riskassessment', 's2', 2)" :class="{'active': answers.Riskassessment === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The need for ESG risk assessment is mentioned, but without identifying a process to do so. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The need for ESG risk assessment is mentioned, but without identifying a process to do so. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Riskassessment', 's2', 3)" :class="{'active': answers.Riskassessment === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The processes to identify and manage ESG risks and opportunities are described 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The processes to identify and manage ESG risks and opportunities are described 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Riskassessment', 's2', 4)" :class="{'active': answers.Riskassessment === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Process adequacy</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Processadequacy', 's2', 0)" :class="{'active': answers.Processadequacy === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Processadequacy', 's2', 1)" :class="{'active': answers.Processadequacy === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                The ESG processes are very basic, and there is no discussion of potential adequacy or gaps assessment. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The ESG processes are very basic, and there is no discussion of potential adequacy or gaps assessment. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Processadequacy', 's2', 2)" :class="{'active': answers.Processadequacy === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The ESG processes are developed to some degree, and partially informed by and adapted to specific types and levels of ESG risk exposure. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The ESG processes are developed to some degree, and partially informed by and adapted to specific types and levels of ESG risk exposure. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Processadequacy', 's2', 3)" :class="{'active': answers.Processadequacy === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The scope and structure of the ESG processes is adequate to meet the expected level of ESG-risk exposure.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                The scope and structure of the ESG processes is adequate to meet the expected level of ESG-risk exposure.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Processadequacy', 's2', 4)" :class="{'active': answers.Processadequacy === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Risk rating & prioritisation</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Riskratingprioritisation', 's2', 0)" :class="{'active': answers.Riskratingprioritisation === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Riskratingprioritisation', 's2', 1)" :class="{'active': answers.Riskratingprioritisation === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is an approach to ESG risk rating, but it is not implemented consistently and in full. There are no tools, frameworks or ways of working to prioritise the most pressing ESG matters. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                There is an approach to ESG risk rating, but it is not implemented consistently and in full. There are no tools, frameworks or ways of working to prioritise the most pressing ESG matters. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Riskratingprioritisation', 's2', 2)" :class="{'active': answers.Riskratingprioritisation === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Individual ESG risks and opportunities are provided, reviewed at least annually and kept up to date. There is a high-level approach to prioritising and ranking ESG interventions, but it is not implemented consistently and in full.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Individual ESG risks and opportunities are provided, reviewed at least annually and kept up to date. There is a high-level approach to prioritising and ranking ESG interventions, but it is not implemented consistently and in full.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Riskratingprioritisation', 's2', 3)" :class="{'active': answers.Riskratingprioritisation === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is an established way of working and tools or guidelines to enable priotisation and efficient allocation of resources to address the most material ESG matters. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                There is an established way of working and tools or guidelines to enable priotisation and efficient allocation of resources to address the most material ESG matters. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Riskratingprioritisation', 's2', 4)" :class="{'active': answers.Riskratingprioritisation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[2].status}">
      <div class="group-title" @click="toggleSection(2)">
        <span class="title">
          <button>
            <span v-if="!section[2].status">+</span>
            <span v-if="section[2].status">-</span>
          </button>
          Organisational Capacity and Competency
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s3')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s3')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              No assigned staff with E&S management responsibilities. No, or limited, awareness and E&S roles and responsibilities starting to get defined.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              No assigned staff with E&S management responsibilities. No, or limited, awareness and E&S roles and responsibilities starting to get defined.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s3')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              All levels of the company are involved in awareness training. E&S roles and responsibilities are assigned and part of daily operations. E&S staff is trained and competent. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              All levels of the company are involved in awareness training. E&S roles and responsibilities are assigned and part of daily operations. E&S staff is trained and competent. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s3')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              All levels of the company are trained, and E&S is managed as an integrated system. Management commitment is reflected in resources devoted to E&S management and training. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              All levels of the company are trained, and E&S is managed as an integrated system. Management commitment is reflected in resources devoted to E&S management and training.
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s3')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Internal ESG organisation</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('InternalESGorganisation', 's3', 0)" :class="{'active': answers.InternalESGorganisation === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('InternalESGorganisation', 's3', 1)" :class="{'active': answers.InternalESGorganisation === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                ESG responsibilities are not defined within the company, neither as a separate function, nor embedded in existing organisational structure and roles. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                ESG responsibilities are not defined within the company, neither as a separate function, nor embedded in existing organisational structure and roles. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('InternalESGorganisation', 's3', 2)" :class="{'active': answers.InternalESGorganisation === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                The organisational structure to manage ESG internally within the company is defined. ESG roles and responsibilities have been assigned, but only to a handful "key" ESG persons. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The organisational structure to manage ESG internally within the company is defined. ESG roles and responsibilities have been assigned, but only to a handful "key" ESG persons. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('InternalESGorganisation', 's3', 3)" :class="{'active': answers.InternalESGorganisation === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The organisational structure to manage ESG internally within the company is defined, and involves senior accountability. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The organisational structure to manage ESG internally within the company is defined, and involves senior accountability. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('InternalESGorganisation', 's3', 4)" :class="{'active': answers.InternalESGorganisation === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Training</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Training', 's3', 0)" :class="{'active': answers.Training === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Training', 's3', 1)" :class="{'active': answers.Training === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No formal ESG related training has been undertaken. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                No formal ESG related training has been undertaken. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Training', 's3', 2)" :class="{'active': answers.Training === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                One off ESG trainings, and/or trainings for ESG lead/s only have been provided. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                One off ESG trainings, and/or trainings for ESG lead/s only have been provided. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Training', 's3', 3)" :class="{'active': answers.Training === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Training is provided as and when required to ensure competence levels are adequate. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Training is provided as and when required to ensure competence levels are adequate. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Training', 's3', 4)" :class="{'active': answers.Training === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Oversight</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Oversight', 's3', 0)" :class="{'active': answers.Oversight === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Oversight', 's3', 1)" :class="{'active': answers.Oversight === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Oversight for the ESG organisation has not been defined. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Oversight for the ESG organisation has not been defined. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Oversight', 's3', 2)" :class="{'active': answers.Oversight === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Oversight for the ESG organisation takes place but is not formally part of senior management's responsibilities. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Oversight for the ESG organisation takes place but is not formally part of senior management's responsibilities. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Oversight', 's3', 3)" :class="{'active': answers.Oversight === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The Board and/or senior management has oversight of and ultimate responsibility for the ESG organisation. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                The Board and/or senior management has oversight of and ultimate responsibility for the ESG organisation. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Oversight', 's3', 4)" :class="{'active': answers.Oversight === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[3].status}">
      <div class="group-title" @click="toggleSection(3)">
        <span class="title">
          <button>
            <span v-if="!section[3].status">+</span>
            <span v-if="section[3].status">-</span>
          </button>
          Processes
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s4')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s4')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              No, or few, monitoring plans. Sole intention is to satisfy regulatory requirements. No formal review activities.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              No, or few, monitoring plans. Sole intention is to satisfy regulatory requirements. No formal review activities.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s4')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Routine review of monitoring and supervision activities, including participation of workers. Corrective actions routinely implemented. An E&S internal audit plan is in place. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Routine review of monitoring and supervision activities, including participation of workers. Corrective actions routinely implemented. An E&S internal audit plan is in place. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s4')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Monitoring, supervising and auditing activities are integrated and included in management review. Senior management receives periodic reports about E&S performance and progress toward E&S objectives and targets. All key project decisions consider E&S.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Monitoring, supervising and auditing activities are integrated and included in management review. Senior management receives periodic reports about E&S performance and progress toward E&S objectives and targets. All key project decisions consider E&S.
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s4')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Process description</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Processdescription', 's4', 0)" :class="{'active': answers.Processdescription === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Processdescription', 's4', 1)" :class="{'active': answers.Processdescription === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There are no formal descriptions of ESG management processes. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There are no formal descriptions of ESG management processes. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Processdescription', 's4', 2)" :class="{'active': answers.Processdescription === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Management processes are formalised, but ESG is not integrated in these. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Management processes are formalised, but ESG is not integrated in these. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Processdescription', 's4', 3)" :class="{'active': answers.Processdescription === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                All management processes involving ESG matters are described and ESG is well integrated. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                All management processes involving ESG matters are described and ESG is well integrated. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Processdescription', 's4', 4)" :class="{'active': answers.Processdescription === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Grievances</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Grievances', 's4', 0)" :class="{'active': answers.Grievances === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Grievances', 's4', 1)" :class="{'active': answers.Grievances === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No grievance mechanism in place. No, or basic, procedures for receiving complaints. Responsibility limited to one person or unit.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                No grievance mechanism in place. No, or basic, procedures for receiving complaints. Responsibility limited to one person or unit.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Grievances', 's4', 2)" :class="{'active': answers.Grievances === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Grievance mechanism is fully implemented; however, there is not enough evidence of its effectiveness. Awareness is limited to those directly handling the complaints; limited tracking of cases.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Grievance mechanism is fully implemented; however, there is not enough evidence of its effectiveness. Awareness is limited to those directly handling the complaints; limited tracking of cases.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Grievances', 's4', 3)" :class="{'active': answers.Grievances === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Effective grievance mechanism is evidenced by formal records. There is routine review of records and the effectiveness of the program.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Effective grievance mechanism is evidenced by formal records. There is routine review of records and the effectiveness of the program.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Grievances', 's4', 4)" :class="{'active': answers.Grievances === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>ESG reporting process</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('ESGreportingprocess', 's4', 0)" :class="{'active': answers.ESGreportingprocess === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('ESGreportingprocess', 's4', 1)" :class="{'active': answers.ESGreportingprocess === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is no reporting on ESG matters. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                There is no reporting on ESG matters. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ESGreportingprocess', 's4', 2)" :class="{'active': answers.ESGreportingprocess === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Occasional reporting, but without consistent structure and tracking of performance over time.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Occasional reporting, but without consistent structure and tracking of performance over time.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ESGreportingprocess', 's4', 3)" :class="{'active': answers.ESGreportingprocess === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                There is a well-defined ESG performance reporting process with KPIs and targets. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                There is a well-defined ESG performance reporting process with KPIs and targets. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ESGreportingprocess', 's4', 4)" :class="{'active': answers.ESGreportingprocess === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[4].status}">
      <div class="group-title" @click="toggleSection(4)">
        <span class="title">
          <button>
            <span v-if="!section[4].status">+</span>
            <span v-if="section[4].status">-</span>
          </button>
          Performance Management and Monitoring
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s5')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s5')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              No, or limited/informal programs or activities to mitigate E&S impacts. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              No, or limited/informal programs or activities to mitigate E&S impacts. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s5')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Actions/activities in place to manage E&S risks and impacts. Measurable company-wide objectives and targets. Periodic review and update.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Actions/activities in place to manage E&S risks and impacts. Measurable company-wide objectives and targets. Periodic review and update.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s5')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Routine, consistent implementation of actions/activities to proactively manage E&S risks and impacts. Significant improvements in E&S performance. Demonstrated commitment to continual improvement using annual improvement plans. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Routine, consistent implementation of actions/activities to proactively manage E&S risks and impacts. Significant improvements in E&S performance. Demonstrated commitment to continual improvement using annual improvement plans. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s5')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Goals and targets</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Goalstargets', 's5', 0)" :class="{'active': answers.Goalstargets === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Goalstargets', 's5', 1)" :class="{'active': answers.Goalstargets === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There are no ESG targets, goals or KPIs. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There are no ESG targets, goals or KPIs. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Goalstargets', 's5', 2)" :class="{'active': answers.Goalstargets === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                There is a limited (excessively small) number of ESG goals and/or targets, and they are not consistently and regularly tracked. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is a limited (excessively small) number of ESG goals and/or targets, and they are not consistently and regularly tracked. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Goalstargets', 's5', 3)" :class="{'active': answers.Goalstargets === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The ESG goals are relevant, specific and realistic, and they are consistently and regularly tracked. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The ESG goals are relevant, specific and realistic, and they are consistently and regularly tracked. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Goalstargets', 's5', 4)" :class="{'active': answers.Goalstargets === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Action Plan follow-up</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('ActionPlanfollowup', 's5', 0)" :class="{'active': answers.ActionPlanfollowup === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('ActionPlanfollowup', 's5', 1)" :class="{'active': answers.ActionPlanfollowup === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There are no Action Plans or lists of ESG related actions. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                There are no Action Plans or lists of ESG related actions. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ActionPlanfollowup', 's5', 2)" :class="{'active': answers.ActionPlanfollowup === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Action Plans exist, but are not up-to-date and monitored regularly. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Action Plans exist, but are not up-to-date and monitored regularly. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('ActionPlanfollowup', 's5', 3)" :class="{'active': answers.ActionPlanfollowup === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Progress and achievement of goals in agreed Action Plans (or similar) are verified regularly.  
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Progress and achievement of goals in agreed Action Plans (or similar) are verified regularly.  
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('ActionPlanfollowup', 's5', 4)" :class="{'active': answers.ActionPlanfollowup === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[5].status}">
      <div class="group-title" @click="toggleSection(5)">
        <span class="title">
          <button>
            <span v-if="!section[5].status">+</span>
            <span v-if="section[5].status">-</span>
          </button>
          Reporting
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s6')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s6')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              Minimal amounts of communication and reporting is provided, usually or entirely initiated by investors rather than the company itself. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Minimal amounts of communication and reporting is provided, usually or entirely initiated by investors rather than the company itself. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s6')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Reporting procedures are in place, mostly with a reactive and minimum (as opposed to comprehensive) approach. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Reporting procedures are in place, mostly with a reactive and minimum (as opposed to comprehensive) approach. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s6')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Regular reporting occurs, is comprehensive in nature, evaluates past and current performance. Ad hoc reporting is provided when warranted, and with minimum delay. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Regular reporting occurs, is comprehensive in nature, evaluates past and current performance. Ad hoc reporting is provided when warranted, and with minimum delay. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s6')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Content</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Content', 's6', 0)" :class="{'active': answers.Content === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Content', 's6', 1)" :class="{'active': answers.Content === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                There is no mention of ESG matters, performance or metrics in any reporting. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                There is no mention of ESG matters, performance or metrics in any reporting. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Content', 's6', 2)" :class="{'active': answers.Content === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                ESG matters reported separately rather than integrated with rest of annual reporting. Not comprehensive, and not consistent over time in terms of content. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                ESG matters reported separately rather than integrated with rest of annual reporting. Not comprehensive, and not consistent over time in terms of content. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Content', 's6', 3)" :class="{'active': answers.Content === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                The regular reporting from the company is satisfactorily comprehensive (incl. ESG performance, actions and improvements, ESG risks and opportunities, and changes to ESG management Systems).
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                The regular reporting from the company is satisfactorily comprehensive (incl. ESG performance, actions and improvements, ESG risks and opportunities, and changes to ESG management Systems).
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Content', 's6', 4)" :class="{'active': answers.Content === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>Frequency</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Frequency', 's6', 0)" :class="{'active': answers.Frequency === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Frequency', 's6', 1)" :class="{'active': answers.Frequency === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No ESG data is provided in any regular or ad hoc reporting formats. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                No ESG data is provided in any regular or ad hoc reporting formats. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Frequency', 's6', 2)" :class="{'active': answers.Frequency === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Annual or less frequent ESG data provided.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Annual or less frequent ESG data provided.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Frequency', 's6', 3)" :class="{'active': answers.Frequency === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                ESG reporting occurs annually for standardised items, and more often for specific (material, significant and/or severe) ESG developments. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                ESG reporting occurs annually for standardised items, and more often for specific (material, significant and/or severe) ESG developments. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Frequency', 's6', 4)" :class="{'active': answers.Frequency === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Serious incidents</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Seriousincidents', 's6', 0)" :class="{'active': answers.Seriousincidents === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Seriousincidents', 's6', 1)" :class="{'active': answers.Seriousincidents === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Serious incidents have occurred but not been included in any management report or Board documentation. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Serious incidents have occurred but not been included in any management report or Board documentation. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Seriousincidents', 's6', 2)" :class="{'active': answers.Seriousincidents === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Serious incidents have occurred, and are required reporting items to senior management and the Board. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Serious incidents have occurred, and are required reporting items to senior management and the Board. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Seriousincidents', 's6', 3)" :class="{'active': answers.Seriousincidents === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Serious incidents that result in loss of life, severe permanent injury or permanent damage to health, a material adverse environmental or social impact, or material breach of law relating to ESG matters are reported to senior management and the Board within 48 hours.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Serious incidents that result in loss of life, severe permanent injury or permanent damage to health, a material adverse environmental or social impact, or material breach of law relating to ESG matters are reported to senior management and the Board within 48 hours.
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Seriousincidents', 's6', 4)" :class="{'active': answers.Seriousincidents === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Corrective actions</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button" @click="toggle('Correctiveactions', 's6', 0)" :class="{'active': answers.Correctiveactions === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Correctiveactions', 's6', 1)" :class="{'active': answers.Correctiveactions === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No corrective actions have been taken in response to any serious incidents. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                No corrective actions have been taken in response to any serious incidents. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Correctiveactions', 's6', 2)" :class="{'active': answers.Correctiveactions === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Some corrective actions have been devised, but there is no monitoring or follow-up to ensure completion. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Some corrective actions have been devised, but there is no monitoring or follow-up to ensure completion. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Correctiveactions', 's6', 3)" :class="{'active': answers.Correctiveactions === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Corrective actions and outcomes in response to serious incidents are reported to senior management and the Board. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Corrective actions and outcomes in response to serious incidents are reported to senior management and the Board. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Correctiveactions', 's6', 4)" :class="{'active': answers.Correctiveactions === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group" :class="{'active': section[6].status}">
      <div class="group-title" @click="toggleSection(6)">
        <span class="title">
          <button>
            <span v-if="!section[6].status">+</span>
            <span v-if="section[6].status">-</span>
          </button>
          Emergency Response and Preparedness
        </span>
        <div class="buttons">
          <span class="button" :class="{'active': 0 === getLabel('s7')}">
            <span>
              Non existing
            </span>
          </span>
          <span class="button" :class="{'active': 1 === getLabel('s7')}">
            <span class="short">
              Basic
            </span>
            <span class="long">
              Individual parts of an Emergency Plan in place, but low awareness and no signs of implementation and rehearsals.
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Individual parts of an Emergency Plan in place, but low awareness and no signs of implementation and rehearsals.
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 2 === getLabel('s7')}">
            <span class="short">
              Good
            </span>
            <span class="long">
              Most parts of an Emergency Plan in place. Likely scenarios and crisis events described. Some awareness and occasional rehearsals. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Most parts of an Emergency Plan in place. Likely scenarios and crisis events described. Some awareness and occasional rehearsals. 
            </md-tooltip>
          </span>
          <span class="button" :class="{'active': 3 === getLabel('s7')}">
            <span class="short">
              Very good
            </span>
            <span class="long">
              Comprehensive Emergency Plan in place. Likely scenarios and crisis events described, and specific responses to each developed, to ensure business continuity. Widely communicated and scheduled or regular rehearsals. 
            </span>
            <md-tooltip md-direction="top" v-if="!expandStatus">
              Comprehensive Emergency Plan in place. Likely scenarios and crisis events described, and specific responses to each developed, to ensure business continuity. Widely communicated and scheduled or regular rehearsals. 
            </md-tooltip>
          </span>
          <span class="button na" :class="{'active': 4 === getLabel('s7')}">
            <span>
              N/A
            </span>
          </span>
        </div>
      </div>
      <div class="blocks">
        <div class="block" :class="{'active': step === 0}">
          <span class="title">
            <span>Internal, short term emergency scenarios</span>
          </span>
          <div class="buttons" :class="{'active': step === 0}">
            <span class="button" @click="toggle('Internalshorttermemergencyscenarios', 's7', 0)" :class="{'active': answers.Internalshorttermemergencyscenarios === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Internalshorttermemergencyscenarios', 's7', 1)" :class="{'active': answers.Internalshorttermemergencyscenarios === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No individual emergency scenarios have been identified.
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                No individual emergency scenarios have been identified.
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Internalshorttermemergencyscenarios', 's7', 2)" :class="{'active': answers.Internalshorttermemergencyscenarios === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Generic emergency scenarios have been identified (fire and safety, traffic accidents or similar). 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Generic emergency scenarios have been identified (fire and safety, traffic accidents or similar). 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Internalshorttermemergencyscenarios', 's7', 3)" :class="{'active': answers.Internalshorttermemergencyscenarios === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Emergency scenarios specific to the operations of the company have been identified. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 0">
                Emergency scenarios specific to the operations of the company have been identified. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Internalshorttermemergencyscenarios', 's7', 4)" :class="{'active': answers.Internalshorttermemergencyscenarios === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 1}">
          <span class="title">
            <span>External, longer term disruptive scenarios</span>
          </span>
          <div class="buttons" :class="{'active': step === 1}">
            <span class="button" @click="toggle('Externallongertermdisruptivescenarios', 's7', 0)" :class="{'active': answers.Externallongertermdisruptivescenarios === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Externallongertermdisruptivescenarios', 's7', 1)" :class="{'active': answers.Externallongertermdisruptivescenarios === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                No external and potentially disruptive factors or scenarios have been identified. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                No external and potentially disruptive factors or scenarios have been identified. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Externallongertermdisruptivescenarios', 's7', 2)" :class="{'active': answers.Externallongertermdisruptivescenarios === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                One or two external and potentially disruptive factors or scenarios have been identified, including the time over which are deemed to impact the company. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                One or two external and potentially disruptive factors or scenarios have been identified, including the time over which are deemed to impact the company. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Externallongertermdisruptivescenarios', 's7', 3)" :class="{'active': answers.Externallongertermdisruptivescenarios === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Comprehensive identification of external and potentially disruptive factors or scenarios, and estimated duration. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 1">
                Comprehensive identification of external and potentially disruptive factors or scenarios, and estimated duration. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Externallongertermdisruptivescenarios', 's7', 4)" :class="{'active': answers.Externallongertermdisruptivescenarios === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 2}">
          <span class="title">
            <span>Basic elements</span>
          </span>
          <div class="buttons" :class="{'active': step === 2}">
            <span class="button" @click="toggle('Basicelements', 's7', 0)" :class="{'active': answers.Basicelements === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Basicelements', 's7', 1)" :class="{'active': answers.Basicelements === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                Individual basic elements of an Emergency Plan in place (i.e. roles and responsibilities, emergency response procedures, local first aid capabilities, contact lists and organization of emergency areas (command centres, medical stations). 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Individual basic elements of an Emergency Plan in place (i.e. roles and responsibilities, emergency response procedures, local first aid capabilities, contact lists and organization of emergency areas (command centres, medical stations). 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Basicelements', 's7', 2)" :class="{'active': answers.Basicelements === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Most elements of an Emergency Plan in place. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                Most elements of an Emergency Plan in place. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Basicelements', 's7', 3)" :class="{'active': answers.Basicelements === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                All relevant elements of an Emergency Plan in place. Description of how each element fits into the overall plan, and thus its relevance. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 2">
                All relevant elements of an Emergency Plan in place. Description of how each element fits into the overall plan, and thus its relevance. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Basicelements', 's7', 4)" :class="{'active': answers.Basicelements === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
        <div class="block" :class="{'active': step === 3}">
          <span class="title">
            <span>Business continuity</span>
          </span>
          <div class="buttons" :class="{'active': step === 3}">
            <span class="button" @click="toggle('Businesscontinuity', 's7', 0)" :class="{'active': answers.Businesscontinuity === 0}">
              <span>
                Non existing
              </span>
            </span>
            <span class="button" @click="toggle('Businesscontinuity', 's7', 1)" :class="{'active': answers.Businesscontinuity === 1}">
              <span class="short">
                Basic
              </span>
              <span class="long">
                High level/strategic view of requirements for business continuity, but no corresponding operational implementation or follow-up. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                High level/strategic view of requirements for business continuity, but no corresponding operational implementation or follow-up. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Businesscontinuity', 's7', 2)" :class="{'active': answers.Businesscontinuity === 2}">
              <span class="short">
                Good
              </span>
              <span class="long">
                Operational assessment of critical points for business continuity and operational arrangements in place to address those risks. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Operational assessment of critical points for business continuity and operational arrangements in place to address those risks. 
              </md-tooltip>
            </span>
            <span class="button" @click="toggle('Businesscontinuity', 's7', 3)" :class="{'active': answers.Businesscontinuity === 3}">
              <span class="short">
                Very good
              </span>
              <span class="long">
                Redundancy organisational and material arrangements made, including replacement supplies of all input resources, redundant or duplicate supply systems, and information back-up systems. Regular audits/checks to ensure business continuity requirements fulfilled. 
              </span>
              <md-tooltip md-direction="top" v-if="!expandStatus && step !== 3">
                Redundancy organisational and material arrangements made, including replacement supplies of all input resources, redundant or duplicate supply systems, and information back-up systems. Regular audits/checks to ensure business continuity requirements fulfilled. 
              </md-tooltip>
            </span>
            <span class="button na" @click="toggle('Businesscontinuity', 's7', 4)" :class="{'active': answers.Businesscontinuity === 4}">
              <span>
                N/A
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-nav">
      <md-button class="md-raised md-primary" v-if="disabled" disabled>Next</md-button>
      <md-button class="md-raised md-primary" v-if="!disabled" @click="nextSection()">Next</md-button>
    </div>
  </main>
</template>

<script>
import HeaderColumnsLower from '../HeaderColumnsLower';

export default {
  name: "esms",
  components: {
    HeaderColumnsLower,
  },
  data() {
    return {
      step: 0,
      disabled: true,
      answers: [],
      expandStatus: this.$store.state.expandStatus,
      review: this.$store.state.review,
      reviewCount: this.$store.state.reviewCount,
      section: [
        { status: false, count: 0, total: 5, name: 's1' },
        { status: false, count: 0, total: 3, name: 's2' },
        { status: false, count: 0, total: 3, name: 's3' },
        { status: false, count: 0, total: 3, name: 's4' },
        { status: false, count: 0, total: 2, name: 's5' },
        { status: false, count: 0, total: 4, name: 's6' },
        { status: false, count: 0, total: 4, name: 's7' },
      ],
      sectionMedian: {
        s1: null,
        s2: null,
        s3: null,
        s4: null,
        s5: null,
        s6: null,
        s7: null,
      },
      level: 'esms',
    }
  },
  mounted() {
    this.assembleAnswers();
    this.$store.subscribe((mutation, state) => {
      if (["toggleExpandStatus"].indexOf(mutation.type) >= 0) {
        this.expandStatus = state.expandStatus;
      }
      if (["startReview", "answerReview"].indexOf(mutation.type) >= 0) {
        this.review = state.preview.status ? state.preview.review : state.review;
        this.reviewCount = state.reviewCount;
        this.assembleAnswers();
      }
    });
  },
  methods: {
    toggle(key, section, index) {
      const total = this.section.filter((item) => item.name === section)[0].total;
      this.$store.commit("answerReview", {level: "esms", type: "lower", key: key, value: index, section: section, total: total});
      const check = this.review.lower.filter((item) => item.section === section && item.level === "esms")
      this.step = check.length;
      if (this.step === total) {
        this.section.forEach((item) => {
          item.status = false;
        });
      }
    },
    assembleAnswers() {
      this.answers = []
      if (this.review.lower) {
        this.review.lower.map((item) => {
          this.answers[item.key] = item.value;
        });
      }
      this.getMedian();
      if (this.reviewCount.lower.esms >= 24) {
        this.disabled = false;
      }
    },
    nextSection() {
      this.$store.commit("toggleSection", {type: "lower", section: 1})
    },
    toggleSection(key) {
      if (this.section[key].status) {
        this.section[key].status = false;
        return false;
      }
      this.section.forEach((item) => {
        item.status = false;
      });
      this.section[key].status = !this.section[key].status;
      const check = this.review.lower.filter((item) => item.section === this.section[key].name && item.level === "esms")
      const total = this.section[key].total;
      this.step = check.length === total ? 0 : check.length;
    },
    getMedian() {
      this.section.map((section) => {
        let total = 0
        if (this.review.lower) {
          const item = this.review.lower.filter((item) => item.section === section.name && item.level === this.level)
          if (item)
            item.map((item) => {
              total += item.value;
            });
        }
        this.sectionMedian[section.name] = parseInt(total / section.total);
      });
    },
    getLabel(section) {
      const total = this.section.filter((item) => item.name === section)[0].total
      const check = this.review.lower.filter((item) => item.section === section && item.level === this.level)
      if (check.length < total)
        return '';
      return this.sectionMedian[section];
    }
  }
}
</script>
