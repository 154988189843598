<template>
  <md-card>
    <md-card-header>
      <div class="md-title">Latest ESG Risk Management Score</div>
      <div class="utils">
        <md-field>
          <md-select id="sector" v-model="type">
            <md-option value="total">All</md-option>
            <md-option value="esms">Environmental & Social Risk Management</md-option>
            <md-option value="corporate">Corporate Governance</md-option>
            <md-option value="bi">Business Integrity</md-option>
          </md-select>
        </md-field>
        <md-icon>
          info
          <md-tooltip md-direction="bottom" v-if="data.content" v-html="data.content['Info: ESG Risk Management Score']"></md-tooltip>
        </md-icon>
      </div>
    </md-card-header>
    <md-card-content>
      <div v-if="this.item.review">
        <span>{{ reviewScore }}<sup>/100</sup></span>
      </div>
      <div v-if="!this.item.review">
        <p>
          This investment doesn't have a review yet.
        </p>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "CardEsgScore",
  props: ["item"],
  data() {
    return {
      data: this.$store.state.data,
      type: 'total',
      reviewScore: 0,
    }
  },
  mounted() {
    this.reviewScore = this.item.reviewScore.total.toFixed(0);
  },
  watch: {
    item() {
      this.reviewScore = this.item.reviewScore.total.toFixed(0);
    },
    type() {
      if (this.type === 'total')
        this.reviewScore = this.item.reviewScore.total.toFixed(0);
      else
        this.reviewScore = this.item.reviewScore[this.type].total.toFixed(0);
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card-header {
  display: flex;
  background: rgba(2, 84, 149, 0.08);
  border-bottom: 1px solid rgba(2, 84, 149, 0.5);
  justify-content: space-between;
  .md-title {
    margin: 0;
  }
  .utils {
    display: flex;
    align-items: center;
    .md-field {
      margin: 0; 
      min-height: 32px;
      padding-top: 0;
      .md-select {
        max-width: 120px;
      }
    }
    i {
      margin-left: 10px;
    }
  }
}
.md-card-content {
  padding: 20px 0 16px;
}
span {
  position: relative;
  font-size: 30px;
  line-height: 20px;
  color: rgb(51, 161, 201);
  sup {
    font-size: 14px;
    top: 0;
  }
}
</style>